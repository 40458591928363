import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      take(1),
      map(({ isAuthenticated }) => {
        // allow navigation if authenticated
        if (isAuthenticated) {
          var canNavigate: boolean | UrlTree = false;
          this.oidcSecurityService
            .checkAuth()
            .subscribe(({ isAuthenticated, userData, accessToken }) => { 
              var subURL = state.url;
              if(state.url.indexOf("?") != -1){
                subURL = state.url.substring(0, state.url.indexOf("?"));
              }
              
              switch(subURL){
                
                case '/portal': canNavigate = true; break;
                case '/usage-statistics': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "ViewUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/document-archive': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "DocumentArchive"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/submit-jobs': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "DocumentArchive"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/account-settings': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "DocumentArchive" || data == "ViewUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/queues': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/interactive': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/templates': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "ViewUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/api': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/workflows': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/users': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/groups': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/roles': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings/pools': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/job-status': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "DocumentArchive"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/interactive/newDraft': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "InteractiveUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                // case '/interactive/workflowApproval': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "InteractiveUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/interactive/workflowApproval': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "InteractiveUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/interactive/mydrafts': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "InteractiveUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/interactive/completedTasks': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "InteractiveUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/reports': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/templating': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "TemplateUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/processServices': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "ProcessUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/settings': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "ViewUser" || data == "DocumentArchive" || data == "InteractiveUser" || data == "ProcessUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal')}; break;
                case '/help': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"|| data == "Admin"|| data == "ViewUser"|| data == "DocumentArchive"|| data == "InteractiveUser" || data == "ProcessUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/contact-us': if(userData.groups.filter((data: string) => {  return data == "CGAdmin" || data == "Admin" || data == "ViewUser" || data == "DocumentArchive" || data == "ProcessUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/notifications': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"|| data == "Admin"|| data == "ViewUser"|| data == "DocumentArchive"|| data == "InteractiveUser" || data == "ProcessUser"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/tenants': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/users': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/permissions': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/groups': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/roles': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                case '/admin/pools': if(userData.groups.filter((data: string) => {  return data == "CGAdmin"}).length>0){canNavigate = true; }else {canNavigate = this.router.parseUrl('/portal');return this.router.parseUrl('/portal')}; break;
                default: canNavigate = false; 
              }
              return true;
            });

            return canNavigate;
        }

        // redirect if not authenticated
        return this.router.parseUrl('/unauthorized');
      })
    );
  }
}