<div style="user-select: none;">
    <h1 mat-dialog-title><b>Add Pool</b></h1>
    <div mat-dialog-content>
        <div class="flexRow">
            <div>
                <p class="itemHeading">Name*</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [(ngModel)]="data.poolName" cdkFocusInitial>

                <p class="itemHeading">Description</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [(ngModel)]="data.poolDescription">
            </div>
        </div>
    </div>
    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="secondary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="data" >Add</button>
    </div>
</div>