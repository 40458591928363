<div style="padding: 20px; user-select: none;">
    <div class="divHeaderLine">
        <h3>Completed tasks</h3>
        <div class="divHeaderLine">
            <div class="flexRow">
                <mat-form-field>
                    <mat-label>Workflow</mat-label>
                    <mat-select [disabled]="workflowsLoading" [formControl]="filterWorkflows" required
                        (selectionChange)="getTasks()">
                        @for (workflow of workflows; track workflow){
                        <mat-option [value]="workflow.id">{{workflow.workflowName}}</mat-option>
                        }
                    </mat-select>
                    <mat-card *ngIf="workflowsLoading"
                        style="display: flex; justify-content: center; align-items: center">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card>
                </mat-form-field>
                &nbsp;
                <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
            </div>
        </div>
    </div>
    <br>
    <div style="user-select: none;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Task ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
            </ng-container>

            <ng-container matColumnDef="LastUpdate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
                <td mat-cell *matCellDef="let element"> {{element.LastUpdateDisplay}} </td>
            </ng-container>

            <ng-container matColumnDef="CreateDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
                <td mat-cell *matCellDef="let element"> {{element.CreateDateDisplay}} </td>
            </ng-container>

            <ng-container matColumnDef="CreatorName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                <td mat-cell *matCellDef="let element"> {{element.CreatorName}} </td>
            </ng-container>

            <ng-container matColumnDef="WorkflowName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Workflow </th>
                <td mat-cell *matCellDef="let element"> {{element.WorkflowName}} </td>
            </ng-container>

            <ng-container matColumnDef="Queue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Queue </th>
                <td mat-cell *matCellDef="let element"> {{element.Queue}} </td>
            </ng-container>

            <ng-container matColumnDef="ProjectID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Project ID </th>
                <td mat-cell *matCellDef="let element"> {{element.ProjectID}} </td>
            </ng-container>

            <ng-container matColumnDef="TemplateSelector">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Template Selector </th>
                <td mat-cell *matCellDef="let element"> {{element.TemplateSelector}} </td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef style="width: 50px;">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        Action
                        <mat-menu #menu="matMenu">
                            <div style="display: flex; flex-direction: column; padding: 5px;" (click)="$event.stopPropagation()">
                                <p style="padding-left: 10px;">Cloumns</p>
                                <mat-checkbox [(ngModel)]="check_TaskID">Task ID</mat-checkbox>
                                <!-- <mat-checkbox [(ngModel)]="check_Name">Name</mat-checkbox> -->
                                <mat-checkbox [(ngModel)]="check_LastUpdated">Last Updated</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_CreatedDate">Created Date</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_CreatedBy" >Created By</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_Workflow" >Workflow</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_Queue" >Queue</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_ProjectID" >Project ID</mat-checkbox>
                                <mat-checkbox [(ngModel)]="check_TemplateSelector" >Template Selector</mat-checkbox>
                                <button class="blueButton" (click)="columnVisibleSelect()">Apply</button>
                            </div>
                        </mat-menu>
                        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="columnVisibleSet()">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256"
                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M120,64a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24h68A12,12,0,0,1,120,64ZM108,92H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm40-96h68a12,12,0,0,0,0-24H148a12,12,0,0,0,0,24Zm68,16H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flexRow">
                        <button mat-mini-fab color="primary" aria-label="User Details" (click)="openTasks(element)">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-card *ngIf="taskssLoading" style="display: flex; justify-content: center; align-items: center">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card>
        <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons aria-label="Select page"></mat-paginator>
    </div>
    <!-- <iframe style="display: none;" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" [src]="embedURL"
        allow="clipboard-read; clipboard-write" width='100%'></iframe> -->
</div>