<div style="padding: 20px; user-select: none;">
    <div class="divHeaderLine">
        <h3>Workflows</h3>
        <div class="divHeaderLine">
            <div class="flexRow">
                <!-- <mat-form-field>
                    <mat-label>Pool</mat-label>
                    <mat-select [formControl]="filterPools" required (selectionChange)="getWorkflowItems()">
                        @for (pool of pools; track pool){
                        <mat-option [value]="pool.id">{{pool.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <div style="width: 10px;"></div> -->
                <mat-form-field>
                    <mat-label>Workflow</mat-label>
                    <mat-select [disabled]="workflowsLoading" [formControl]="filterWorkflows" required
                        (selectionChange)="getWorkflowPools()">
                        @for (workflow of workflows; track workflow){
                        <mat-option [value]="workflow.id">{{workflow.workflowName}}</mat-option>
                        }
                    </mat-select>
                    <mat-card *ngIf="workflowsLoading"
                        style="display: flex; justify-content: center; align-items: center">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card>
                </mat-form-field>
                &nbsp;
                <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
            </div>
        </div>
    </div>
    <br>
    <div style="user-select: none;">

        <mat-accordion>
            @for (pool of pools; track $index) {
            <mat-expansion-panel (opened)="getPoolTasks(pool)">
                <mat-expansion-panel-header>
                    <mat-panel-title style="font-weight: bold;">
                        {{pool.Name}}
                    </mat-panel-title>
                    <mat-panel-description>
                        <div *ngIf="pool.inPool" style="color: black; font-size: 14px;">User in Pool</div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="!tasksLoading else loading">
                    <div *ngIf="taskTable.length == 0">
                        <p>*No tasks for this pool.</p>
                    </div>
                    <div *ngIf="taskTable.length >0">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Task ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="LastUpdate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
                                <td mat-cell *matCellDef="let element"> {{element.LastUpdateDisplay}} </td>
                            </ng-container>

                            <ng-container matColumnDef="CreateDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.CreateDateDisplay}} </td>
                            </ng-container>

                            <ng-container matColumnDef="CreatorName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                <td mat-cell *matCellDef="let element"> {{element.CreatorName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Queue">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Queue </th>
                                <td mat-cell *matCellDef="let element"> {{element.Queue}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ProjectID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Project ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.ProjectID}} </td>
                            </ng-container>

                            <ng-container matColumnDef="TemplateSelector">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Template Selector </th>
                                <td mat-cell *matCellDef="let element"> {{element.TemplateSelector}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef style="width: 50px;">
                                    <div style="display: flex; flex-direction: row; align-items: center;">
                                        Action
                                        <mat-menu #menu="matMenu">
                                            <div style="display: flex; flex-direction: column; padding: 5px;"
                                                (click)="$event.stopPropagation()">
                                                <p style="padding-left: 10px;">Cloumns</p>
                                                <mat-checkbox [(ngModel)]="check_TaskID">Task ID</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_LastUpdated">Last
                                                    Updated</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_CreatedDate">Created
                                                    Date</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_CreatedBy">Created By</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_Queue">Queue</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_ProjectID">Project ID</mat-checkbox>
                                                <mat-checkbox [(ngModel)]="check_TemplateSelector">Template
                                                    Selector</mat-checkbox>
                                                <button class="blueButton"
                                                    (click)="columnVisibleSelect()">Apply</button>
                                            </div>
                                        </mat-menu>
                                        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="columnVisibleSet()">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                viewBox="0 0 256 256" height="1em" width="1em"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M120,64a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24h68A12,12,0,0,1,120,64ZM108,92H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H40a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm40-96h68a12,12,0,0,0,0-24H148a12,12,0,0,0,0,24Zm68,16H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Zm0,40H148a12,12,0,0,0,0,24h68a12,12,0,0,0,0-24Z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-mini-fab color="primary" aria-label="User Details"
                                        (click)="viewTaskFromButton(element, false)" [disabled]="!pool.inPool">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="20px" width="20px"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M3.04 10h2.58l.65 1H2.54l-.5-.5v-9l.5-.5h12l.5.5v4.77l-1-1.75V2h-11v8zm5.54 1l-1.41 3.47h2.2L15 8.7 14.27 7h-1.63l.82-1.46L12.63 4H9.76l-.92.59-2.28 5L7.47 11h1.11zm1.18-6h2.87l-1.87 3h3.51l-5.76 5.84L10.2 10H7.47l2.29-5zM6.95 7H4.04V6H7.4l-.45 1zm-.9 2H4.04V8H6.5l-.45 1z">
                                            </path>
                                        </svg>
                                    </button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[5, 10]"
                            showFirstLastButtons aria-label="Select page"></mat-paginator>
                    </div>
                </div>
                <ng-template #loading>
                    <mat-card *ngIf="tasksLoading" style="display: flex; justify-content: center; align-items: center">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card>
                </ng-template>
            </mat-expansion-panel>
            }
        </mat-accordion>

        
    </div>
    <!-- <iframe style="display: none;" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" [src]="embedURL"
        allow="clipboard-read; clipboard-write" width='100%'></iframe> -->
</div>