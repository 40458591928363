<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Reports</h3>
        <div class="divHeaderLine">
            <div class="chipItemsReport">
                <button *ngIf="!csvLoading" (click)="getCSV()" class="blueButton"
                    style="height: 36px; width: 115px">Download CSV</button>
                <button *ngIf="csvLoading" class="blueButton" style="height: 36px; width: 115px"
                    disabled>Downloading</button>
            </div>
            <div class="chipItemsReport">
                <mat-chip-listbox aria-label="Report Items" multiple>
                    <mat-chip-option id="Chart" (selectionChange)="getChips($event)" color="accent"
                        selected>Chart</mat-chip-option>
                    <mat-chip-option id="Summary" (selectionChange)="getChips($event)" color="accent"
                        selected>Summary</mat-chip-option>
                    <mat-chip-option id="Transactions" (selectionChange)="getChips($event)" color="accent"
                        selected>Transactions</mat-chip-option>
                    <mat-chip-option id="Emails" (selectionChange)="getChips($event)" color="accent"
                        selected>Emails</mat-chip-option>
                </mat-chip-listbox>
            </div>

            <!-- <mat-form-field>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="getReport()">
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getReport()">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                @if (range.controls.start.hasError('matStartDateInvalid')) {
                <mat-error>Invalid start date</mat-error>
                }
                @if (range.controls.end.hasError('matEndDateInvalid')) {
                <mat-error>Invalid end date</mat-error>
                }
            </mat-form-field> -->

            <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
        </div>
    </div>
    <div>
        <div *ngIf="!pdfLoading" class="pdfViewer">
            <iframe title="Preview" [src]="pdfData" height="100%" width="100%" style="border: none;"></iframe>
        </div>
        <div *ngIf="pdfLoading" style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 240px); align-items: center;">
            <p class="loadingText">Processing Report</p>
            <br>
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>