import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateRange, MatDatepickerModule } from '@angular/material/datepicker';
import moment from 'moment';

export interface Date {
  dateFrom: any,
  dateTo: any
}

@Component({
  selector: 'app-advanced-date-selection',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './advanced-date-selection.component.html',
  styleUrl: './advanced-date-selection.component.scss'
})
export class AdvancedDateSelectionComponent implements OnInit {
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() date: Date;
  @ViewChild('menuTrigger') trigger: any;
  timeframe = 'Today';
  timeMenuOption = 'Fixed';
  timeMenuOptionPeriod = 'Day';
  selectedFixedDate = new Date();
  timeOffset = 1;
  timeFrom = new Date();
  timeTo = new Date();
  rangeDate = new DateRange(moment(new Date()), moment(new Date()));
  predefinedTime = '';

  ngOnInit(): void {
    if (this.date) {
      this.timeFrom = new Date(this.date.dateFrom);
      this.timeTo = new Date(this.date.dateTo);
      this.timeframe = new Date(this.date.dateFrom).toLocaleDateString() + " - " + new Date(this.date.dateTo).toLocaleDateString();
    }
    else {
      this.timeFrom.setHours(0);
      this.timeFrom.setMinutes(0);
      this.timeFrom.setSeconds(0);
      this.timeFrom.setMilliseconds(0);

      this.timeTo.setHours(23);
      this.timeTo.setMinutes(59);
      this.timeTo.setSeconds(59);
      this.timeTo.setMilliseconds(999);
      this.timeframe = new Date().toLocaleDateString();
    }

  }


  rangeChange(data: any) {
    if (this.rangeDate.end == null) {
      this.rangeDate = new DateRange(this.rangeDate.start, data);
    }
    else {
      this.rangeDate = new DateRange(data, null);
    }
  }

  setDateToday() {
    this.selectedFixedDate = new Date();
  }



  setDate(triggerLocal: boolean) {
    if (this.timeMenuOption == 'Fixed') {
      var fromTime = new Date(this.selectedFixedDate);
      var toTime = new Date(this.selectedFixedDate);
      fromTime.setHours(0);
      fromTime.setMinutes(0);
      fromTime.setSeconds(0);
      fromTime.setMilliseconds(0);
      this.timeFrom = fromTime;
      toTime.setHours(23);
      toTime.setMinutes(59);
      toTime.setSeconds(59);
      toTime.setMilliseconds(999);
      this.timeTo = toTime;
      this.timeframe = new Date(this.timeFrom).toLocaleDateString();
    }
    else if (this.timeMenuOption == 'Range') {
      if (this.rangeDate.end == null) {
        this.timeFrom = this.rangeDate.start ? this.rangeDate.start.toDate() : new Date();
        var startTime = this.rangeDate.start ? this.rangeDate.start.toDate().toLocaleDateString() : new Date().toLocaleDateString();
        var endTime: any = this.rangeDate.start ? this.rangeDate.start.toDate() : new Date();
        endTime = new Date(endTime.setDate(endTime.getDate() + 1));
        endTime = new Date(endTime.setMilliseconds(endTime.getMilliseconds() - 1));
        this.timeTo = endTime;
        if (triggerLocal) {
          this.timeframe = startTime + ' - ' + endTime.toLocaleDateString();
        }
      }
      else {
        this.timeFrom = this.rangeDate.start ? this.rangeDate.start.toDate() : new Date();
        var startTime = this.rangeDate.start ? this.rangeDate.start.toDate().toLocaleDateString() : new Date().toLocaleDateString();
        var endTime: any = this.rangeDate.end ? this.rangeDate.end.toDate() : new Date();
        endTime = new Date(endTime.setDate(endTime.getDate() + 1));
        endTime = new Date(endTime.setMilliseconds(endTime.getMilliseconds() - 1));
        this.timeTo = endTime;
        if (triggerLocal) {
          this.timeframe = startTime + ' - ' + endTime.toLocaleDateString();
        }
      }
    }
    else if (this.timeMenuOption == 'Relative') {
      var fromTime = new Date();
      var toTime = new Date();
      toTime.setHours(23);
      toTime.setMinutes(59);
      toTime.setSeconds(59);
      toTime.setMilliseconds(999);
      this.timeFrom = new Date();
      this.timeFrom.setHours(0);
      this.timeFrom.setMinutes(0);
      this.timeFrom.setSeconds(0);
      this.timeFrom.setMilliseconds(0);
      this.timeTo = toTime;
      if (this.timeMenuOptionPeriod == 'Day') {
        fromTime = new Date(new Date(this.timeFrom).setDate(new Date(this.timeFrom).getDate() - this.timeOffset + 1));
        this.timeFrom = fromTime;
        if (triggerLocal) {
          this.timeframe = this.timeOffset + ' Day(s)';
        }
      }
      else if (this.timeMenuOptionPeriod == 'Week') {
        fromTime = new Date(new Date(this.timeFrom).setDate(new Date(this.timeFrom).getDate() - (this.timeOffset * 7) + 1));
        this.timeFrom = fromTime;
        if (triggerLocal) {
          this.timeframe = this.timeOffset + ' Week(s)';
        }

      }
      else if (this.timeMenuOptionPeriod == 'Month') {
        fromTime = new Date(new Date(this.timeFrom).setMonth(new Date(this.timeFrom).getMonth() - (this.timeOffset)));
        this.timeFrom = fromTime;
        if (triggerLocal) {
          this.timeframe = this.timeOffset + ' Month(s)';
        }

      }
    }
    else if (this.timeMenuOption == 'Predefined') {
      if (this.predefinedTime == 'today') {

      }
      switch (this.predefinedTime) {
        case 'today': this.timeframe = 'Today'; break;
        case 'yesterday': this.timeframe = 'Yesterday'; break;
        case 'thisweek': this.timeframe = 'This Week'; break;
        case 'oneweek': this.timeframe = 'One Week'; break;
        case 'lastweek': this.timeframe = 'Last Week'; break;
        case 'thismonth': this.timeframe = 'This Month'; break;
        case 'lastmonth': this.timeframe = 'Last Month'; break;
        case 'thisyear': this.timeframe = 'This Year'; break;
        case 'lastyear': this.timeframe = 'Last Year'; break;
      }
    }

    var returnDate: Date = {
      dateFrom: new Date(this.timeFrom),
      dateTo: new Date(this.timeTo)
    }
    if (triggerLocal) {
      this.dateChanged.emit(returnDate);
    }

  }

  predToday() {
    this.predefinedTime = 'today';
    var fromTime = new Date();
    var toTime = new Date();
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predYesterday() {
    this.predefinedTime = 'yesterday';
    var fromTime = new Date(new Date().setDate(new Date().getDate() - 1));
    var toTime = new Date(new Date().setDate(new Date().getDate() - 1));
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predThisWeek() {
    this.predefinedTime = 'thisweek';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1)
    const toTime = new Date(now.getFullYear(), now.getMonth(), fromTime.getDate() + 7)

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }

  predOneWeek() {
    this.predefinedTime = 'oneweek';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6)
    const toTime = new Date(now.getFullYear(), now.getMonth(), fromTime.getDate() + 6)

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predLastWeek() {
    this.predefinedTime = 'lastweek';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1 -7)
    const toTime = new Date(now.getFullYear(), now.getMonth(), fromTime.getDate() + 6)

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }

  predThisMonth() {
    this.predefinedTime = 'thismonth';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), now.getMonth(), 1);
    const toTime = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predLastMonth() {
    this.predefinedTime = 'lastmonth';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), now.getMonth()-1, 1);
    const toTime = new Date(now.getFullYear(), now.getMonth(), 0);

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predThisYear() {
    this.predefinedTime = 'thisyear';

    const now = new Date()
    const fromTime = new Date(now.getFullYear(), 0);
    const toTime = new Date(now.getFullYear(), 11, 31);

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }
  predLastYear() {
    this.predefinedTime = 'lastyear';

    const now = new Date()
    const fromTime = new Date(now.getFullYear()-1, 0);
    const toTime = new Date(now.getFullYear()-1, 11, 31);

    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    this.timeFrom = fromTime;
    toTime.setHours(23);
    toTime.setMinutes(59);
    toTime.setSeconds(59);
    toTime.setMilliseconds(999);
    this.timeTo = toTime;
  }

}