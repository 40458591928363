import { NgModule } from '@angular/core';
import {Component} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { HomeComponent } from './home/home.component';
import { PortalComponent } from './portal/portal.component'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthorizationGuard } from './auth.gaurd';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { UsageStatisticsComponent } from './usage-statistics/usage-statistics.component';
import { DocumentArchiveComponent } from './document-archive/document-archive.component';
import { SubmitJobsComponent } from './submit-jobs/submit-jobs.component';
import { JobStatusComponent } from './job-status/job-status.component';
import { InteractiveDocumentsComponent } from './interactive-documents/interactive-documents.component';
import { ReportsComponent } from './reports/reports.component';
import { TemplatingComponent } from './templating/templating.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpDocumentationComponent } from './help-documentation/help-documentation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AdminComponent } from './admin/admin.component';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormGroup, FormControl, ReactiveFormsModule} from '@angular/forms';
import {JsonPipe} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastsContainer } from './toast/toasts-container.component';
import { NgbdToastGlobal } from './toast/toast-global.component';
import { ToastDirectiveDirective } from './toast/toast-directive.directive';
import { TemplateStore } from './toast/template-store';
import { ProcessServicesComponent } from './process-services/process-services.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { WorkflowApprovalComponent } from './workflow-approval/workflow-approval.component';
import { SavedDraftsComponent } from './saved-drafts/saved-drafts.component';
import { CompletedTasksComponent } from './completed-tasks/completed-tasks.component';
import { MatCardModule } from '@angular/material/card';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};


@NgModule({
  declarations: [AppComponent, HomeComponent, UnauthorizedComponent, ToastDirectiveDirective],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'portal', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [AuthorizationGuard] },
      { path: 'portal', component: PortalComponent, canActivate: [AuthorizationGuard] },
      { path: 'usage-statistics', component: UsageStatisticsComponent, canActivate: [AuthorizationGuard] },
      { path: 'document-archive', component: DocumentArchiveComponent, canActivate: [AuthorizationGuard] },
      { path: 'submit-jobs', component: SubmitJobsComponent, canActivate: [AuthorizationGuard] },
      { path: 'job-status', component: JobStatusComponent, canActivate: [AuthorizationGuard] },
      { path: 'interactive/newDraft', component: InteractiveDocumentsComponent, canActivate: [AuthorizationGuard] },
      { path: 'interactive/workflowApproval', component: WorkflowApprovalComponent, canActivate: [AuthorizationGuard] },
      { path: 'interactive/mydrafts', component: SavedDraftsComponent, canActivate: [AuthorizationGuard] },
      { path: 'interactive/completedTasks', component: CompletedTasksComponent, canActivate: [AuthorizationGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthorizationGuard] },
      { path: 'templating', component: TemplatingComponent, canActivate: [AuthorizationGuard] },
      { path: 'processServices', component: ProcessServicesComponent, canActivate: [AuthorizationGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthorizationGuard] },
      { path: 'settings/:item', component: SettingsComponent, canActivate: [AuthorizationGuard] },
      { path: 'help', component: HelpDocumentationComponent, canActivate: [AuthorizationGuard] },
      { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthorizationGuard] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthorizationGuard] },
      { path: 'admin', component: AdminComponent, canActivate: [AuthorizationGuard] },
      { path: 'admin/:item', component: AdminComponent, canActivate: [AuthorizationGuard] },
      { path: 'forbidden', component: UnauthorizedComponent, canActivate: [AuthorizationGuard] },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', redirectTo: 'portal' },
      
      // { path: 'callback', component: CallbackComponent },
    ]),
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    AuthConfigModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    ScrollingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    ReactiveFormsModule,
    JsonPipe,
    NgbModule,
    ToastsContainer,
    MatCardModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ToastsContainer,
    NgbdToastGlobal,
    TemplateStore,
    // {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded)
      )
      .subscribe((config) => {
        //console.log('ConfigLoaded', config);
      });
  }
  
}

