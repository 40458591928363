<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Job Status Information</h3>
        <div class="divHeaderLine">
            <button mat-mini-fab color="primary" style="margin-top: 10px;" aria-label="Get Jobs" (click)="getJobs()">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px"
                    width="20px" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M256 388c-72.597 0-132-59.405-132-132 0-72.601 59.403-132 132-132 36.3 0 69.299 15.4 92.406 39.601L278 234h154V80l-51.698 51.702C348.406 99.798 304.406 80 256 80c-96.797 0-176 79.203-176 176s78.094 176 176 176c81.045 0 148.287-54.134 169.401-128H378.85c-18.745 49.561-67.138 84-122.85 84z">
                    </path>
                </svg>
            </button>
            &nbsp;&nbsp;
            &nbsp;&nbsp;
            <mat-slide-toggle (change)="toggleAutoRefersh($event)" [(ngModel)]="autoRefersh"
                style="padding-top: 15px; font-size: 13px;">Auto refresh <br>every 10 seconds</mat-slide-toggle>
            &nbsp;&nbsp;
            <mat-form-field style="width: 400px;">
                <mat-label>Status</mat-label>
                <mat-select (ngModelChange)="getJobs()" [formControl]="statuses" multiple>
                    @for (item of statusList; track item) {
                    <mat-option [value]="item">{{item}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            &nbsp;
            <!-- <mat-form-field>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input style="width: 280px;" [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="getJobs()">
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getJobs()">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                @if (range.controls.start.hasError('matStartDateInvalid')) {
                <mat-error>Invalid start date</mat-error>
                }
                @if (range.controls.end.hasError('matEndDateInvalid')) {
                <mat-error>Invalid end date</mat-error>
                }
            </mat-form-field> -->
            <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
        </div>
    </div>

    <div class="graphsMain">
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Waiting" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="WaitingStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">Waiting</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="WaitingEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Progress" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="ProgressStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">In Progress</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="ProgressEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Spooled" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="SpooledStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">Spooled</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="SpooledEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Completed" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="CompletedStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">Completed</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="CompletedEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Stopped" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="StoppedStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">Stopped</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="StoppedEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
        <div class="divItem">
            <mat-spinner *ngIf="jobsLoading"></mat-spinner>
            <canvas [ngClass]="jobsLoading?'hide':'display'" id="Errored" class="graphItem"></canvas>
            <div class="bottomGraphItems">
                <div style="width: 60px; text-align: center;">
                    <p id="ErroredStart" style="width: 60px;" class="bottomGraphText"></p>
                </div>
                <div><br>
                    <p class="bottomGraphText">Errored</p>
                </div>
                <div style="width: 60px; text-align: center;">
                    <p id="ErroredEnd" style="width: 60px;" class="bottomGraphText"></p>
                </div>
            </div>
        </div>
    </div>
    <mat-drawer-container autosize>
        <mat-drawer #drawer position="end" mode="side" (closedStart)="drawerClose()" [opened]="jobDrawerOpen">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h2 style="margin-top: 10px;">&nbsp;Info for JOB <b>{{jobDrawerData.JobID}}</b></h2>
                <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                    (click)="closeJobOption()">
                    <svg stroke="grey" fill="grey" stroke-width="0" viewBox="0 0 512 512" height="30px" width="30px"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z">
                        </path>
                    </svg>
                </button>
            </div>
            

            <div>
                <mat-tab-group animationDuration="0">
                    <mat-tab label="Details">
                        <br>
                        <table style="user-select: none;">
                            <tbody>
                                <tr>
                                    <td class="jobInfoTableLeft">Queue</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Queue}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Processed by</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.ProcessedBy}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Submitted</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.SubmittedDate}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Started</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Started}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Finished</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Finished}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Duration</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Duration}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Progress</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Progress?jobDrawerData.Progress:"0"}}
                                        transaction(s) processed</td>
                                </tr>
                            </tbody>
                        </table>
                        <br style="user-select: none;">
                        <table style="user-select: none;">
                            <tbody>
                                <tr>
                                    <td class="jobInfoTableLeft">Type</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Type}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Input File</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.InputFile}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Data Type</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.DataType}}</td>
                                </tr>
                                <tr>
                                    <td class="jobInfoTableLeft">Range</td>
                                    <td class="jobInfoTableRight">{{jobDrawerData.Range}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-tab>
                    <mat-tab label="Reports">
                        <div *ngIf="!jobDrawerLoading">
                            @for (item of reports; track item){
                            <br style="user-select: none;">
                            <table style="user-select: none;">
                                <tbody>
                                    <tr>
                                        <td class="jobInfoTableLeft">Log Type</td>
                                        <td class="jobInfoTableRight">{{item.fileType}}</td>
                                    </tr>
                                    <tr>
                                        <td class="jobInfoTableLeft">Log File</td>
                                        <td class="jobInfoTableRight">{{item.fileName}}</td>
                                    </tr>
                                    <tr>
                                        <td class="jobInfoTableLeft">Content Type</td>
                                        <td class="jobInfoTableRight">{{item.contentType}}</td>
                                    </tr>
                                    <tr>
                                        <td class="jobInfoTableLeft"></td>
                                        <td class="jobInfoTableRight">
                                            <button mat-mini-fab color="primary" aria-label="Delete Queue"
                                                (click)="getLogFile(item)">
                                                <!-- <button mat-mini-fab color="primary" aria-label="Delete Queue" (click)="drawer.toggle()" > -->
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                    viewBox="0 0 512 512" height="1em" width="1em"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            }
                        </div>
                        <div class="divItem">
                            <mat-spinner *ngIf="jobDrawerLoading"></mat-spinner>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </mat-drawer>
        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="jobsviewport">
            <div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="user-select: none;" matSort>
                    <ng-container matColumnDef="JobID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Job ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.JobID}} </td>
                    </ng-container>
                    <ng-container matColumnDef="JobName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.JobName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="SubmittedDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Submitted Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.SubmittedDate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Queue">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Queue </th>
                        <td mat-cell *matCellDef="let element"> {{element.Queue}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let element" [ngSwitch]="element.Status">
                            <mat-chip-option *ngSwitchCase="'QUEUED'" disableRipple
                                style="background-color: #57B9D6; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'RUNNING'" disableRipple
                                style="background-color: #57B9D6; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'SPOOLED'" disableRipple
                                style="background-color: #777777; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'FINISHED'" disableRipple
                                style="background-color: #69B02C; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'STOPPING'" disableRipple
                                style="background-color: #F1D86F; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'STOPPED'" disableRipple
                                style="background-color: #FFF032; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                            <mat-chip-option *ngSwitchCase="'FAILED'" disableRipple
                                style="background-color: #FF3228; cursor: context-menu;" selectable="false" highlighted>
                                {{element.Status}} </mat-chip-option>
                        </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-mini-fab color="primary" aria-label="Delete Queue"
                                (click)="getJobInfo(element)">
                                <!-- <button mat-mini-fab color="primary" aria-label="Delete Queue" (click)="drawer.toggle()" > -->
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                                    </path>
                                </svg>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
                <mat-card *ngIf="jobsLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
                <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);"
                    [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons
                    aria-label="Select page of periodic elements"></mat-paginator>

            </div>
        </cdk-virtual-scroll-viewport>
    </mat-drawer-container>

</div>