import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, NgModel, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../web-methods.service';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Buffer } from 'buffer';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ScrollingModule } from '@angular/cdk/scrolling';

enum flow {
  start,
  inDraft,
  end,
  loading,
  completing,
  save
}


@Component({
  selector: 'app-interactive-documents',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    ScrollingModule,
  ],
  templateUrl: './interactive-documents.component.html',
  styleUrl: './interactive-documents.component.scss'
})
export class InteractiveDocumentsComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private sanitizer: DomSanitizer, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal, private route: ActivatedRoute, private router: Router) { }
  embedURL: SafeResourceUrl;
  iframeData = ``;
  tenants = [{ id: '', EmbedURL: '' }];
  state: flow = flow.start;
  enum: typeof flow = flow;
  displayedColumns: string[] = ['docName', 'channel', 'action'];
  commentsNav = false;

  directoryLoading = false;
  dirFilesLoading = false;
  sendLoading = false;
  directories: any[] = [];
  selectedDirectory: any = null;
  dirFiles: any[] = [];
  selectedDirFile: any = null;

  projectID = "";
  templateSelector = "";
  draftData = "";
  workflow = "";
  queueName = "";
  steps: any = [];

  toEmail = "";
  toSubject = "";

  finalDocuments: any[] = [];
  hostname = "";
  fileName = "";

  editor: any;
  taskID: string;
  taskName: string;

  ngOnInit(): void {

    this.route.queryParamMap.subscribe((element: any) => {
      this.taskID = element.params.taskid;
    })
    // this.router.navigate(
    //   ['.'],
    //   { relativeTo: this.route, queryParams: {} }
    // );


    if (window.location.port == '') {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
    }
    else {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].EmbedURL);
        this.embedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].EmbedURL);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="` + this.embedURL + `" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
    if (this.taskID != undefined) {
      // this.viewTaks({});

      this.getSavedDraft(this.taskID);
    }
    else {
      this.getDirectories();

    }

  }

  getSavedDraft(TaskID: string) {
    this.state = flow.loading;
    this.oidcSecurityService.getUserData().subscribe((userData: any) => {
      var userID = userData.sub;
      var request = {
        tenantID: localStorage.getItem('currentTenant'),
        userID: userID,
        taskID: TaskID,
        poolID: ""
      }
      this.api.postService("/workflow/getTask", request)
        .then((response) => {
          // console.log(response.data);
          if (response.status == 200) {
            this.draftData = Buffer.from(response.data[0].DraftXML, "base64").toString("utf-8");
            this.workflow = response.data[0].WorkflowID == "" ? undefined : response.data[0].WorkflowID;
            this.projectID = response.data[0].ProjectID;
            this.templateSelector = response.data[0].TemplateSelector;
            this.taskName = response.data[0].Name;
            try {
              var localSteps = JSON.parse(Buffer.from(response.data[0].Steps, "base64").toString("utf-8"));
              this.steps = [];
              localSteps.forEach((element: any) => {
                element.DateTime = new Date(element.DateTime).toDateString() + " " + new Date(element.DateTime).toLocaleTimeString();
                this.steps.push(element);
              });
            }
            catch (err) { }
            setTimeout(() => {
              this.editor = window;
              this.editor = this.editor.SmartComms;
              this.editor = new this.editor.DraftEditor({
                server: 'https://eu10-sb.smartcommunications.cloud',
                clientServer: this.hostname,
                targetElement: 'draftEditorWindow',
                navigateAwayWarning: 'Are you sure you want to leave Draft-Editor?',
                onReady: () => {
                  // console.log("onReady");
                  this.state = flow.inDraft;
                  this.editor.v2.loadString(this.draftData, { openInPreview: false }, function () {
                  });
                },
                loadStartupConfig: () => ({
                  version: '2',
                  editingMode: 'defaultMode',
                  features: {
                    disabled: ['PasteMode']
                  },
                  sidePanel: { collapse: true, alignment: 'right' }
                })
              });
            },
              2000);

          }
          else {
            this.toastTemplates.showErrorToast("API error - Get Tasl", "Could not find task");
            this.state = flow.start;
            this.getDirectories();
            this.router.navigate(
              ['.'],
              { relativeTo: this.route, queryParams: {} }
            );
          }
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Get Tasl", "Could not find task");
          this.state = flow.start;
          this.getDirectories();
          this.router.navigate(
            ['.'],
            { relativeTo: this.route, queryParams: {} }
          );
        });
    });

  }


  getDirectories() {
    this.selectedDirFile = null;
    this.directoryLoading = true;
    this.directories = [];
    this.api.postService("/settings/listdirectoriesfortenant", { tenantID: localStorage.getItem('currentTenant') })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          this.directories = response.data;
        }
        else {
          this.toastTemplates.showErrorToast("API error - List Directories", "A connection error has occured. Please contact support for assistance.");
        }
        this.directoryLoading = false;
      })
      .catch((err) => {
        this.toastTemplates.showErrorToast("API error - List Directories", "Could not read directories.");
        // console.log(err);
        this.directoryLoading = false;
      });
  }

  getFiles(item: any) {
    this.selectedDirectory = item;
    this.selectedDirFile = null;
    console.log(item);
    this.dirFiles = [];
    this.dirFilesLoading = true;
    var s3Item = item.bucket.S;
    var s3Only = s3Item.substring(0, s3Item.lastIndexOf('/') + 1);
    var bucketOnly = s3Item.substring(s3Item.lastIndexOf('/') + 1);
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: s3Only,
      path: item.path.S,
      bucket: bucketOnly
    }
    // console.log(request);
    this.api.postService("/document/lists3content", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            var bodyData = JSON.parse(response.data.body);
            // console.log(bodyData);
            var dirList = []
            for (var a = 0; a < bodyData.Contents.length; a++) {
              if (bodyData.Contents[a].Key.slice(-1) == '/') {
              }
              else {
                dirList.push({ label: bodyData.Contents[a].Key.substring(bodyData.Contents[a].Key.lastIndexOf('/') + 1), data: { Key: bodyData.Contents[a].Key, ETag: bodyData.Contents[a].ETag, LastModified: bodyData.Contents[a].LastModified } });
              }
            }
            // console.log(dirList);
            this.dirFiles = dirList;
          }
          else {
            this.toastTemplates.showErrorToast("API error - List Files", "A connection error has occured. Please contact support for assistance.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - List Files", "Could not read files from S3 bucket.");
        }
        this.dirFilesLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - List Files", "Could not read files.");
        this.dirFilesLoading = false;
      });
  }

  selectFile(item: any) {
    // console.log(item.source._value);
    this.selectedDirFile = item.source._value;
  }

  loadData() {
    this.state = flow.loading;
    // console.log(this.selectedDirectory);
    // console.log(this.selectedDirFile);
    var dir = this.selectedDirectory.bucket.S;
    var url = dir.substring(0, dir.lastIndexOf("/") + 1);
    var bucketName = dir.substring(dir.lastIndexOf("/") + 1);
    // console.log(url);
    // console.log(bucketName);
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: url,
      path: this.selectedDirFile[0].data.Key,
      bucket: bucketName
    }
    // console.log(request);
    this.api.postService("/document/get-document", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            var body = JSON.parse(response.data.body);
            // console.log(body);
            this.projectID = body.Metadata.sc_project_id;
            this.templateSelector = body.Metadata.sc_template_selector_id;
            this.workflow = body.Metadata.workflow;
            this.queueName = body.Metadata.queue;
            this.createDraft(body.Metadata.sc_project_id, body.Metadata.sc_template_selector_id, body.document, body.mimeType);
          }
          else {
            this.toastTemplates.showErrorToast("API error - Read file", "A connection error has occured. Please contact support for assistance.");
            this.state = flow.start;
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Read file", "A connection error has occured. Please contact support for assistance.");
          this.state = flow.start;
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Read file", "File could not be retrieved.");
        this.state = flow.start;
      })
  }

  createDraft(projectIDLocal: string, templateSelectorLocal: string, fileLocal: string, fileTypeLocal: string) {
    this.fileName = fileLocal;
    this.state = flow.loading;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      projectID: projectIDLocal,
      batchConfigResId: templateSelectorLocal,
      transactionData: fileLocal,
      transactionDataType: fileTypeLocal,
    }
    this.api.postService("/sc/createdraft", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          this.draftData = Buffer.from(response.data.data, "base64").toString("utf-8");
          this.editor = window;
          this.editor = this.editor.SmartComms;
          this.editor = new this.editor.DraftEditor({
            server: 'https://eu10-sb.smartcommunications.cloud',
            clientServer: this.hostname,
            targetElement: 'draftEditorWindow',
            navigateAwayWarning: 'Are you sure you want to leave Draft-Editor?',
            onReady: () => {
              // console.log("onReady");
              this.state = flow.inDraft;
              this.editor.v2.loadString(this.draftData, { openInPreview: false }, function () {
              });
            },
            loadStartupConfig: () => ({
              version: '2',
              editingMode: 'defaultMode',
              features: {
                disabled: ['PasteMode']
              },
              sidePanel: { collapse: true, alignment: 'right' }
            }),
            save: (data: any) => {
              // console.log(data);
            }
          });

        }
        else {
          this.toastTemplates.showErrorToast("API error - Create Draft", "A connection error has occured. Please contact support for assistance.");
          this.state = flow.start;
        }

      })
      .catch((err) => {
        console.log(err);
        this.toastTemplates.showErrorToast("API error - Create Draft", "Draft could not be created. Please check your data file.");
        this.state = flow.start;
      })
  }

  viewFileInformation(data: any) {
    console.log(data);
    var dialog = this.dialog.open(FileInfoDialog, { maxWidth: '100vw', maxHeight: '100vh', data: { 'file': data, 'directory': this.selectedDirectory }, disableClose: true });
  }

  saveDraft(done: any, submitWF: any) {
    this.editor.v2.saveToString((results: any, res2: any) => {
      // console.log(results);
      // console.log(res2);
      this.state = flow.save;
      document.getElementById('draftEditorWindow')!.setAttribute('src', '');

      this.api.postService("/settings/listworkflows", { tenantID: localStorage.getItem('currentTenant') }).then((wfResp) => {
        if (wfResp.status == 200) {
          this.oidcSecurityService.getUserData().subscribe((userData) => {
            var request = {};
            var poolID = "";
            var poolName = "";
            if (done) {
              poolID = "Complete";
            }
            if (submitWF) {
              // console.log(wfResp);
              var wf = wfResp.data.find((element: any) => { return element.id == this.workflow });
              poolID = wf.wfPools[0].id;
              poolName = wf.wfPools[0].Name;
            }
            if (this.taskID == undefined) {
              request = {
                tenantID: localStorage.getItem('currentTenant'),
                name: this.selectedDirFile[0].label,
                projectId: this.projectID,
                workflowID: this.workflow ? this.workflow : "",
                poolID: poolID,
                poolName: poolName,
                creator: userData.sub,
                steps: "",
                templateSelector: this.templateSelector,
                queue: this.queueName ? this.queueName : '',
                draftXML: Buffer.from(res2.draftXML).toString('base64')
              }
              this.api.postService("/workflow/createTask", request).then((response) => {
                if (response.status == 200) {
                  this.state = flow.start;
                }
                else {
                  this.toastTemplates.showErrorToast("API error - Save Draft", "The draft could not be saved.");
                  this.state = flow.start;
                }

              })
                .catch((err) => {
                  // console.log(err);
                  this.toastTemplates.showErrorToast("API error - Save Draft", "The draft could not be saved.");
                  this.state = flow.start;
                });
            }
            else {
              request = {
                taskID: this.taskID,
                tenantID: localStorage.getItem('currentTenant'),
                draftXML: Buffer.from(res2.draftXML).toString('base64'),
                poolID: poolID,
                poolName: poolName,
                taskName: this.taskName,
                updater: userData.sub
              }
              this.api.postService("/workflow/updateTask", request).then((response) => {
                if (response.status == 200) {
                  this.state = flow.start;
                }
                else {
                  this.toastTemplates.showErrorToast("API error - Save Draft", "The draft could not be saved.");
                  this.state = flow.start;
                }
                this.router.navigate(
                  ['.'],
                  { relativeTo: this.route, queryParams: {} }
                );
              })
                .catch((err) => {
                  // console.log(err);
                  this.toastTemplates.showErrorToast("API error - Save Draft", "The draft could not be saved.");
                  this.state = flow.start;
                  this.router.navigate(
                    ['.'],
                    { relativeTo: this.route, queryParams: {} }
                  );
                });
            }
          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - SGet Workflows", "Could not read workflows.");
        }
      })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - SGet Workflows", "Could not read workflows.");
          this.state = flow.start;
        });
    });
  }


  submitToWorkflow() {

  }


  reworkDraft() {
    this.state = flow.loading;
    this.editor = window;
    this.editor = this.editor.SmartComms;
    this.editor = new this.editor.DraftEditor({
      server: 'https://eu10-sb.smartcommunications.cloud',
      clientServer: this.hostname,
      targetElement: 'draftEditorWindow',
      navigateAwayWarning: 'Are you sure you want to leave Draft-Editor?',
      onReady: () => {
        // console.log("onReady");
        this.state = flow.inDraft;
        this.editor.v2.loadString(this.draftData, { openInPreview: false }, function () {
        });
      },
      loadStartupConfig: () => ({
        version: '2',
        editingMode: 'defaultMode',
        features: {
          disabled: ['PasteMode']
        },
        sidePanel: { collapse: true, alignment: 'right' }
      }),

    });
  }

  cancelDraft() {
    this.state = flow.start;
    document.getElementById('draftEditorWindow')!.setAttribute('src', '');
    this.selectedDirFile = null;
    this.dirFiles = [];
    this.getDirectories();
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: {} }
    );
  }

  completeDraft() {
    this.editor.v2.saveToString((results: any, res2: any) => {
      // console.log(results);
      // console.log(res2);
      if (res2.errors.length == 0) {
        this.state = flow.completing;
        this.draftData = res2.draftXML;
        var request = {
          tenantID: localStorage.getItem('currentTenant'),
          projectId: this.projectID,
          reviewCaseData: Buffer.from(res2.draftXML).toString('base64')
        }
        this.api.postService("/sc/finalizedraft", request)
          .then((response) => {
            var resultObject = response.data;

            var docTable = [];
            for (var a = 0; a < resultObject.envelopes.length; a++) {
              try {
                var docTableRow = { documentName: resultObject.envelopes[a].primaryChannel.documentName, channelName: resultObject.envelopes[a].primaryChannel.channelName, doc: resultObject.envelopes[a].primaryChannel.data, mime: resultObject.envelopes[a].primaryChannel.mimeType, distType: 'primary' };
                for (var d = 0; d < resultObject.envelopes[a].primaryChannel.properties.length; d++) {
                  // console.log(resultObject.envelopes[a].primaryChannel.properties[d].name);
                  // console.log(resultObject.envelopes[a].primaryChannel.properties[d].value);
                  if (resultObject.envelopes[a].primaryChannel.properties[d].name == 'smtp.destinationaddress') {
                    this.toEmail = resultObject.envelopes[a].primaryChannel.properties[d].value;
                  }
                  else if (resultObject.envelopes[a].primaryChannel.properties[d].name == 'smtp.subject') {
                    this.toSubject = resultObject.envelopes[a].primaryChannel.properties[d].value;
                  }
                }
                docTable.push(docTableRow);
              } catch (err) { console.log(err) }
              try {
                for (var b = 0; b < resultObject.envelopes[a].enclosureChannels.length; b++) {
                  var docTableRow = { documentName: resultObject.envelopes[a].enclosureChannels[b].documentName, channelName: resultObject.envelopes[a].enclosureChannels[b].channelName, doc: resultObject.envelopes[a].enclosureChannels[b].data, mime: resultObject.envelopes[a].enclosureChannels[b].mimeType, distType: 'enclosure' };
                  docTable.push(docTableRow);
                }

              } catch (err) { console.log(err) }
            }
            this.finalDocuments = docTable;
            // console.log(docTable);

            // document.getElementById('draftEditorWindow')!.setAttribute('src', '');
            this.state = flow.end;
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Complete Draft", "The draft could not be completed.");
          })
      }
    })
  }


  viewDocument(item: any) {
    // console.log(item);
    var dialog = this.dialog.open(PreviewDialog, { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh', data: item, disableClose: true });
  }


  sendEmail() {
    this.sendLoading = true;
    var attach = [];
    var emailBodyData = '';
    for (var a = 0; a < this.finalDocuments.length; a++) {
      if (this.finalDocuments[a].distType == "enclosure") {
        var mime = "pdf";
        // console.log(this.finalDocuments[a].mime);
        switch (this.finalDocuments[a].mime) {
          case "application/pdf": mime = "pdf"; break;
          case "text/html": mime = "html"; break;
        }
        var packageItem = {
          fileName: this.finalDocuments[a].documentName + '.' + mime,
          fileData: this.finalDocuments[a].doc
        }
        attach.push(packageItem);
      }
      else {
        emailBodyData = this.finalDocuments[a].doc;
      }
    }
    var request = {
      emailAttachments: attach,
      emailBody: emailBodyData,
      emailSubject: this.toSubject,
      emailTo: this.toEmail
    }
    // console.log(request);
    this.api.postService("/mail/sendenvelope", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            this.toastTemplates.showSuccessToast("Send Email", "Email sent successfully");
          }
          else {
            this.toastTemplates.showErrorToast("API error - Send Email", "Email could not be sent");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Send Email", "Email could not be sent");
        }

        this.sendLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Send Email", "Email could not be sent");
        this.sendLoading = false;
      })
  }

  openComments() {
    // console.log("Open comments");
    this.commentsNav = !this.commentsNav;
  }
}

@Component({
  selector: 'preview-dialog',
  templateUrl: 'preview-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  styleUrl: './interactive-documents.component.scss'
})
export class PreviewDialog implements OnInit {
  constructor(private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<PreviewDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  docData: SafeResourceUrl;
  ngOnInit(): void {
    // console.log(this.data);
    const url = URL.createObjectURL(new Blob([Buffer.from(this.data.doc, 'base64')], { type: this.data.mime }));
    this.docData = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'file-info-dialog',
  templateUrl: 'file-info-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  styleUrl: './interactive-documents.component.scss'
})
export class FileInfoDialog implements OnInit {
  constructor(private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<FileInfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  projectID = 'loading';
  projectName = 'loading';
  templateSelector = 'loading';
  templateSelectorName = 'loading';
  workflow = 'loading';
  workflowName = 'loading';
  queueName = 'loading';
  ngOnInit(): void {
    console.log(this.data);
    this.getFileInfo();
  }
  getFileInfo() {
    var dir = this.data.directory.bucket.S;
    var url = dir.substring(0, dir.lastIndexOf("/") + 1);
    var bucketName = dir.substring(dir.lastIndexOf("/") + 1);
    // console.log(url);
    // console.log(bucketName);
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: url,
      path: this.data.file.data.Key,
      bucket: bucketName
    }
    // console.log(request);
    this.api.postService("/document/get-document", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            var body = JSON.parse(response.data.body);
            // console.log(body);
            this.projectID = body.Metadata.sc_project_id;
            this.templateSelector = body.Metadata.sc_template_selector_id;
            this.workflow = body.Metadata.workflow;
            this.queueName = body.Metadata.queue;
            if(body.Metadata.sc_project_id){
              this.getProject(body.Metadata.sc_project_id);
            }
            else{
              this.projectName = '';
            }
            if(body.Metadata.sc_project_id && body.Metadata.sc_template_selector_id){
              this.getTemplateSelector(body.Metadata.sc_project_id, body.Metadata.sc_template_selector_id);
            }
            else{
              this.templateSelectorName = '';
            }
            if(body.Metadata.workflow){
              this.getWorkflow(body.Metadata.workflow);
            }
            else{
              this.workflowName = '';
            }
            
          }
          else {
            this.toastTemplates.showErrorToast("API error - Read file", "A connection error has occured. Please contact support for assistance.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Read file", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        console.log(err);
        this.toastTemplates.showErrorToast("API error - Read file", "File could not be retrieved.");
      });
  }

  getProject(project: string){
    var request = {
      tenantID: localStorage.getItem('currentTenant')
    }
    // console.log(request);
    this.api.postService("/sc/listprojects", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
            var body = response.data;
            // console.log(body);
            var projectItem = body.find((item:any) => {
              return item.id == project;
            })
            // console.log(projectItem);
            this.projectName = projectItem.name;
        }
        else {
          this.projectName = '';
          this.toastTemplates.showErrorToast("API error - Read Project", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        this.projectName = '';
        console.log(err);
        this.toastTemplates.showErrorToast("API error - Read Project", "Project could not be retrieved.");
      });
  }

  getTemplateSelector(projectID: string, ts: string){
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      projectID: projectID
    }
    // console.log(request);
    this.api.postService("/sc/listtemplateselector", request)
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
            var body = response.data;
            console.log(body);
            var tsItem = body.find((item: any) => {
              return item.resourceId == ts;
            });
            this.templateSelectorName = tsItem.resourceName;
        }
        else {
          this.templateSelectorName = '';
          this.toastTemplates.showErrorToast("API error - Read Template Selector", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        this.templateSelectorName = '';
        console.log(err);
        this.toastTemplates.showErrorToast("API error - Read Template Selector", "Template Selector not be retrieved.");
      });
  }

  getWorkflow(wfID: string){
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          var body = response.data;
          var wfItem = body.find((item: any) => {
            return item.id == wfID;
          });
          this.workflowName = wfItem.workflowName;
        }
        else{
          this.toastTemplates.showErrorToast("API error - Get Workflows", 'Please contact support for assistance.');
          this.workflowName = '';
        }
      })
      .catch((err) => {
        // console.log(err);
        this.workflowName = '';
        this.toastTemplates.showErrorToast("API error - Get Workflows", err);
      });
  }

}