<div class="mainDiv">
    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <button class="blueButton" style="margin-bottom: 15px;" (click)="addTenant()">
            <div style="display: flex; flex-direction: row; align-items: baseline; gap: 10px;">
                <div style="height: 20px;">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15px"
                        width="15px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18 15L17.999 18H21V20H17.999L18 23H16L15.999 20H13V18H15.999L16 15H18ZM11 18V20H3V18H11ZM21 11V13H3V11H21ZM21 4V6H3V4H21Z">
                        </path>
                    </svg>
                </div>
                <div style="height: 15px;">
                    <p>Add Tenant</p>
                </div>
            </div>
        </button>
        <div
            style="margin-top: 10px; margin-bottom: 5px; margin-left: 10px; display: flex; flex-direction: row; justify-content: flex-start;">
            <div class="input-icons">
                <i class="icon">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </i>
                <input class="filterInput" [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Find Tenant by Tenant Name"
                    #input>
                <i class=" icon iconRight" (click)="clearFilter()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z">
                        </path>
                    </svg>
                </i>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="SC_ExternalID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant External ID </th>
            <td mat-cell *matCellDef="let element"> {{element.SC_ExternalID}} </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tenant Name </th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab color="primary" aria-label="Delete Queue" (click)="viewTenant(element)">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-card *ngIf="tenantsLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>