import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-usage-stats',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule
  ],
  templateUrl: './usage-stats.component.html',
  styleUrl: './usage-stats.component.scss'
})
export class UsageStatsComponent {

}
