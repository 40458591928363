import { Component, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { WebMethodsService } from '../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { NgChartsModule } from 'ng2-charts';
import * as echarts from 'echarts';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';

@Component({
  selector: 'app-usage-statistics',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgChartsModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgbPopoverModule,
    AdvancedDateSelectionComponent,
  ],
  templateUrl: './usage-statistics.component.html',
  styleUrl: './usage-statistics.component.scss',
})
export class UsageStatisticsComponent implements OnInit {
  loadingTotDocsCard = false;
  loadingTotPerOutputCard = false;
  loadingTotPerTemplateCard = false;
  loadingTimePerOutputCard = false;
  loadingTimePerTemplateCard = false;
  loadingTotPerTemplateCardGraph = false;
  loadingTemplatePeriodCardGraph = false;

  totDocs = 0;
  totDocsPeriod = 0;
  totDocsThisMonth = 0;
  totDocsLastMonth = 0;
  totalOutputItems = ``;
  totalTemplateItems = ``;
  timeOutputItems = `<p class="cardSmallValue">N/A</p>`;
  timeTemplateItems = `<p class="cardSmallValue">N/A</p>`;
  percentChangeOnTotal = ``;

  currentUser: any = "Rudi";

  selectedGraphType = new FormControl('bar', Validators.required);
  selectedScale = new FormControl('day', Validators.required);
  selectedGroup = new FormControl('total', Validators.required);

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };

  constructor(private api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  ngOnInit() {
    this.loadingTotDocsCard = true;
    this.loadingTotPerOutputCard = true;
    this.loadingTotPerTemplateCard = true;
    this.loadingTotPerTemplateCardGraph = true;
    this.getStats();

    this.api.postService("/es/documents-generated-by-channel", {
      "tenantID": localStorage.getItem('currentTenant'),
      "dateStart": "2000-01-01T00:00:00.000Z",
      "dateEnd": new Date().toISOString()
    })
      .then((response) => {
        // console.log(response);
        if (("Message" in response.data)) {
          // console.log(response.data.Message);
          this.toastTemplates.showErrorToast("API error - Get Totals", response.data.Message);
          this.loadingTotDocsCard = false;
        }
        else {
          for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
            this.totDocs = this.totDocs + response.data.aggregations.all.buckets[a].doc_count;
            this.totalOutputItems = this.totalOutputItems + `<div>
              <p class="cardSmallHeader">`+ response.data.aggregations.all.buckets[a].key + `</p>
            <p class="cardSmallValue">`+ response.data.aggregations.all.buckets[a].doc_count + `</p>
            </div>`;
          }
          if (response.data.aggregations.all.buckets.length == 0) {
            this.totalOutputItems = `<p class="cardSmallValue">N/A</p>`;
          }


          this.api.postService("/es/documents-generated-by-channel", {
            "tenantID": localStorage.getItem('currentTenant'),
            "dateStart": new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
            "dateEnd": new Date().toISOString()
          })
            .then((response) => {
              // console.log(response);
              if (("Message" in response.data)) {
                // console.log(response.data.Message);
                this.toastTemplates.showErrorToast("API error", response.data.Message);
                this.loadingTotDocsCard = false;
              }
              else {
                this.totDocsThisMonth = response.data.hits.total.value;

                this.api.postService("/es/documents-generated-by-channel", {
                  "tenantID": localStorage.getItem('currentTenant'),
                  "dateStart": new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString(),
                  "dateEnd": new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString()
                })
                  .then((response) => {
                    // console.log(response);
                    if (("Message" in response.data)) {
                      // console.log(response.data.Message);
                      this.toastTemplates.showErrorToast("API error", response.data.Message);
                      this.loadingTotDocsCard = false;
                    }
                    else {
                      this.totDocsLastMonth = response.data.hits.total.value;


                      if (this.totDocsThisMonth > this.totDocsLastMonth) {
                        var diffAmount = this.totDocsThisMonth - this.totDocsLastMonth;
                        var percentDif = (diffAmount / this.totDocsThisMonth) * 100;
                        this.percentChangeOnTotal = `<p class="statLine1Pos"><b>↑</b> ` + Math.round(percentDif) + `%</p>`;
                      }
                      else if (this.totDocsThisMonth < this.totDocsLastMonth) {
                        var diffAmount = this.totDocsLastMonth - this.totDocsThisMonth;
                        var percentDif = (diffAmount / this.totDocsLastMonth) * 100;
                        this.percentChangeOnTotal = `<p class="statLine1Neg"><b>↓</b> ` + Math.round(percentDif) + `%</p>`;
                      }
                      else {
                        this.percentChangeOnTotal = `<p class="statLine1">No change</p>`;
                      }
                    }
                    this.loadingTotDocsCard = false;
                  })
                  .catch((error) => {
                    // console.log(error);
                    this.loadingTotDocsCard = false;
                  });
              }
            })
            .catch((error) => {
              // console.log(error);
              this.loadingTotDocsCard = false;
            });
        }

        this.loadingTotPerOutputCard = false;
      })
      .catch((error) => {
        // console.log(error);
        this.loadingTotPerOutputCard = false;
        this.loadingTotDocsCard = false;
      });

    this.api.postService("/es/documents-generated-per-template", {
      "tenantID": localStorage.getItem('currentTenant'),
      "dateStart": "2000-01-01T00:00:00.000Z",
      "dateEnd": new Date().toISOString()
    })
      .then((response) => {
        if (("Message" in response.data)) {
          // console.log(response.data.Message);
          this.toastTemplates.showErrorToast("API error - Get total Template", response.data.Message);
          this.loadingTotPerTemplateCard = false;
        }
        else {
          for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
            this.totalTemplateItems = this.totalTemplateItems + `
                <p class="cardSmallHeader">`+ response.data.aggregations.all.buckets[a].key + `</p>
                <p class="cardSmallValue">`+ response.data.aggregations.all.buckets[a].doc_count + `</p>
              `;
          }
          if (response.data.aggregations.all.buckets.length == 0) {
            this.totalTemplateItems = `<p class="cardSmallValue">N/A</p>`;
          }
        }
        this.loadingTotPerTemplateCard = false;
      })
      .catch((error) => {
        // console.log(error);
        this.loadingTotPerTemplateCard = false;
      });

    this.api.postService("/es/documents-grouped", {
      "tenantID": localStorage.getItem('currentTenant'),
      "dateStart": "2000-01-01T00:00:00.000Z",
      "dateEnd": new Date().toISOString(),
      "scale": "day"
    })
      .then((response) => {
        if (("Message" in response.data)) {
          // console.log(response.data.Message);
          this.toastTemplates.showErrorToast("API error - Get total Template Graph", response.data.Message);
          this.loadingTotPerTemplateCardGraph = false;
        }
        else {
          // console.log(response);
          var labels = [];
          var dataset = [];
          var newData = [];
          for (var a = 0; a < response.data.aggregations.group_by_date.buckets.length; a++) {
            labels.push(new Date(response.data.aggregations.group_by_date.buckets[a].key_as_string).toLocaleDateString());
            dataset.push(response.data.aggregations.group_by_date.buckets[a].doc_count);
            newData.push([new Date(response.data.aggregations.group_by_date.buckets[a].key_as_string), response.data.aggregations.group_by_date.buckets[a].doc_count]);
          }
          // console.log(labels);
          // console.log(dataset);
          // console.log("New Data");
          // console.log(newData);

          var chartDom = document.getElementById('TotPerTemplateCardGraph');
          var myChart = echarts.init(chartDom, null, {
            renderer: 'canvas',
            useDirtyRect: false
          });
          var option = {
            xAxis: {
              type: 'time',
            },
            yAxis: {
              type: 'value',
            },
            tooltip: {
              trigger: 'axis'
            },
            series: [
              {
                name: 'Documents',
                type: 'line',
                smooth: false,
                symbol: 'none',
                areaStyle: {},
                data: newData
              }
            ]
          };
          option && myChart.setOption(option);
          myChart.renderToCanvas();
        }
        this.loadingTotPerTemplateCardGraph = false;
      })
      .catch((error) => {
        // console.log(error);
        this.loadingTotPerTemplateCardGraph = false;
      });
  }

  dateUpdated(data: any) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getStats();
  }

  getStats() {
    if (
      !this.range.controls.start.hasError('matStartDateInvalid') &&
      !this.range.controls.end.hasError('matEndDateInvalid') &&
      this.range.controls.start.value != null &&
      this.range.controls.end.value != null
    ) {

      this.loadingTimePerOutputCard = true;
      this.loadingTimePerTemplateCard = true;
      this.timeOutputItems = ``;
      this.timeTemplateItems = ``;

      this.getDocsGraph();

      this.api.postService("/es/documents-generated-by-channel", {
        "tenantID": localStorage.getItem('currentTenant'),
        "dateStart": this.range.controls.start.value?.toISOString(),
        "dateEnd": this.range.controls.end.value?.toISOString()
      })
        .then((response) => {
          if ("Message" in response.data) {
            this.toastTemplates.showErrorToast("API error - Get Output", response.data.Message);
          }
          else {
            this.totDocsPeriod = 0;
            for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
              this.totDocsPeriod = this.totDocsPeriod + response.data.aggregations.all.buckets[a].doc_count;
              this.timeOutputItems = this.timeOutputItems + `<div>
            <p class="cardSmallHeader">`+ response.data.aggregations.all.buckets[a].key + `</p>
          <p class="cardSmallValue">`+ response.data.aggregations.all.buckets[a].doc_count + `</p>
          </div>`;
            }
            if (response.data.aggregations.all.buckets.length == 0) {
              this.timeOutputItems = `<p class="cardSmallValue">N/A</p>`;
            }
          }
          this.loadingTimePerOutputCard = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loadingTimePerOutputCard = false;
        });

      this.api.postService("/es/documents-generated-per-template", {
        "tenantID": localStorage.getItem('currentTenant'),
        "dateStart": this.range.controls.start.value?.toISOString(),
        "dateEnd": this.range.controls.end.value?.toISOString()
      })
        .then((response) => {
          if ("Message" in response.data) {
            this.toastTemplates.showErrorToast("API error - Get Templates", response.data.Message);
          }
          else {
            for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
              this.timeTemplateItems = this.timeTemplateItems + `
                <p class="cardSmallHeader">`+ response.data.aggregations.all.buckets[a].key + `</p>
                <p class="cardSmallValue">`+ response.data.aggregations.all.buckets[a].doc_count + `</p>
              `;
            }
            if (response.data.aggregations.all.buckets.length == 0) {
              this.timeTemplateItems = `<p class="cardSmallValue">N/A</p>`;
            }
          }
          this.loadingTimePerTemplateCard = false;
        })
        .catch((error) => {
          // console.log(error);
          this.loadingTimePerTemplateCard = false;
        });
    }
  }

  getDocsGraph() {
    this.loadingTemplatePeriodCardGraph = true;
    this.api.postService("/es/documents-grouped", {
      "tenantID": localStorage.getItem('currentTenant'),
      "dateStart": this.range.controls.start.value?.toISOString(),
      "dateEnd": this.range.controls.end.value?.toISOString(),
      "scale": this.selectedScale.value,
      "filter": this.selectedGroup.value
    })
      .then((response) => {
        if (("Message" in response.data)) {
          // console.log(response.data.Message);
          this.toastTemplates.showErrorToast("API error - Get total Template Graph", response.data.Message);
          this.loadingTemplatePeriodCardGraph = false;
        }
        else {
          var option;
          // console.log(response);
          var chartDom = document.getElementById('TemplatePeriodCardGraph');
          var myChart = echarts.init(chartDom);
          myChart.clear();
          if (this.selectedGroup.value == "total") {
            var labels = [];
            var dataset = [];
            var newData = [];
            for (var a = 0; a < response.data.aggregations.group_by_date.buckets.length; a++) {
              labels.push(new Date(response.data.aggregations.group_by_date.buckets[a].key_as_string).toLocaleDateString());
              dataset.push(response.data.aggregations.group_by_date.buckets[a].doc_count);
              newData.push([new Date(response.data.aggregations.group_by_date.buckets[a].key_as_string), response.data.aggregations.group_by_date.buckets[a].doc_count]);
            }
            // console.log(labels);
            // console.log(dataset);
            // console.log("New Data");
            // console.log(newData);
            option = {
              xAxis: {
                type: 'time',
              },
              yAxis: {
                type: 'value',
              },
              tooltip: {
                trigger: 'axis'
              },
              series: [
                {
                  name: 'Envelopes',
                  type: this.selectedGraphType.value,
                  smooth: false,
                  symbol: 'none',
                  stack: 'Total',
                  areaStyle: {},
                  data: newData
                }
              ]
            };
          }
          else if (this.selectedGroup.value == "template") {
            var seriesData = this.formatEStoGraphSeries(response.data.aggregations.group_by_category.buckets, this.selectedGraphType.value!);

            option = {
              xAxis: {
                type: 'time',
              },
              yAxis: {
                type: 'value',
              },
              tooltip: {
                trigger: "axis"
              },
              series: seriesData
            };
          }
          else if (this.selectedGroup.value == "channel") {
            var seriesData = this.formatEStoGraphSeries(response.data.aggregations.group_by_category.buckets, this.selectedGraphType.value!);

            option = {
              xAxis: {
                type: 'time',
              },
              yAxis: {
                type: 'value',
              },
              tooltip: {
                trigger: "axis"
              },
              series: seriesData
            };
          }
          option && myChart.setOption(option);
          myChart.renderToCanvas();
        }
        this.loadingTemplatePeriodCardGraph = false;
      })
      .catch((error) => {
        // console.log(error);
        this.loadingTemplatePeriodCardGraph = false;
      });
  }

  scaleChanged() {
    // console.log(this.selectedScale.value);
    this.getDocsGraph();
  }

  graphChanged() {
    // console.log(this.selectedGraphType.value);
    this.getDocsGraph();
  }

  groupChanged() {
    // console.log(this.selectedGroup.value);
    this.getDocsGraph();
  }

  formatEStoGraphSeries(raw: any, type: string) {
    var series: any = [];

    for (var a = 0; a < raw.length; a++) {
      var data = [];
      for (var b = 0; b < raw[a].group_by_date.buckets.length; b++) {
        data.push([raw[a].group_by_date.buckets[b].key_as_string, raw[a].group_by_date.buckets[b].doc_count]);
      }
      series.push(
        {
          name: raw[a].key,
          type: type,
          smooth: false,
          symbol: 'none',
          stack: 'Total',
          areaStyle: {},
          data: data,
          label: {
            show: true,
            position: 'top'
          }
        }
      );
    }


    return series;
  }

  
}
