import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebMethodsService } from './web-methods.service';
import { NgbdToastGlobal } from './toast/toast-global.component';
import { Subscription } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { webSocketService } from './web-socket.service';
import { environment } from '../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


interface Tenant {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(public oidcSecurityService: OidcSecurityService, public dialog: MatDialog, private route: Router, private api: WebMethodsService, private avtiveRoute: ActivatedRoute, private toastTemplates: NgbdToastGlobal, private readonly notificationsService: NotificationsService, private webSocketService: webSocketService, private sanitizer: DomSanitizer) {
    route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navHomeStyle = false;
        this.naveUsageStyle = false;
        this.navArchiveStyle = false;
        this.navSubmitJobsStyle = false;
        this.navJobstatusStyle = false;
        this.navInteractiveNewStyle = false;
        this.navInteractiveApproveStyle = false;
        this.navInteractiveMyDraftStyle = false;
        this.navInteractiveCompletedTasksStyle = false;
        this.navReportsStyle = false;
        this.navTemplatingStyle = false;
        this.navProcessStyle = false;
        this.navSettingsStyle = false;
        this.navHelpStyle = false;
        this.navContactStyle = false;
        this.navAdminStyle = false;
        switch (val.url) {
          case "/portal": this.navHomeStyle = true; break;
          case "/usage-statistics": this.naveUsageStyle = true; break;
          case "/document-archive": this.navArchiveStyle = true; break;
          case "/submit-jobs": this.navSubmitJobsStyle = true; this.jobsOpen = true; break;
          case "/job-status": this.navJobstatusStyle = true; this.jobsOpen = true; break;
          case "/interactive/newDraft": this.navInteractiveNewStyle = true; break;
          case "/interactive/workflowApproval": this.navInteractiveApproveStyle = true; break;
          case "/interactive/mydrafts": this.navInteractiveMyDraftStyle = true; break;
          case "/interactive/completedTasks": this.navInteractiveCompletedTasksStyle = true; break;
          case "/reports": this.navReportsStyle = true; break;
          case "/templating": this.navTemplatingStyle = true; break;
          case "/processServices": this.navProcessStyle = true; break;
          case "/settings/account-settings": this.navSettingsStyle = true; break;
          case "/settings/queues": this.navSettingsStyle = true; break;
          case "/settings/interactive": this.navSettingsStyle = true; break;
          case "/settings/templates": this.navSettingsStyle = true; break;
          case "/settings/api": this.navSettingsStyle = true; break;
          case "/settings/workflows": this.navSettingsStyle = true; break;
          case "/settings/users": this.navSettingsStyle = true; break;
          case "/settings/groups": this.navSettingsStyle = true; break;
          case "/settings/roles": this.navSettingsStyle = true; break;
          case "/settings/pools": this.navSettingsStyle = true; break;
          case "/help": this.navHelpStyle = true; break;
          case "/contact-us": this.navContactStyle = true; break;
          case "/admin/tenants": this.navAdminStyle = true; break;
          case "/admin/users": this.navAdminStyle = true; break;
          case "/admin/permissions": this.navAdminStyle = true; break;
          case "/admin/groups": this.navAdminStyle = true; break;
          case "/admin/roles": this.navAdminStyle = true; break;
          case "/admin/pools": this.navAdminStyle = true; break;
        }
      }
    });
  }

  subscription: Subscription;

  navHomeStyle = false;
  naveUsageStyle = false;
  navArchiveStyle = false;
  navSubmitJobsStyle = false;
  navJobstatusStyle = false;
  navInteractiveNewStyle = false;
  navInteractiveApproveStyle = false;
  navInteractiveMyDraftStyle = false;
  navInteractiveCompletedTasksStyle = false;
  navReportsStyle = false;
  navTemplatingStyle = false;
  navProcessStyle = false;
  navSettingsStyle = false;
  navHelpStyle = false;
  navContactStyle = false;
  navAdminStyle = false;

  notifications: any = [];

  

  isAuthenticated = false;
  name = "";
  email = "";
  username = "";
  userID = "";
  permCGAdmin = false;
  permAdmin = false;
  permArchive = false;
  permInteractive = false;
  permTemplate = false;
  permProc = false;
  permView = false;
  menuDocker = false;
  drawerMode: MatDrawerMode = 'over';
  jobsOpen = false;
  interactiveOpen = false;
  currentTenant = '';
  currentTenantName = '';

  testSome = false;

  showUsage = false;
  showArchive = false;
  showJobs = false;
  showInteractive = false;
  showReports = false;
  showTemplating = false;
  showProcess = false;
  showSettings = false;
  showHelp = false;
  showContact = false;
  showAdmin = false;

  hostname = "";
  tenants = [{ id: '', EmbedURL: '' }];
  embedURL: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  iframeData = ``;

  tenantList: Tenant[];

  environmentName = "";

  ngOnInit() {
    console.log(this.embedURL);
    this.environmentName = environment.environmentName;

    
    this.subscription = this.notificationsService.notificationItems$.subscribe(notificationItems => {
      this.notifications = [];
      notificationItems.forEach((element: any) => {
        if(!element.Read){
          this.notifications.push(element);
        }
      });
    });
    this.menuDocker = localStorage.getItem('menuDock') == 'true' ? true : false;
    this.drawerMode = localStorage.getItem('menuDock') == 'true' ? 'side' : 'over';
    // console.log(window.location.href);
    this.oidcSecurityService
      .checkAuth()
      .subscribe(({ isAuthenticated, userData, accessToken, idToken, configId }) => {
        if (!isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
        else{
          if (window.location.port == '') {
            this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
          }
          else {
            this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
          }
          // this.tenants = JSON.parse(localStorage.getItem('tenants')!);
          // console.log('Tenants: ')
          // console.log(this.tenants)

        }
        this.webSocketService.connect();
        this.isAuthenticated = isAuthenticated;
        this.name = userData.name!;
        this.email = userData.email!;
        this.username = userData.preferred_username!;
        this.userID = userData.sub!;
        const groupsList = userData.groups;
        var groupCGAdmin = false;
        try {
          groupCGAdmin = groupsList.includes('CGAdmin');
        }
        catch (err) { }
        var postData = { Username: groupCGAdmin ? "*" : userData.sub };



        this.api.postService("/um/tenantperuser", postData)
          .then((response) => {
            var localTenantList = this.processTenants(response, groupCGAdmin);
            const currentTenant = localStorage.getItem('currentTenant');
            for (var a = 0; a < localTenantList!.length; a++) {
              // console.log(localTenantList[a]);
              if (localTenantList[a].id == currentTenant) {
                // console.log('Embed URL');
                // console.log(localTenantList[a].EmbedURL);
                this.embedURL = this.sanitizer.bypassSecurityTrustResourceUrl(localTenantList[a].EmbedURL);
                // this.embedURL = this.tenants[a].EmbedURL;
                this.iframeData = `<iframe class="templateIframe" src="` + this.embedURL + `" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
                // console.log(this.iframeData);
              }
            }

            userData.groups.forEach((element: string) => {
              if (element == "CGAdmin") {
                this.permCGAdmin = true;
                this.showUsage = true;
                this.showArchive = true;
                this.showJobs = true;
                this.showInteractive = true;
                this.showReports = true;
                this.showTemplating = true;
                this.showSettings = true;
                this.showHelp = true;
                this.showContact = true;
                this.showAdmin = true;
              }
              else if (element == "Admin") {
                this.permAdmin = true;
                this.showUsage = true;
                this.showArchive = true;
                this.showJobs = true;
                this.showInteractive = true;
                this.showReports = true;
                this.showTemplating = true;
                this.showSettings = true;
                this.showHelp = true;
                this.showContact = true;
              }
              else if (element == "DocumentArchive") {
                this.permArchive = true;
                this.showArchive = true;
                this.showSettings = true;
                this.showHelp = true;
                this.showContact = true;
              }
              else if (element == "InteractiveUser") {
                this.permInteractive = true;
                this.showInteractive = true;
                this.showSettings = true;
                this.showHelp = true;
              }
              else if (element == "TemplateUser") {
                this.permTemplate = true;
                this.showTemplating = true;
              }
              else if (element == "ProcessUser") {
                this.permProc = true;
                localTenantList.forEach((element) => {
                  if (element.id == this.currentTenant) {
                    this.showProcess = element.Alf_Proc_Enable;
                  }
                })

              }
              else if (element == "ViewUser") {
                this.permView = true;
                this.showUsage = true;
                this.showSettings = true;
                this.showHelp = true;
                this.showContact = true;
              }
            });

            this.notificationsService.getNotifications();
          })
          .catch((error) => {
            // console.log(error);
          });
      });
  }

  ngOnDestroy(): void {
    this.webSocketService.disconnect();
  }

  clearNotifications(event: Event) {
    event.stopPropagation();
    var notifIDs: any = [];
    this.notifications.forEach((element: any) => {
      notifIDs.push(element.id);
    });
    this.api.postService("/notifications/updateNotifications", { userID: this.userID, tenantID: this.currentTenant, action: "clearFromIDs", notificationsIDs: notifIDs }).then((results) => {
      this.notificationsService.getNotifications();
    });
  }




  processTenants(data: any, groupCGAdmin: boolean) {
    let returnData = [];
    try {
      var results = data.data;
      for (var a = 0; a < results.data.listTenantss.items.length; a++) {
        if ((results.data.listTenantss.items[a].Tenants.items.length > 0) || (groupCGAdmin)) {
          let thisTenant = {
            id: results.data.listTenantss.items[a].id,
            tenantItem: results.data.listTenantss.items[a].Name,
            SC_ExternalID: results.data.listTenantss.items[a].SC_ExternalID,
            EmbedURL: results.data.listTenantss.items[a].EmbedURL,
            ES_Enable: results.data.listTenantss.items[a].ES_Enable,
            Alf_Enable: results.data.listTenantss.items[a].Alf_Enable,
            Alf_Embed: results.data.listTenantss.items[a].Alf_Embed,
            Alf_Proc_Embed: results.data.listTenantss.items[a].Alf_Proc_Embed,
            Alf_Proc_Enable: results.data.listTenantss.items[a].Alf_Proc_Enable
          };
          returnData.push(thisTenant);
        }
      }
      localStorage.setItem('tenants', JSON.stringify(returnData));
      

      let options: string | any[] | null;
      // let currentTenant = "";
      try {
        options = returnData;
        if (options === null) {
          options = [];
          localStorage.setItem('tenants', '');
        }
        const initalTenant = options.length > 0 ? options[0].id : "";
        this.currentTenant = localStorage.getItem('currentTenant') ? localStorage.getItem('currentTenant') : initalTenant;
        var tenantFound = options.find((element)=> {return element.id == this.currentTenant});
        if(tenantFound == undefined){
          this.currentTenant = initalTenant;
        }
        localStorage.setItem('currentTenant', this.currentTenant);
        // this.tenantList = options;
        var tenantListLocal = [];
        for (var a = 0; a < options.length; a++) {
          var tenantItem = { value: options[a].id, viewValue: options[a].tenantItem };
          tenantListLocal.push(tenantItem);

        }
        this.tenantList = tenantListLocal;
      }
      catch (err) {
        // console.log(err);
      }
      if (localStorage.getItem('currentTenant') == null) {
        try {
          localStorage.setItem('currentTenant', returnData[0].id);
          this.currentTenantName = returnData[0].tenantItem;
        }
        catch (err) { }
      }
      else {
        var thisTenant = localStorage.getItem('currentTenant');
        returnData.forEach((element) => {

          if (element.id == thisTenant) {
            this.currentTenantName = element.tenantItem;
          }
        });
      }
    } catch (e) { }
    return returnData;
  }


  addNotif() {
    this.api.postService("/notifications/setNotification", {
      userID: this.userID,
      tenantID: this.currentTenant,
      Type: "pool",
      Pool: "Technical",
      Header: "New Workflow task",
      Body: "A new workflow task has been assigned to the the Technical Pool",
      Link:"",
      DateTime: new Date().toISOString()
    })
      .then((response) => {
        if (response.status == 200) {

        }
        else {
          this.toastTemplates.showErrorToast("API error - Create Notification", "A connection error has occured. Please contact support for assistance.");
        }

      });
  }

  tenantChanged(data: any) {
    localStorage.setItem('currentTenant', data.value);
    var currentURL = window.location.origin;
    window.location.assign(currentURL);
  }

  logout() {
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => {
        // console.log(result);
      });
  }
  login() {
    this.oidcSecurityService.authorize();
  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(DialogAnimationsExampleDialog, {
      width: '650px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
  menuDockClick(event: any) {
    localStorage.setItem('menuDock', event.checked);
    if (event.checked) {
      this.drawerMode = 'side';

    }
    else {
      this.drawerMode = 'over';
    }
  }
  toggleJobs() {
    this.jobsOpen = !this.jobsOpen;
  }
  toggleInteractive() {
    this.interactiveOpen = !this.interactiveOpen;
  }
  navAccountSettings() {
    this.route.navigateByUrl('/settings/account-settings');
  }
  navHome() {
    this.route.navigateByUrl('/portal');
  }
  navUsageStatistics() {
    this.route.navigateByUrl('/usage-statistics');
  }
  navDocumentArchive() {
    this.route.navigateByUrl('/document-archive');
  }
  navSubmitJobs() {
    this.route.navigateByUrl('/submit-jobs');
  }
  navJobStatus() {
    this.route.navigateByUrl('/job-status');
  }
  navInteractive() {
    this.route.navigateByUrl('/interactive/newDraft');
  }
  navMyDrafts() {
    this.route.navigateByUrl('interactive/mydrafts');
  }
  navCompletedTasks() {
    this.route.navigateByUrl('interactive/completedTasks');
  }
  navWorkflowApproval() {
    this.route.navigateByUrl('/interactive/workflowApproval');
  }
  navReports() {
    this.route.navigateByUrl('/reports');
  }
  navTemplating() {
    this.route.navigateByUrl('/templating');
  }
  navProcess() {
    this.route.navigateByUrl('/processServices');
  }
  navSettings() {
    this.route.navigateByUrl('/settings/templates');
  }
  navHelp() {
    this.route.navigateByUrl('/help');
  }
  navContactUs() {
    this.route.navigateByUrl('/contact-us');
  }
  navAdmin() {
    this.route.navigateByUrl('/admin/tenants');
  }
  navNotification() {
    this.route.navigateByUrl('/notifications');
  }
  navFromNotification(location: any){
    this.route.navigateByUrl(location);
  }
}

@Component({
  selector: 'popia-popup',
  templateUrl: 'popia/popia-popup.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
})
export class DialogAnimationsExampleDialog {
  constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>) { }
}
