import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebMethodsService } from '../web-methods.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { Buffer } from 'buffer';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';

import { MatTabsModule } from '@angular/material/tabs';
import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';

enum flow {
  loading,
  inPreview,
  saving
}

@Component({
  selector: 'app-workflow-approval',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatCardModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatButtonModule,
    ScrollingModule,
    MatCheckboxModule,
    MatMenuModule,
    FormsModule,
    MatSortModule,
    MatTabsModule,
    AdvancedDateSelectionComponent,
  ],
  templateUrl: './workflow-approval.component.html',
  styleUrl: './workflow-approval.component.scss'
})
export class WorkflowApprovalComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal, private route: ActivatedRoute, public dialog: MatDialog, private router: Router, private sanitizer: DomSanitizer) {
    route.queryParamMap.subscribe((element: any) => {
      this.taskID = element.params.taskid;
      if (this.taskID != undefined) {
        this.viewTaskFromInit();
      }
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['Name', 'LastUpdate', 'CreateDate', 'CreatorName', 'Action'];
  taskTable = [];
  dataSource = new MatTableDataSource(this.taskTable);
  taskID: any;
  filterWorkflows: any = new FormControl("0");
  workflows: any = [];
  pools: any = [];
  workflowsLoading = false;
  tasksLoading = false;

  hostname = "";
  tenants = [{ id: '', EmbedURL: '' }];
  embedURL: SafeResourceUrl;
  iframeData = ``;

  check_TaskID = true;
  check_Name = true;
  check_LastUpdated = true;
  check_CreatedDate = true;
  check_CreatedBy = true;
  check_Queue = false;
  check_ProjectID = false;
  check_TemplateSelector = false;

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };

  steps = [{ Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe" }, { Pool: "B", Comment: "Happy with document.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "Rudi van Aarde" }, { Pool: "B", Comment: "Happy with document.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "Chris Smith" }, { Pool: "C", Comment: "Please update working on in the Free text section. Also look at the grammer used in the paragraph. Something does not sound right. Please give the customer to savings option. You can also apply a 7% discount to the final amount. Please also update the marketing banner. The incorrect one is used. There should be one for the discount in the shared content under DemoMobile7Discount.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Rejected", User: "Peter Jones" }];

  @ViewChild(MatAccordion) accordion: MatAccordion;
  ngOnInit(): void {
    if (window.location.port == '') {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
    }
    else {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].EmbedURL);
        this.embedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].EmbedURL);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="` + this.embedURL + `" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
    this.getWorkflowItems();
    this.route.queryParamMap.subscribe((element: any) => {
      this.taskID = element.params.taskid;
    })
    if (this.taskID != undefined) {
      //this.viewTaskFromInit();
    }
  }

  dateUpdated(data: any) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getWorkflowItems();
  }

  viewTaskFromInit() {
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      taskID: this.taskID
    }
    this.api.postService("/workflow/getTask", request)
      .then((response) => {
        if (response.status == 200) {
          // console.log(response.data[0]);
          if (response.data.length > 0) {
            this.oidcSecurityService.getUserData().subscribe((userData) => {
              // console.log(userData);
              this.api.postService("/settings/listpools", request).then((poolsResp) => {
                if (response.status == 200) {
                  // console.log(poolsResp.data);
                  var poolFound = poolsResp.data.find((element: any) => { return element.id == response.data[0].PoolID });
                  // console.log("Pool Found:");
                  // console.log(poolFound);
                  var userInPoolFound = undefined;
                  try {
                    userInPoolFound = userData.Pool.find((element: any) => { return element == poolFound.Name });
                  }
                  catch (err) { }

                  // console.log("User in pool");
                  // console.log(userInPoolFound);
                  if (userInPoolFound != undefined) {
                    var tenantID = localStorage.getItem('currentTenant');
                    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
                      .then((responseWF) => {
                        // this.workflowsLoading = false;
                        // console.log(responseWF);
                        if (responseWF.status == 200) {
                          var localworkflows = responseWF.data;
                          var currentWorkflow = [];
                          try {
                            currentWorkflow = localworkflows.find((element: any) => { return element.id == response.data[0].WorkflowID }).wfPools;
                          }
                          catch (err) { }
                          var nextPool = 0;
                          var currentPoolName = '';
                          try {
                            nextPool = currentWorkflow.findIndex((element: any) => { return element.id == response.data[0].PoolID });
                            // console.log("nextPool");
                            // console.log(nextPool);
                            currentPoolName = currentWorkflow.findIndex((element: any) => { return element.id == response.data[0].Name });
                            response.data[0].nextPool = currentWorkflow[nextPool + 1] ? currentWorkflow[nextPool + 1].id : "Complete";
                            response.data[0].nextPoolName = currentWorkflow[nextPool + 1] ? currentWorkflow[nextPool + 1].Name : "";
                            response.data[0].PoolName = currentWorkflow[nextPool] ? currentWorkflow[nextPool].Name : "";
                          }
                          catch (err) { }


                          this.viewTaks(response.data[0], true);
                        }
                      })
                      .catch((err) => {
                        // console.log(err);
                        this.toastTemplates.showErrorToast("API error - Add Workflow", err);
                        // this.workflowsLoading = false;
                      });

                  }
                  else {
                    this.toastTemplates.showErrorToast("Task Error", "User not allowed to view this task.");
                    this.router.navigate(
                      ['.'],
                      { relativeTo: this.route, queryParams: {} }
                    );
                  }
                }
                else {
                  this.toastTemplates.showErrorToast("API error - Get Task", "Could not find task");
                }
              })
                .catch((err) => {
                  // console.log(err);
                  this.toastTemplates.showErrorToast("API error - Get Task", "Could not find task");
                });
            });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get Task", "Could not find task");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Task", "Could not find task");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Task", "Could not find task");
      });
  }



  getWorkflowItems() {
    this.taskTable = [];
    this.dataSource = new MatTableDataSource(this.taskTable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pools = [];
    this.filterWorkflows = null;
    try {
      this.accordion.closeAll();
    }
    catch (err) { }
    this.workflows = [];
    this.workflowsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
      .then((response) => {
        this.workflowsLoading = false;
        // console.log(response);
        if (response.status == 200) {
          this.workflows = response.data;
          this.filterWorkflows = new FormControl(response.data[0].id);
          this.getWorkflowPools();
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Workflows", err);
        this.workflowsLoading = false;
      });
  }

  getWorkflowPools() {
    this.taskTable = [];
    this.dataSource = new MatTableDataSource(this.taskTable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.pools = [];
    try {
      this.accordion.closeAll();
    }
    catch (err) { }
    // console.log(this.filterWorkflows);
    var selectedWorkflow = this.workflows.find((element: any) => { return element.id == this.filterWorkflows.value });
    // console.log(selectedWorkflow);
    var poolItems = selectedWorkflow.wfPools;
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      // console.log(userData);
      var userPools: any[] = [];
      try {
        userPools = userData.Pool;
      }
      catch (err) { }
      this.pools = [];
      poolItems.forEach((element: any) => {
        // console.log(element);
        var userPoolFound = undefined;
        try {
          userPoolFound = userPools.find((elementPool: any) => { return elementPool == element.Name; });
        }
        catch (err) { }
        var poolItem = element;
        if (userPoolFound != undefined) {
          poolItem.inPool = true;
        }
        else {
          poolItem.inPool = false;
        }
        this.pools.push(element);
      })
    });
  }

  getPoolTasks(poolTask: any) {
    // console.log(poolTask);
    this.taskTable = [];
    this.dataSource = new MatTableDataSource(this.taskTable);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tasksLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    var postData = {
      tenantID: tenantID,
      poolID: poolTask.id,
      workflowID: this.filterWorkflows.value,
      fromDate: this.range.controls.start.value,
      toDate: this.range.controls.end.value,
    };
    var index = this.pools.findIndex((element: any) => { return element.id == poolTask.id });
    // console.log("Index");
    // console.log(index);
    var NextPool = "Complete";
    var NextPoolName = "";
    try {
      NextPool = this.pools[index + 1].id;
      NextPoolName = this.pools[index + 1].Name;
    }
    catch (err) { }

    // console.log(index);
    this.api.postService("/workflow/listTasks", postData)
      .then((response) => {
        this.tasksLoading = false;
        // console.log(response);
        if (response.status == 200) {
          var newTaskTable: any = [];
          response.data.forEach((element: any) => {
            element.LastUpdateDisplay = new Date(element.LastUpdate).toDateString() + " " + new Date(element.LastUpdate).toLocaleTimeString();
            element.CreateDateDisplay = new Date(element.CreateDate).toDateString() + " " + new Date(element.CreateDate).toLocaleTimeString();
            element.PoolName = poolTask.Name;
            element.nextPool = NextPool;
            element.nextPoolName = NextPoolName;

            newTaskTable.push(element);
          });
          this.taskTable = newTaskTable;
          this.dataSource = new MatTableDataSource(this.taskTable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tasks", "Can not load tasks");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Tasks", err);
        this.tasksLoading = false;
      });
  }

  viewTaks(data: any, load: any) {
    // console.log(data);
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { taskid: data.id } }
    );
    var timeout = 0;
    if (load) {
      timeout = 10;
    }
    data.timeout = timeout;

    var dialog = this.dialog.open(WorkflowItemDialog, { width: '99%', height: '99%', maxWidth: '100vw', data, disableClose: true });
    dialog.afterClosed().subscribe((data: any) => {
      // console.log("Test");

      // console.log(data);

      this.router.navigate(
        ['.'],
        { relativeTo: this.route, queryParams: {} }
      );
      this.getWorkflowPools();

    });

  }

  viewTaskFromButton(data: any, load: any) {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route, queryParams: { taskid: data.id } }
    );
  }

  columnVisibleSelect() {
    var newColumns = [];
    if (this.check_TaskID) {
      newColumns.push('id');
    }
    if (this.check_Name) {
      newColumns.push('Name');
    }
    if (this.check_LastUpdated) {
      newColumns.push('LastUpdate');
    }
    if (this.check_CreatedDate) {
      newColumns.push('CreateDate');
    }
    if (this.check_CreatedBy) {
      newColumns.push('CreatorName');
    }
    if (this.check_Queue) {
      newColumns.push('Queue');
    }
    if (this.check_ProjectID) {
      newColumns.push('ProjectID');
    }
    if (this.check_TemplateSelector) {
      newColumns.push('TemplateSelector');
    }
    newColumns.push('Action');
    this.displayedColumns = newColumns;
  }

  columnVisibleSet() {
    this.check_TaskID = (this.displayedColumns.indexOf('id') != -1);
    this.check_Name = (this.displayedColumns.indexOf('Name') != -1);
    this.check_LastUpdated = (this.displayedColumns.indexOf('LastUpdate') != -1);
    this.check_CreatedDate = (this.displayedColumns.indexOf('CreateDate') != -1);
    this.check_CreatedBy = (this.displayedColumns.indexOf('CreatorName') != -1);
    this.check_Queue = (this.displayedColumns.indexOf('Queue') != -1);
    this.check_ProjectID = (this.displayedColumns.indexOf('ProjectID') != -1);
    this.check_TemplateSelector = (this.displayedColumns.indexOf('TemplateSelector') != -1);
  }
}

@Component({
  selector: 'workflow-item-dialog',
  templateUrl: 'workflow-item-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    ScrollingModule,
  ],
  styleUrl: './workflow-approval.component.scss'
})
export class WorkflowItemDialog implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public dialogRef: MatDialogRef<WorkflowItemDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal, public dialog: MatDialog) { }
  showSideNav = false;
  state: flow = flow.loading;
  enum: typeof flow = flow;
  draftData = "";
  editor: any;
  hostname = "";
  // steps = [{Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "A", Comment: "All in order", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "John Doe"}, {Pool: "B", Comment: "Happy with document.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "Rudi van Aarde"}, {Pool: "B", Comment: "Happy with document.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Approved", User: "Chris Smith"}, {Pool: "C", Comment: "Please update working on in the Free text section. Also look at the grammer used in the paragraph. Something does not sound right. Please give the customer to savings option. You can also apply a 7% discount to the final amount. Please also update the marketing banner. The incorrect one is used. There should be one for the discount in the shared content under DemoMobile7Discount.", DateTime: "2024-04-30T14:51:01.185Z", Action: "Rejected", User: "Peter Jones"}];
  steps: any = [];
  ngOnInit(): void {
    // console.log(this.data);
    try {
      var localSteps = JSON.parse(Buffer.from(this.data.Steps, "base64").toString("utf-8"));
      this.steps = [];
      localSteps.forEach((element: any) => {
        element.DateTime = new Date(element.DateTime).toDateString() + " " + new Date(element.DateTime).toLocaleTimeString();
        this.steps.push(element);
      });

    }
    catch (err) { }
    // console.log(this.steps);

    if (window.location.port == '') {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
    }
    else {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }

    this.loadDraft();
  }

  loadDraft() {
    setTimeout(() => {
      this.draftData = Buffer.from(this.data.DraftXML, "base64").toString("utf-8");
      this.editor = window;
      this.editor = this.editor.SmartComms;
      this.editor = new this.editor.DraftEditor({
        server: 'https://eu10-sb.smartcommunications.cloud',
        clientServer: this.hostname,
        targetElement: 'draftEditorWindow',
        navigateAwayWarning: 'Are you sure you want to leave Draft-Editor?',
        onReady: () => {
          // console.log("onReady");
          this.state = flow.inPreview;
          this.editor.v2.loadString(this.draftData, { openInPreview: false, editMode: "readOnly" }, function () {
          });
        },
        loadStartupConfig: () => ({
          version: '2',
          editingMode: 'readOnly',
          features: {
            disabled: ['PasteMode']
          },
          sidePanel: { collapse: false, alignment: 'right' }
        }),
      });
    }, this.data.timeout)
  }

  approveTask() {
    var dialog = this.dialog.open(ApproveRejectDialog, { width: '500px', height: '300px', data: { action: 'Approve', comment: '' }, disableClose: true });
    dialog.afterClosed().subscribe((dialogData: any) => {
      // console.log(dialogData);
      if (dialogData) {
        // console.log("Approving task");
        this.state = flow.saving;
        this.updateTask(dialogData, this.data);

      }
      else {
        // console.log("Cancelled");
      }
    })
  }

  rejectTask() {
    var dialog = this.dialog.open(ApproveRejectDialog, { width: '500px', height: '300px', data: { action: 'Reject', comment: '' }, disableClose: true });
    dialog.afterClosed().subscribe((dialogData: any) => {
      // console.log(dialogData);
      // console.log(this.data);
      this.data.nextPool = '';
      if (dialogData) {
        // console.log("Rejecting task");
        this.state = flow.saving;
        this.updateTask(dialogData, this.data);
      }
      else {
        // console.log("Cancelled");
      }
    })
  }

  updateTask(dialogData: any, taskData: any) {
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.steps.push({ Pool: taskData.PoolName, Comment: dialogData.comment, DateTime: new Date(), Action: dialogData.action == 'Approve' ? "Approved" : "Rejected", User: userData.name });
      // console.log("DialogData");
      // console.log(dialogData);
      // console.log("taskData");
      // console.log(taskData);
      var request = {
        taskID: taskData.id,
        tenantID: localStorage.getItem('currentTenant'),
        steps: Buffer.from(JSON.stringify(this.steps)).toString('base64'),
        poolID: dialogData.action == 'Approve' ? taskData.nextPool : "",
        poolName: dialogData.action == 'Approve' ? taskData.nextPoolName: "",
        taskName: taskData.Name,
        updater: userData.sub
      }

      this.api.postService("/workflow/updateTask", request)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
          }
          this.dialogRef.close(this.data);
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Update Workflow Task", err);
          this.dialogRef.close(this.data);
        });
    });
  }

  toggleSideNav() {
    this.showSideNav = !this.showSideNav;
  }
}

@Component({
  selector: 'workflow-approve-reject-dialog',
  templateUrl: 'workflow-approve-reject-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    ScrollingModule,
    MatFormFieldModule,
    MatInputModule,

  ],
  styleUrl: './workflow-approval.component.scss'
})
export class ApproveRejectDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<ApproveRejectDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
  }

  approve() {
    this.dialogRef.close(this.data);
  }

  reject() {
    this.dialogRef.close(this.data);
  }
}
