import { Injectable } from '@angular/core';
import axios from 'axios';
import {
  OidcSecurityService
} from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebMethodsService {

  constructor(public oidcSecurityService: OidcSecurityService) { }
  token: string = "";
  postService(url: string, data: any) {
    this.oidcSecurityService.getAccessToken().subscribe((accesstoken: string) => {
      this.token = accesstoken;
    });

    return axios.post(environment.api_gw + url, data, { headers: { Authorization: this.token } });
  }
  putService(presigendUrl: string, file: any) {
    const formData = new FormData();
    formData.append("file", file);
    return axios({
        url: presigendUrl,
        method: "put",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: file,
        onUploadProgress: (ProgressEvent) => {
            localStorage.setItem('fileUploadProgress', Math.round(ProgressEvent.progress! * 100).toString())
            window.dispatchEvent(new Event("storage"));
        }
    }).then((res) => { return res; })
        .catch((err) => { return err; });
  }
}
