import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { generate } from 'generate-password-browser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    FormsModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['username', 'firstName', 'email', 'RequiredActions', 'enabled', 'ViewUser'];
  usersLoading = false;
  usersTable = [];
  dataSource = new MatTableDataSource(this.usersTable);
  filterValue = '';

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers() {
    this.usersLoading = true;
    var currentTenant = localStorage.getItem('currentTenant');
    this.api.postService("/um/getusers", { tenantID: currentTenant })
      .then((response) => {
        this.usersLoading = false;
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            this.usersTable = JSON.parse(response.data.body);
            this.dataSource = new MatTableDataSource(this.usersTable);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            // console.log(this.usersTable);
          }
          else {
            this.toastTemplates.showErrorToast("API error - List Users", "Could not get users.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - List Users", "Could not get users.");
        }
      })
      .catch((err) => {
        this.usersLoading = false;
        this.toastTemplates.showErrorToast("API error - List Users", err);
        // console.log(err);
      });
  }

  viewUser(userInfo: any) {
    var dialog = this.dialog.open(UserInfoDialog, { width: 'fit-content', data: { userInfo, addUser: false }, disableClose: false });
    dialog.afterClosed().subscribe((data) => {
      // console.log(data);
      this.getUsers();
    });
  }

  addUser() {
    var dialog = this.dialog.open(UserInfoDialog, { width: 'fit-content', data: { addUser: true } });
    dialog.afterClosed().subscribe((data) => {
      // console.log(data);
      this.getUsers();
    });
  }
  getValue(item: string) {
    var data = item;
    data = data.replaceAll("_", " ");
    return data;
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}

@Component({
  selector: 'user-details-dialog',
  templateUrl: 'user-details-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    MatChipsModule,
    ScrollingModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
    MatExpansionModule,
  ],
  styleUrl: './users.component.scss'
})
export class UserInfoDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<UserInfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  at = '@';
  permissions: any[] = [];
  groups: any[] = [];
  pools: any[] = [];
  roles: any[] = [];
  tenants: any[] = [];
  permissionsLoading = false;
  groupsLoading = false;
  poolsLoading = false;
  rolesLoading = false;
  tenantsLoading = false;
  usrLoading = false;
  currentTenant: any;
  currentTenantName: any;


  tenantSelected: string[] = [];
  PermissionSelected: string[] = [];
  groupSelected: string[] = [];
  poolSelected: string[] = [];
  RoleSelected: string[] = [];

  firstName = "";
  lastName = "";
  enabled = "";
  username = "";
  email = "";
  password = "";
  emailNotification = "false";

  errorMessage = "";

  ngOnInit(): void {
    this.currentTenant = localStorage.getItem('currentTenant');
    var tenants = JSON.parse(localStorage.getItem('tenants')!);
    this.currentTenantName = tenants.find((element: any) => { return element.id == this.currentTenant }).tenantItem.toLowerCase().replaceAll(" ", "");
    if (this.data.addUser == false) {
      this.firstName = this.data.userInfo.firstName ? this.data.userInfo.firstName : "";
      this.lastName = this.data.userInfo.lastName ? this.data.userInfo.lastName : "";
      this.enabled = this.data.userInfo.enabled ? this.data.userInfo.enabled : "";
      this.username = this.data.userInfo.username ? this.data.userInfo.username : "";
      try {
        this.emailNotification = this.data.userInfo.attributes.EmailNotification[0] ? this.data.userInfo.attributes.EmailNotification[0] : "false"
      }
      catch (err) { }
      this.getPermissions();
      this.getRoles();
      this.getTenants();
      this.getGroups();
      this.getPools();
    }
    else {
      this.getTenants();
      this.getPermissions();
      this.getRoles();
      this.getGroups();
      this.getPools();
      this.password = generate({
        length: 8,
        uppercase: true,
        lowercase: true,
        numbers: true,
        symbols: true,
        strict: true
      })
    }
  }

  getPermissions() {
    this.permissionsLoading = true;
    this.permissions = [];
    var request = {
      Username: ""
    }
    if (this.data.addUser == false) {
      request.Username = this.data.userInfo.id
    }

    this.api.postService("/um/getgroupsforuser", request)
      .then((response) => {
        this.permissionsLoading = false;
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            // this.permissions = JSON.parse(response.data.body);
            this.permissions = [];
            // console.log()
            JSON.parse(response.data.body).forEach((element: any) => {
              // console.log(element);
              if (element.GroupName != 'CGAdmin') {
                this.permissions.push(element);
                if (element.InGroup) {
                  if (this.data.addUser == false) {
                    this.PermissionSelected.push(element.GroupID);
                  }
                  else {
                    this.PermissionSelected.push(element.GroupName);
                  }
                }
              }

            });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get Groups for User", "Could not get groups for user.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Groups for User", "Could not get groups for user.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Groups for User", err);
        this.permissionsLoading = false;
      });
  }

  getRoles() {
    this.rolesLoading = true;
    this.roles = [];
    var request = {
      Username: ""
    }
    if (this.data.addUser == false) {
      request.Username = this.data.userInfo.id
    }
    // console.log(this.data.userInfo);
    this.api.postService("/settings/listroles", { Username: "" })
      .then((response) => {
        this.rolesLoading = false;
        if (response.status == 200) {
          var data = response.data;
          data.sort((a: any, b: any) => {
            return a.RoleName - b.RoleName;
          });
          data.forEach((element: any) => {
            if (this.data.addUser == false) {
              if ("attributes" in this.data.userInfo) {
                if ("SCRole" in this.data.userInfo.attributes) {
                  if (this.data.userInfo.attributes.SCRole.find((elementFind: string) => elementFind == element.Name)) {
                    this.roles.push({
                      RoleDescription: element.Description,
                      RoleName: element.Name,
                      InRole: true
                    });
                    this.RoleSelected.push(element.Name);
                  }
                  else {
                    this.roles.push({
                      RoleDescription: element.Description,
                      RoleName: element.Name,
                      InRole: false
                    });
                  }
                }
                else {
                  this.roles.push({
                    RoleDescription: element.Description,
                    RoleName: element.Name,
                    InRole: false
                  });
                }
              }
              else {
                this.roles.push({
                  RoleDescription: element.Description,
                  RoleName: element.Name,
                  InRole: false
                });
              }
            }
            else {
              this.roles.push({
                RoleDescription: element.Description,
                RoleName: element.Name,
                InRole: false
              });
            }


          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Roles for User", "Could not get roles for user.");
        }


      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Roles for User", err);
        this.rolesLoading = false;
      });
  }

  getGroups() {
    this.groupsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listgroups", { tenantID: tenantID })
      .then((response) => {
        this.groupsLoading = false;
        if (response.status == 200) {
          var groupsList = response.data;
          groupsList.forEach((element: any) => {
            if (this.data.addUser == false) {
              if ("attributes" in this.data.userInfo) {
                if ("GR" in this.data.userInfo.attributes) {
                  if (this.data.userInfo.attributes.GR.find((elementFind: string) => elementFind == element.Name) != undefined) {
                    this.groups.push({
                      name: element.Name,
                      inGroup: true
                    });
                    this.groupSelected.push(element.Name);
                  }
                  else {
                    this.groups.push({
                      name: element.Name,
                      inGroup: false
                    });
                  }
                }
                else {
                  this.groups.push({
                    name: element.Name,
                    inGroup: false
                  });
                }
              }
              else {
                this.groups.push({
                  name: element.Name,
                  inGroup: false
                });
              }


            }
            else {
              this.groups.push({
                name: element.Name,
                inGroup: false
              });
            }
          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Groups", "Could not get groups.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Groups", err);
        this.groupsLoading = false;
      });
  }

  getPools() {
    this.poolsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listpools", { tenantID: tenantID })
      .then((response) => {
        this.poolsLoading = false;
        if (response.status == 200) {
          var poolsList = response.data;
          poolsList.forEach((element: any) => {
            if (this.data.addUser == false) {
              if ("attributes" in this.data.userInfo) {
                if ("Pool" in this.data.userInfo.attributes) {
                  if (this.data.userInfo.attributes.Pool.find((elementFind: string) => elementFind == element.Name) != undefined) {
                    this.pools.push({
                      name: element.Name,
                      inPool: true
                    });
                    this.poolSelected.push(element.Name);
                  }
                  else {
                    this.pools.push({
                      name: element.Name,
                      inPool: false
                    });
                  }
                }
                else {
                  this.pools.push({
                    name: element.Name,
                    inPool: false
                  });
                }
              }
              else {
                this.pools.push({
                  name: element.Name,
                  inPool: false
                });
              }


            }
            else {
              this.pools.push({
                name: element.Name,
                inPool: false
              });
            }
          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Pools", "Could not get pools.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Pools", err);
        this.groupsLoading = false;
      });
  }

  getTenants() {
    this.tenantsLoading = true;
    var request = {
      Username: ""
    }
    if (this.data.addUser == false) {
      request.Username = this.data.userInfo.id
    }
    this.api.postService("/um/tenantperuser", request)
      .then((response) => {
        this.tenantsLoading = false;
        if (response.status == 200) {
          this.tenants = response.data.data.listTenantss.items;
          response.data.data.listTenantss.items.forEach((element: any) => {
            if (element.Tenants.items.length > 0) {
              this.tenantSelected.push(element.id);
            }
          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tenants", "Could not get tenants.");
        }
      })
      .catch((error) => {
        // console.log(error);
        this.toastTemplates.showErrorToast("API error - Get Tenants", error);
        this.tenantsLoading = false;
      });
  }

  updateUserDetails() {

    // console.log(this.firstName);
    // console.log(this.lastName);
    // console.log(this.enabled);
    // console.log(this.tenantSelected);
    // console.log(this.PermissionSelected);
    // console.log(this.groupSelected);
    // console.log(this.RoleSelected);
    // console.log(this.data.userInfo.id);
    if (this.firstName == "") {
      this.errorMessage = "First Name can not be empty";
    }
    else if (this.lastName == "") {
      this.errorMessage = "Last Name can not be empty";
    }
    else {
      this.usrLoading = true;
      var request = {
        email: this.data.userInfo.email,
        firstName: this.firstName,
        lastName: this.lastName,
        groups: this.groupSelected,
        roles: this.RoleSelected,
        pools: this.poolSelected,
        enable: this.enabled,
        userID: this.data.userInfo.id,
        emailNotification: this.emailNotification
      }
      this.api.postService("/um/updateuser", request)
        .then((response) => {
          this.usrLoading = false;
          var postItems: any = [];
          this.permissions.forEach((element: any) => {
            if (element.InGroup) {
              if ((this.PermissionSelected.find((item) => { return item == element.GroupID; })) == undefined) {
                var request = {
                  Username: this.data.userInfo.id,
                  GroupName: element.GroupID,
                  Enable: false
                }
                var deletGroups = this.api.postService("/um/setgroupforuser", request)
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    console.log(err)
                  });
                postItems.push(deletGroups);
              }

            }
          });
          Promise.all(postItems).then(() => {
            this.PermissionSelected.forEach(element => {
              var request = {
                Username: this.data.userInfo.id,
                GroupName: element,
                Enable: true
              }
              this.api.postService("/um/setgroupforuser", request)
                .then((response) => {
                  // console.log(response);
                })
                .catch((err) => {
                  console.log(err)
                  this.toastTemplates.showErrorToast("API error - Set groups for user", err);
                });
            });
          });
          this.tenants.forEach((element: any) => {
            if (element.Tenants.items.length > 0) {
              if ((this.tenantSelected.find((item) => { return item == element.id })) == undefined) {
                this.api.postService("/um/deleteuserfromtenant", { UserTableID: element.Tenants.items[0].id })
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                    this.toastTemplates.showErrorToast("API error - Delete user from tenant", err);
                  });
              }
            }
            else {
              if ((this.tenantSelected.find((item) => { return item == element.id })) != undefined) {
                var request = {
                  TenantID: element.id,
                  UserID: this.data.userInfo.id
                }
                this.api.postService("/um/addusertotenant", request)
                  .then((response) => {
                    // console.log(response);
                  })
                  .catch((err) => {
                    console.log(err);
                    this.toastTemplates.showErrorToast("API error - Add user to tenant", err);
                  });
              }
            }
          });
          this.dialogRef.close();
        })
        .catch((err) => {
          console.log(err);
          this.toastTemplates.showErrorToast("API error - Update user", err);
          this.usrLoading = false;
        });
    }
  }

  createUser() {

    // console.log(this.firstName);
    // console.log(this.lastName);
    // console.log(this.email);
    // console.log(this.password);
    // console.log(this.tenantSelected);
    // console.log(this.PermissionSelected);
    // console.log(this.groupSelected);
    // console.log(this.RoleSelected);

    if (this.email == "") {
      this.errorMessage = "Please enter a valid email address";
    }
    else if (this.firstName == "") {
      this.errorMessage = "Please enter a First Name";
    }
    else if (this.lastName == "") {
      this.errorMessage = "Please enter a Last Name";
    }
    else {
      this.usrLoading = true;
      var request = {
        email: this.email,
        username: this.username + "@" + this.currentTenantName,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        permissions: this.PermissionSelected,
        groups: this.groupSelected,
        roles: this.RoleSelected,
        pools: this.poolSelected
      }

      this.api.postService("/um/createnewuser", request)
        .then((response) => {
          this.usrLoading = false;
          // console.log(response);
          if (response.status == 200) {
            if (response.data.statusCode == 200) {
              // console.log(JSON.parse(JSON.parse(response.data.body)));
              // console.log(JSON.parse(JSON.parse(response.data.body))[0].id);
              var request = {
                TenantID: this.currentTenant,
                UserID: JSON.parse(JSON.parse(response.data.body))[0].id
              }
              this.api.postService("/um/addusertotenant", request)
                .then((response) => {
                  // console.log(response);
                  this.dialogRef.close();
                })
                .catch((err) => {
                  this.toastTemplates.showErrorToast("API error - Adding user", err);
                  this.usrLoading = false;
                  this.dialogRef.close();
                  // console.log(err);
                })

            }
            else {
              this.errorMessage = JSON.parse(response.data.body).errorMessage;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.toastTemplates.showErrorToast("API error - Create User", err);
          this.usrLoading = false;
        });
    }
  }
}
