<div style="user-select: none;">
    <h1 mat-dialog-title><b>Tenant Information</b></h1>

    <!-- Edit Tenant -->
    <div *ngIf="!data.addTenant" mat-dialog-content>
        <div class="flexRow">
            <div style="padding-left: 10px;">
                <p class="itemHeading">Tenant Name</p>
                <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Name">
            </div>
            <div style="padding-left: 10px;">
                <p class="itemHeading">Tenant ID</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: #E3E7EB;"
                    [(ngModel)]="data.tenantInfo.id" disabled>
            </div>
        </div>
        <div class="flexRow">


            <div class="framedBox">
                <p class="framedBoxHeading">SmartComm</p>
                <div>
                    <p class="itemSubHeading itemHeadingPad">Tenant External ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="SC_ExternalID">

                    <p class="itemSubHeading">Endpoint</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Endpoint">

                    <p class="itemSubHeading">User ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="UserID">

                    <p class="itemSubHeading">Password</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Password"
                        placeholder="***************">

                    <p class="itemSubHeading">Client ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ClientID">

                    <p class="itemSubHeading">Client Secret</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ClientSecret">

                    <p class="itemSubHeading">Embed URL</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="EmbedURL">
                </div>
            </div>

            <div>

                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Simple Archive</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="ES_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">ElasticSearch Domain</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ES_Domain">

                        <p class="itemSubHeading">ElasticSearch Index</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ES_Index">

                        <p class="itemSubHeading">S3 Role ARN</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="S3_Role">
                    </div>
                </div>
                <br />

                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Advanced Archive</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="Alf_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">Alfresco Content Embed URL</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Alf_Embed">
                    </div>
                </div>
                <br />
                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Process Services</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="Alf_Proc_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">Alfresco Process Embed URL</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Alf_Proc_Embed">
                    </div>
                </div>

            </div>

        </div>
    </div>

    <!-- Add Tenant -->
    <div *ngIf="data.addTenant" mat-dialog-content>
        <div class="flexRow">
            <div style="padding-left: 10px;">
                <p class="itemHeading">Tenant Name</p>
                <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Name">
            </div>
        </div>
        <div class="flexRow">

            <div class="framedBox">
                <p class="framedBoxHeading">SmartComm</p>
                <div>
                    <p class="itemSubHeading itemHeadingPad">Tenant External ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="SC_ExternalID">

                    <p class="itemSubHeading">Endpoint</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Endpoint">

                    <p class="itemSubHeading">User ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="UserID">

                    <p class="itemSubHeading">Password</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Password">

                    <p class="itemSubHeading">Client ID</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ClientID">

                    <p class="itemSubHeading">Client Secret</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ClientSecret">

                    <p class="itemSubHeading">Embed URL</p>
                    <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="EmbedURL">
                </div>
            </div>
            <div>

                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Simple Archive</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="ES_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">ElasticSearch Domain</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ES_Domain">

                        <p class="itemSubHeading">ElasticSearch Index</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="ES_Index">

                        <p class="itemSubHeading">S3 Role ARN</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="S3_Role">
                    </div>
                </div>
                <br />

                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Advanced Archive</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="Alf_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">Alfresco Content Embed URL</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Alf_Embed">
                    </div>
                </div>
                <br />
                <div class="framedBox" style="width: 100%; background: none;">
                    <div class="framedBoxHeadingTabled">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p style="background-color: white; text-wrap: nowrap;">Process Services</p>
                                </td>
                                <td width="100%">
                                </td>
                                <td style="text-align: right;">
                                    <mat-checkbox style="background-color: white;"
                                        [(ngModel)]="Alf_Proc_Enable">Active</mat-checkbox>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p class="itemSubHeading itemHeadingPad">Alfresco Process Embed URL</p>
                        <input class="mainTextbox valueTextbox localTextBox" [(ngModel)]="Alf_Proc_Embed">
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div mat-dialog-actions class="flexRow" style="justify-content: space-between;">
        <div>
            <p class="errorText">{{errorMessage}}</p>
        </div>
        <div style="justify-content: right;">
            <button *ngIf="data.addTenant" mat-flat-button color="secondary" mat-dialog-close
                cdkFocusInitial>Cancel</button>
            <button *ngIf="!data.addTenant" mat-flat-button color="secondary" mat-dialog-close
                cdkFocusInitial>Close</button>
            <button *ngIf="data.addTenant" mat-flat-button color="primary" (click)="addTenant()">Add</button>
            <button *ngIf="!data.addTenant" mat-flat-button color="primary" (click)="updateTenant()">Update</button>
        </div>


    </div>
    <mat-card *ngIf="tenantLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
</div>