<div style="user-select: none;">

    <div style="width: 100%;">
        <h1 *ngIf="data.action=='Approve'" mat-dialog-title><b>Approve Task</b></h1>
        <h1 *ngIf="data.action=='Reject'" mat-dialog-title><b>Reject Task</b></h1>
        <div style="padding-left: 24px; padding-right: 24px;">
            <form style="width: 100%; height: 100%;">
                <mat-form-field style="width: 100%; height: 200px; min-height: 100px; max-height: 2000px;">
                    <mat-label>Leave a comment</mat-label>
                    <textarea name="Comment" matInput *ngIf="data.action=='Approve'" [(ngModel)]="data.comment" matInput placeholder="Ex. Approved" style="max-height: 150px; height: 150px;"></textarea>
                    <textarea name="Comment" matInput *ngIf="data.action=='Reject'" [(ngModel)]="data.comment" matInput placeholder="Ex. Rejected" style="max-height: 150px; height: 150px;"></textarea>
                </mat-form-field>
            </form>
        </div>
    </div>

    <div mat-dialog-actions style="justify-content: center; bottom: 10px; position: absolute; width: 100%;">
        <button mat-flat-button color="primary" mat-dialog-close>Close</button>
        <button *ngIf="data.action=='Approve'" mat-flat-button color="accent" (click)="approve()">Approve</button>
        <button *ngIf="data.action=='Reject'" mat-flat-button color="warn" (click)="reject()">Reject</button>
    </div>
</div>