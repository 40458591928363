<div class="mainHeader">
    <div class="mainHeaderFlex">
        <img alt='' src='./assets/img/CG_SaaS_Logo_White.svg' draggable="false"/>
        <!-- <p className="mainHeaderText">CCM {process.env.REACT_APP_ENV_TYPE === 'dev' ? ' - DEV' : ''}</p> -->
        <p class="mainHeaderText">CCM{{environmentName}}</p>
    </div>
</div>
<div class="mainHeaderSpace"></div>
<div class="TopBarLeftSection" (click)="drawer.toggle()">
    <div class="navIcon">
        <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
            </path>
        </svg>
    </div>
</div>
<!-- <div class="testButton">
    <button (click)="addNotif()">Test</button>
</div> -->

<div class="helpIcon" [matMenuTriggerFor]="helpmenu">
    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
        stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
        <path d="M12 17h.01"></path>
    </svg>
</div>
<div [matMenuTriggerFor]="notifmenu" class="notificationIcon" *ngIf="notifications.length == 0">
    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M255.9 456c31.1 0 48.1-22 48.1-53h-96.3c0 31 17 53 48.2 53zM412 352.2c-15.4-20.3-45.7-32.2-45.7-123.1 0-93.3-41.2-130.8-79.6-139.8-3.6-.9-6.2-2.1-6.2-5.9v-2.9c0-13.4-11-24.7-24.4-24.6-13.4-.2-24.4 11.2-24.4 24.6v2.9c0 3.7-2.6 5-6.2 5.9-38.5 9.1-79.6 46.5-79.6 139.8 0 90.9-30.3 102.7-45.7 123.1-9.9 13.1-.5 31.8 15.9 31.8h280.1c16.3 0 25.7-18.8 15.8-31.8z">
        </path>
    </svg>
</div>
<div [matMenuTriggerFor]="notifmenu" class="notificationIcon" *ngIf="notifications.length > 0">
    <svg width="1em" height="1em" fill="currentColor" stroke="currentColor" stroke-width="0" version="1.1"
        viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M255.9 456c31.1 0 48.1-22 48.1-53h-96.3c0 31 17 53 48.2 53zM412 352.2c-15.4-20.3-45.7-32.2-45.7-123.1 0-93.3-41.2-130.8-79.6-139.8-3.6-.9-6.2-2.1-6.2-5.9v-2.9c0-13.4-11-24.7-24.4-24.6-13.4-.2-24.4 11.2-24.4 24.6v2.9c0 3.7-2.6 5-6.2 5.9-38.5 9.1-79.6 46.5-79.6 139.8 0 90.9-30.3 102.7-45.7 123.1-9.9 13.1-.5 31.8 15.9 31.8h280.1c16.3 0 25.7-18.8 15.8-31.8z" />
        <ellipse cx="359.69" cy="175.38" rx="101.71" ry="102.02" fill="#f00" stroke="#fff" stroke-linejoin="round"
            stroke-width="19.239" />
    </svg>
</div>
<div [matMenuTriggerFor]="usermenu" class="userIcon">
    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" height="1em" width="1em"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd">
        </path>
    </svg>
</div>

<mat-menu #helpmenu="matMenu" class="userNav">
    <div class="userMenuTopLine" (click)="$event.stopPropagation()">
        <div class="userMenuTopLineItem" style="padding-bottom: 15px;">Help</div>
        <div class="userMenuTopLineItemSignout">

        </div>
    </div>
    <div class="notifOuter">
        <div class="notifMain">
            <div>
                <div class="notifListItemNav" (click)="navHelp()">
                    <br />
                    <p class="nofifItemBody">
                        <svg _ngcontent-ng-c1205749652="" stroke="currentColor" fill="none" stroke-width="2"
                            viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle _ngcontent-ng-c1205749652="" cx="12" cy="12" r="10"></circle>
                            <path _ngcontent-ng-c1205749652="" d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                            <line _ngcontent-ng-c1205749652="" x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                        &nbsp;Help
                    </p>
                </div>
                <div class="notifListItemNav" (click)="navContactUs()">
                    <br />
                    <p class="nofifItemBody">
                        <svg _ngcontent-ng-c1939293663="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <g _ngcontent-ng-c1939293663="">
                                <path _ngcontent-ng-c1939293663="" fill="none" d="M0 0h24v24H0z"></path>
                                <path _ngcontent-ng-c1939293663=""
                                    d="M19 7h5v2h-5V7zm-2 5h7v2h-7v-2zm3 5h4v2h-4v-2zM2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z">
                                </path>
                            </g>
                        </svg>
                        &nbsp;Contact Us
                    </p>
                </div>
            </div>
        </div>
    </div>
</mat-menu>

<mat-menu #notifmenu="matMenu" class="userNav">
    <div class="userMenuTopLine" (click)="$event.stopPropagation()">
        <div class="userMenuTopLineItem">Notifications<br></div>
        <div class="userMenuTopLineItemSignout">
            <button *ngIf="isAuthenticated" mat-flat-button
                (click)="clearNotifications($event); $event.stopPropagation();" matTooltip="Clear notifications"
                [matTooltipPosition]="'right'">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="2em"
                    width="2em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="44"
                        d="M465 127 241 384l-92-92m-9 93-93-93m316-165L236 273"></path>
                </svg>
            </button>
        </div>
    </div>
    <div class="notifOuter" (click)="$event.stopPropagation()">
        <div class="notifMain">
            <div *ngIf="notifications.length == 0" style="padding-left: 10px; padding-right: 10px;">
                <p>No new notifications</p>
            </div>
            <div *ngIf="notifications.length > 0">
                <div *ngFor="let notification of notifications">
                    <div class="notifListItem" *ngIf="notification.Link == ''">
                        <p class="nofifItemHeader">{{notification.header}}</p>
                        <p class="nofifItemBody">{{notification.body}}</p>
                        <p class="nofifItemFooter">{{notification.date}}</p>
                    </div>
                    <div class="notifListItemNav" *ngIf="notification.Link != ''" (click)="navFromNotification(notification.Link)">
                        <p class="nofifItemHeader">{{notification.header}}</p>
                        <p class="nofifItemBody">{{notification.body}}</p>
                        <p class="nofifItemFooter">{{notification.date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <p class="notifViewAll" (click)="navNotification()">View all</p>
    </div>
</mat-menu>

<mat-menu #usermenu="matMenu" class="userNav">
    <div class="userMenuTopLine">
        <div class="userMenuTopLineItem">{{currentTenantName}}</div>
        <div class="userMenuTopLineItemSignout">
            <button *ngIf="isAuthenticated" mat-flat-button (click)="logout()">Sign out</button>
            <button *ngIf="!isAuthenticated" mat-flat-button (click)="login()">Sign in</button>
        </div>
    </div>
    <div class="sidebarWrap">
        <div class="userMenuProfile">
            <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 20 20" height="70%" width="70%"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd">
                </path>
            </svg>
        </div>
        <div class="userMenuProfileItems">
            <P class="userMenuProfileItems userMenuProfileItemsName">{{name}}</P>
            <p class="userMenuProfileItems userMenuProfileItemsEmail">{{username}}</p>
            <p class="userMenuProfileItems userMenuProfileItemsSettings" (click)="navAccountSettings()"><u>Account
                    Settings</u></p>
        </div>
    </div>

    <div class="permissionIcons">
        <div *ngIf="permCGAdmin" class="permissionIconItems" matTooltip="CG Admin">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" stroke-width="2"
                    d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L8,23 M10,19.5 C10,20.88 11.12,22 12.5,22 C13.881,22 15,20.88 15,19.5 C15,18.119 13.881,17 12.5,17 C11.12,17 10,18.119 10,19.5 L10,19.5 Z M23,15 L20,12 L14,18 M17.5,14.5 L20.5,17.5 L17.5,14.5 Z">
                </path>
            </svg>
        </div>
        <div *ngIf="permAdmin" class="permissionIconItems" matTooltip="Admin">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" stroke-width="2"
                    d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                </path>
            </svg>
        </div>
        <div *ngIf="permArchive" class="permissionIconItems" matTooltip="Archive User">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3 10H2V4.00293C2 3.44903 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.43788 22 4.00293V10H21V20.0015C21 20.553 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5525 3 20.0015V10ZM19 10H5V19H19V10ZM4 5V8H20V5H4ZM9 12H15V14H9V12Z">
                </path>
            </svg>
        </div>
        <div *ngIf="permInteractive" class="permissionIconItems" matTooltip="Interactive User">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM304.8 524h50.7c3.7 0 6.8-3 6.8-6.8v-78.9c0-19.7 15.9-35.6 35.5-35.6h205.7v53.4c0 5.7 6.5 8.8 10.9 5.3l109.1-85.7c3.5-2.7 3.5-8 0-10.7l-109.1-85.7c-4.4-3.5-10.9-.3-10.9 5.3V338H397.7c-55.1 0-99.7 44.8-99.7 100.1V517c0 4 3 7 6.8 7zm-4.2 134.9l109.1 85.7c4.4 3.5 10.9.3 10.9-5.3v-53.4h205.7c55.1 0 99.7-44.8 99.7-100.1v-78.9c0-3.7-3-6.8-6.8-6.8h-50.7c-3.7 0-6.8 3-6.8 6.8v78.9c0 19.7-15.9 35.6-35.5 35.6H420.6V568c0-5.7-6.5-8.8-10.9-5.3l-109.1 85.7c-3.5 2.5-3.5 7.8 0 10.5z">
                </path>
            </svg>
        </div>
        <div *ngIf="permTemplate" class="permissionIconItems" matTooltip="Template User">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                </path>
            </svg>
        </div>
        <div *ngIf="permProc" class="permissionIconItems" matTooltip="Process Services">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.5 2h13l.5.5V9h-1V6H2v7h7v1H1.5l-.5-.5v-11l.5-.5zM2 5h12V3H2v2zm5 7v-1.094a1.633 1.633 0 0 1-.469-.265l-.945.539-.5-.86.937-.547a1.57 1.57 0 0 1 0-.547l-.937-.546.5-.86.945.54c.151-.12.308-.209.469-.266V7h1v1.094a1.48 1.48 0 0 1 .469.265l.945-.539.5.86-.937.547a1.57 1.57 0 0 1 0 .546l.937.547-.5.86-.945-.54a1.807 1.807 0 0 1-.469.266V12H7zm-.25-2.5c0 .208.073.385.219.531a.723.723 0 0 0 .531.219.723.723 0 0 0 .531-.219.723.723 0 0 0 .219-.531.723.723 0 0 0-.219-.531.723.723 0 0 0-.531-.219.723.723 0 0 0-.531.219.723.723 0 0 0-.219.531zm5.334 5.5v-1.094a1.634 1.634 0 0 1-.469-.265l-.945.539-.5-.86.938-.547a1.572 1.572 0 0 1 0-.547l-.938-.546.5-.86.945.54c.151-.12.308-.209.47-.266V10h1v1.094a1.486 1.486 0 0 1 .468.265l.945-.539.5.86-.937.547a1.562 1.562 0 0 1 0 .546l.937.547-.5.86-.945-.54a1.806 1.806 0 0 1-.469.266V15h-1zm-.25-2.5c0 .208.073.385.219.531a.723.723 0 0 0 .531.219.723.723 0 0 0 .531-.219.723.723 0 0 0 .22-.531.723.723 0 0 0-.22-.531.723.723 0 0 0-.53-.219.723.723 0 0 0-.532.219.723.723 0 0 0-.219.531z">
                </path>
            </svg>
        </div>
        <div *ngIf="permView" class="permissionIconItems" matTooltip="View User">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="20px"
                width="20px" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M216.49,79.52l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40V216a20,20,0,0,0,20,20H200a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.52ZM183,80H160V57ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm96.48-48.49a36,36,0,1,0-17,17l12,12a12,12,0,0,0,17-17ZM112,148a12,12,0,1,1,12,12A12,12,0,0,1,112,148Z">
                </path>
            </svg>
        </div>
    </div>
</mat-menu>
<mat-drawer-container class="container" hasBackdrop="{{!menuDocker}}" autosize>
    <mat-drawer #drawer class="sidenav" [mode]="drawerMode" autoFocus="false">
        <div class="sidebarMainDiv">
            <mat-form-field color="accent" class="tenantSelect" *ngIf="permCGAdmin">
                <mat-label>Tenant</mat-label>
                <mat-select [(value)]="currentTenant" (selectionChange)="tenantChanged($event)">
                    <!-- @for (food of foods; track food) {
                <mat-option [value]="food.value">{{food.viewValue}}</mat-option>
                } -->
                    @for (tenant of tenantList; track tenant){
                    <mat-option [value]="tenant.value">{{tenant.viewValue}}</mat-option>
                    }
                    <!-- <mat-option value="Demo Mobile">Demo Mobile</mat-option>
                    <mat-option value="Communication Genetics">Communication Genetics</mat-option> -->
                </mat-select>
            </mat-form-field>
            <mat-card *ngIf="permCGAdmin == false" style="margin: 10px;">
                <mat-card-content>
                    <p class="tenantHeading">Tenant</p>
                    <p class="tenantSubHeading">{{currentTenantName}}</p>
                </mat-card-content>
            </mat-card>
            <hr color="white" width="99%" noshade>
            <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="menuScollView" style="user-select: none;">


                <button (click)="navHome()" class="sidebarMenuButton"
                    [ngClass]="navHomeStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 1024 1024" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z">
                                        </path>
                                    </svg></td>
                                <td width="220px">Home</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showUsage" (click)="navUsageStatistics()" class="sidebarMenuButton"
                    [ngClass]="naveUsageStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z">
                                        </path>
                                    </svg></td>
                                <td width="220px">Usage Statistics</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showArchive" (click)="navDocumentArchive()" class="sidebarMenuButton"
                    [ngClass]="navArchiveStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                d="M3 10H2V4.003C2 3.449 2.455 3 2.992 3h18.016A.99.99 0 0 1 22 4.003V10h-1v10.001a.996.996 0 0 1-.993.999H3.993A.996.996 0 0 1 3 20.001V10zm16 0H5v9h14v-9zM4 5v3h16V5H4zm5 7h6v2H9v-2z">
                                            </path>
                                        </g>
                                    </svg></td>
                                <td width="220px">Document Archive</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showJobs" (click)="toggleJobs()" class="sidebarMenuButton">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="20" r="2"></circle>
                                        <circle cx="12" cy="4" r="2"></circle>
                                        <circle cx="6.343" cy="17.657" r="2"></circle>
                                        <circle cx="17.657" cy="6.343" r="2"></circle>
                                        <circle cx="4" cy="12" r="2.001"></circle>
                                        <circle cx="20" cy="12" r="2"></circle>
                                        <circle cx="6.343" cy="6.344" r="2"></circle>
                                        <circle cx="17.657" cy="17.658" r="2"></circle>
                                    </svg></td>
                                <td width="220px">Jobs</td>
                                <td width="30px">
                                    <div *ngIf="!jobsOpen"><svg stroke="currentColor" fill="currentColor"
                                            stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path d="M12 8l6 6H6z"></path>
                                            </g>
                                        </svg></div>
                                    <div *ngIf="jobsOpen"><svg stroke="currentColor" fill="currentColor"
                                            stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path d="M12 16l-6-6h12z"></path>
                                            </g>
                                        </svg></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <div *ngIf="showJobs" class="sidebarMainDiv">
                    <button *ngIf="jobsOpen" (click)="navSubmitJobs()" class="sidebarMenuButtonLower"
                        [ngClass]="navSubmitJobsStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.426,11.095l-17-8c-0.35-0.163-0.763-0.112-1.061,0.133C3.066,3.473,2.937,3.868,3.03,4.242L4.969,12L3.03,19.758	c-0.094,0.374,0.036,0.77,0.335,1.015C3.548,20.923,3.772,21,4,21c0.145,0,0.29-0.031,0.426-0.095l17-8	C21.776,12.74,22,12.388,22,12S21.776,11.26,21.426,11.095z M5.481,18.197L6.32,14.84L12,12L6.32,9.16L5.481,5.803L18.651,12	L5.481,18.197z">
                                            </path>
                                        </svg></td>
                                    <td width="220px">Submit Jobs</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button *ngIf="jobsOpen" (click)="navJobStatus()" class="sidebarMenuButtonLower"
                        [ngClass]="navJobstatusStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M19.931,11H13V6V4.069 C16.611,4.522,19.478,7.389,19.931,11z M4,12c0-4.072,3.061-7.436,7-7.931V6v6c0,0.119,0.028,0.234,0.069,0.345 c0.013,0.032,0.026,0.062,0.042,0.093c0.015,0.03,0.022,0.063,0.041,0.093l4.202,6.723C14.332,19.728,13.198,20,12,20 C7.589,20,4,16.411,4,12z M17.052,18.196L13.805,13h6.126C19.669,15.087,18.605,16.928,17.052,18.196z">
                                            </path>
                                        </svg></td>
                                    <td width="220px">Job Status</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>

                <button *ngIf="showInteractive" (click)="toggleInteractive()" class="sidebarMenuButton">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 1024 1024" height="1em" width="1em"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM304.8 524h50.7c3.7 0 6.8-3 6.8-6.8v-78.9c0-19.7 15.9-35.6 35.5-35.6h205.7v53.4c0 5.7 6.5 8.8 10.9 5.3l109.1-85.7c3.5-2.7 3.5-8 0-10.7l-109.1-85.7c-4.4-3.5-10.9-.3-10.9 5.3V338H397.7c-55.1 0-99.7 44.8-99.7 100.1V517c0 4 3 7 6.8 7zm-4.2 134.9l109.1 85.7c4.4 3.5 10.9.3 10.9-5.3v-53.4h205.7c55.1 0 99.7-44.8 99.7-100.1v-78.9c0-3.7-3-6.8-6.8-6.8h-50.7c-3.7 0-6.8 3-6.8 6.8v78.9c0 19.7-15.9 35.6-35.5 35.6H420.6V568c0-5.7-6.5-8.8-10.9-5.3l-109.1 85.7c-3.5 2.5-3.5 7.8 0 10.5z">
                                        </path>
                                    </svg></td>
                                <td width="220px">Interactive Documents</td>
                                <td width="30px">
                                    <div *ngIf="!interactiveOpen"><svg stroke="currentColor" fill="currentColor"
                                            stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path d="M12 8l6 6H6z"></path>
                                            </g>
                                        </svg></div>
                                    <div *ngIf="interactiveOpen"><svg stroke="currentColor" fill="currentColor"
                                            stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path d="M12 16l-6-6h12z"></path>
                                            </g>
                                        </svg></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <div *ngIf="showInteractive" class="sidebarMainDiv">
                    <button *ngIf="interactiveOpen" (click)="navInteractive()" class="sidebarMenuButtonLower"
                        [ngClass]="navInteractiveNewStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.426,11.095l-17-8c-0.35-0.163-0.763-0.112-1.061,0.133C3.066,3.473,2.937,3.868,3.03,4.242L4.969,12L3.03,19.758	c-0.094,0.374,0.036,0.77,0.335,1.015C3.548,20.923,3.772,21,4,21c0.145,0,0.29-0.031,0.426-0.095l17-8	C21.776,12.74,22,12.388,22,12S21.776,11.26,21.426,11.095z M5.481,18.197L6.32,14.84L12,12L6.32,9.16L5.481,5.803L18.651,12	L5.481,18.197z">
                                            </path>
                                        </svg></td>
                                    <td width="220px">Create Drafts</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button *ngIf="interactiveOpen" (click)="navMyDrafts()" class="sidebarMenuButtonLower"
                        [ngClass]="navInteractiveMyDraftStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px">
                                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                                            stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 4a2 2 0 0 1 2-2h10l4 4v10.2a2 2 0 0 1-2 1.8H8a2 2 0 0 1-2-2Z">
                                            </path>
                                            <path d="M10 2v4h6"></path>
                                            <path d="M18 18v-7h-8v7"></path>
                                            <path d="M18 22H4a2 2 0 0 1-2-2V6"></path>
                                        </svg>
                                    </td>
                                    <td width="220px">Saved Drafts</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button *ngIf="interactiveOpen" (click)="navWorkflowApproval()" class="sidebarMenuButtonLower"
                        [ngClass]="navInteractiveApproveStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                d="M4 16v6h16v-6c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2zm14 2H6v-2h12v2zM12 2C9.24 2 7 4.24 7 7l5 7 5-7c0-2.76-2.24-5-5-5zm0 9L9 7c0-1.66 1.34-3 3-3s3 1.34 3 3l-3 4z">
                                            </path>
                                        </svg></td>
                                    <td width="220px">Workflow Approval</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button *ngIf="interactiveOpen" (click)="navCompletedTasks()" class="sidebarMenuButtonLower"
                        [ngClass]="navInteractiveCompletedTasksStyle?'sidebarMenuButtonSelected':''">
                        <table width="280px">
                            <tbody>
                                <tr>
                                    <td width="30px"></td>
                                    <td width="30px">
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2zM5 5h2v2h10V5h2v15H5V5z">
                                            </path>
                                            <path
                                                d="m11 13.586-1.793-1.793-1.414 1.414L11 16.414l5.207-5.207-1.414-1.414z">
                                            </path>
                                        </svg>
                                    </td>
                                    <td width="220px">Completed Tasks</td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
                <button *ngIf="showReports" (click)="navReports()" class="sidebarMenuButton"
                    [ngClass]="navReportsStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="none" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                                        </path>
                                    </svg></td>
                                <td width="220px">Reports</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showTemplating" (click)="navTemplating()" class="sidebarMenuButton"
                    [ngClass]="navTemplatingStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="none" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                                        </path>
                                    </svg></td>
                                <td width="220px">Templating Environment</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showProcess" (click)="navProcess()" class="sidebarMenuButton"
                    [ngClass]="navProcessStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16"
                                        height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M1.5 2h13l.5.5V9h-1V6H2v7h7v1H1.5l-.5-.5v-11l.5-.5zM2 5h12V3H2v2zm5 7v-1.094a1.633 1.633 0 0 1-.469-.265l-.945.539-.5-.86.937-.547a1.57 1.57 0 0 1 0-.547l-.937-.546.5-.86.945.54c.151-.12.308-.209.469-.266V7h1v1.094a1.48 1.48 0 0 1 .469.265l.945-.539.5.86-.937.547a1.57 1.57 0 0 1 0 .546l.937.547-.5.86-.945-.54a1.807 1.807 0 0 1-.469.266V12H7zm-.25-2.5c0 .208.073.385.219.531a.723.723 0 0 0 .531.219.723.723 0 0 0 .531-.219.723.723 0 0 0 .219-.531.723.723 0 0 0-.219-.531.723.723 0 0 0-.531-.219.723.723 0 0 0-.531.219.723.723 0 0 0-.219.531zm5.334 5.5v-1.094a1.634 1.634 0 0 1-.469-.265l-.945.539-.5-.86.938-.547a1.572 1.572 0 0 1 0-.547l-.938-.546.5-.86.945.54c.151-.12.308-.209.47-.266V10h1v1.094a1.486 1.486 0 0 1 .468.265l.945-.539.5.86-.937.547a1.562 1.562 0 0 1 0 .546l.937.547-.5.86-.945-.54a1.806 1.806 0 0 1-.469.266V15h-1zm-.25-2.5c0 .208.073.385.219.531a.723.723 0 0 0 .531.219.723.723 0 0 0 .531-.219.723.723 0 0 0 .22-.531.723.723 0 0 0-.22-.531.723.723 0 0 0-.53-.219.723.723 0 0 0-.532.219.723.723 0 0 0-.219.531z">
                                        </path>
                                    </svg>
                                </td>
                                <td width="220px">Process Services</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showSettings" (click)="navSettings()" class="sidebarMenuButton"
                    [ngClass]="navSettingsStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z">
                                            </path>
                                        </g>
                                    </svg></td>
                                <td width="220px">Settings</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showHelp" (click)="navHelp()" class="sidebarMenuButton"
                    [ngClass]="navHelpStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="none" stroke-width="2"
                                        viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em"
                                        width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg></td>
                                <td width="220px">Help</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showContact" (click)="navContactUs()" class="sidebarMenuButton"
                    [ngClass]="navContactStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                d="M19 7h5v2h-5V7zm-2 5h7v2h-7v-2zm3 5h4v2h-4v-2zM2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z">
                                            </path>
                                        </g>
                                    </svg></td>
                                <td width="220px">Contact Us</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
                <button *ngIf="showAdmin" (click)="navAdmin()" class="sidebarMenuButton"
                    [ngClass]="navAdminStyle?'sidebarMenuButtonSelected':''">
                    <table width="280px">
                        <tbody>
                            <tr>
                                <td width="30px"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                        viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path
                                                d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm9 6h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z">
                                            </path>
                                        </g>
                                    </svg></td>
                                <td width="220px">Admin</td>
                                <td width="30px"></td>
                            </tr>
                        </tbody>
                    </table>
                </button>
            </cdk-virtual-scroll-viewport>
        </div>
        <mat-slide-toggle class="dockMenuSlideToggle" (change)="menuDockClick($event)" [(ngModel)]="menuDocker"><label
                class="dockMenuSlideToggleLabel" style="user-select: none;">Dock Menu</label></mat-slide-toggle>
    </mat-drawer>
    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
        <div>
            <table width="100%" class="portalMainTable mainSiteTable" style="border: 0px none;">
                <tbody [ngStyle]="{'verticalAlign': 'top'}" style="border: 0px none;">
                    <tr class="portalMainTableRow" style="border: 0px none;">
                        <!-- <td width="0%"  style="border: 0px none;"></td> -->
                        <td class="portalMainTableCenter" width="1000%" style="border: 0px none;">
                            <!-- {{currentPath}} -->
                            <router-outlet></router-outlet>
                        </td>
                        <!-- <td width="0%"  style="border: 0px none;"></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </cdk-virtual-scroll-viewport>

</mat-drawer-container>


<div class="mainFooterSpace">
    <div></div>
</div>
<div class="mainFooter">
    <div>
        <table [ngStyle]="{'width': '100%' }">
            <tbody [ngStyle]="{'width': '100%' }">
                <tr>
                    <td [ngStyle]="{'textAlign': 'left'}"></td>
                    <td [ngStyle]="{'width': '34%', 'textAlign': 'center'}">
                        <p class="footerParagraph">CG SaaS</p>
                    </td>
                    <td [ngStyle]="{'width': '33%', 'textAlign': 'right'}">
                        <p class="footerParagraphPopia" (click)="openDialog('0ms', '0ms')">POPIA Statement</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <svg [ngStyle]="{'verticalAlign': 'top'}" width="100%" height="100%" version="1.1" viewBox="0 0 226.35 1.1754"
            xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1 0 0 .51837 14.821 -80.544)" strokeWidth=".22567">
                <rect transform="rotate(90)" x="155.38" y="-211.53" width="2.2675" height="72.75" fill="#75787b" />
                <rect transform="rotate(90)" x="155.38" y="-134.73" width="2.2675" height="72.75" fill="#5b9bd5" />
                <rect transform="rotate(90)" x="155.38" y="-57.929" width="2.2675" height="72.75" fill="#00ab8e" />
            </g>
        </svg>
    </div>
    <app-toasts></app-toasts>
    <ng-template appToastDirective="standardTpl"> </ng-template>

    <ng-template appToastDirective="successTpl"> </ng-template>

    <ng-template appToastDirective="dangerTpl">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z" />
        </svg> -->
    </ng-template>

    <ng-template appToastDirective="notifTpl"> </ng-template>
    <iframe style="display: none;" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" [src]="embedURL"
        allow="clipboard-read; clipboard-write" width='100%'></iframe>
</div>