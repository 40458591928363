<div style="user-select: none;">
    <h1 mat-dialog-title *ngIf="data.addUser == true"><b>Add User</b></h1>
    <h1 mat-dialog-title *ngIf="data.addUser == false"><b>Edit User</b></h1>
    <div mat-dialog-content>

        <div *ngIf="data.addUser == true">
            <p class="itemHeading">Username</p>
            <div class="flexRow">
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [value]="username"
                    [(ngModel)]="username">
                <p class="paragraphtext">{{at}}{{currentTenantName}}</p>
            </div>
        </div>
        <div *ngIf="data.addUser == false" class="flexRow">
            <div>
                <p class="itemHeading">Username</p>
                <input class="mainTextbox valueTextbox localTextBox" [value]="username" disabled>
            </div>
            <div>
                <p class="itemHeading">User </p>
                <mat-checkbox class="enableCheckbox" [checked]="enabled" [(ngModel)]="enabled">Enabled</mat-checkbox>
            </div>
        </div>
        <div class="flexRow">
            <div>

                <p class="itemHeading">First Name</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;"
                    [value]="firstName" [(ngModel)]="firstName">

                <p class="itemHeading">Last Name</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [value]="lastName"
                    [(ngModel)]="lastName">
            </div>
            <div *ngIf="data.addUser == false">
                <p class="itemHeading">User ID</p>
                <input class="mainTextbox valueTextbox localTextBox" [value]="data.userInfo.id" disabled>

                <p class="itemHeading">Email</p>
                <input class="mainTextbox valueTextbox localTextBox" [value]="data.userInfo.email" disabled>
            </div>
            <div *ngIf="data.addUser == true">
                <p class="itemHeading">Email</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [value]="email"
                    [(ngModel)]="email">

                <p class="itemHeading">Password</p>
                <input class="mainTextbox valueTextbox localTextBox" [value]="password" disabled
                    style="pointer-events:none;">
            </div>

            <div>
                <p class="errorText">{{errorMessage}}</p>
            </div>
        </div>
        <mat-accordion>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p style="font-size: 14px; font-weight: bold;">Permissions</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="mainTextbox valueTextbox localTextBox"
                        style="width: 100% !important; max-width: 600px !important;">
                        <div *ngIf="permissionsLoading" style="display: flex; justify-content: center; height: 202px;">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="!permissionsLoading"
                            style="display: block; width: fit-content; height: 125px; overflow-y: scroll; width: 100%;">
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked" style="width: 100px;"
                                aria-label="Permissions selection" multiple [(ngModel)]="PermissionSelected">
                                @if(data.addUser == true){
                                @for( item of permissions; track item){
                                <mat-chip-option style="font-size: 12px; height: 24px;" [selected]="item.InGroup"
                                    color="accent" [value]="item.GroupName">{{item.GroupName}}</mat-chip-option>
                                }
                                }
                                @if(data.addUser == false){
                                @for( item of permissions; track item){
                                <mat-chip-option style="font-size: 12px; height: 24px;" [selected]="item.InGroup"
                                    color="accent" [value]="item.GroupID">{{item.GroupName}}</mat-chip-option>
                                }
                                }

                            </mat-chip-listbox>
                            <!-- {{permissions | json}} -->
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p style="font-size: 14px; font-weight: bold;">Templating Groups</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="mainTextbox valueTextbox localTextBox"
                        style="width: 100% !important; max-width: 600px !important;">
                        <div *ngIf="groupsLoading" style="display: flex; justify-content: center; height: 202px;">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="!groupsLoading"
                            style="display: block; width: fit-content; height: 125px; overflow-y: scroll; width: 100%;">
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked" style="width: 100px;"
                                aria-label="Permissions selection" multiple [(ngModel)]="groupSelected">

                                @for( item of groups; track item){
                                <mat-chip-option style="font-size: 12px; height: 24px;" [selected]="item.inGroup"
                                    color="accent">{{item.name}}</mat-chip-option>
                                }
                            </mat-chip-listbox>
                            <!-- {{permissions | json}} -->
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p style="font-size: 14px; font-weight: bold;">Templateing Roles</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="mainTextbox valueTextbox localTextBox"
                        style="width: 100% !important; max-width: 600px !important;">
                        <div *ngIf="rolesLoading" style="display: flex; justify-content: center; height: 202px;">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="!rolesLoading"
                            style="display: block; width: fit-content; height: 125px; overflow-y: scroll; width: 100%;">
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked" style="width: 100px;"
                                aria-label="Permissions selection" multiple [(ngModel)]="RoleSelected">

                                @for( item of roles; track item){
                                <!-- {{.type(item.RoleName)}} -->

                                <mat-chip-option style="font-size: 12px; height: 24px;" [selected]="item.InRole"
                                    color="accent" [value]="item.RoleName">{{item.RoleDescription}}</mat-chip-option>
                                }
                            </mat-chip-listbox>
                            <!-- {{permissions | json}} -->
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p style="font-size: 14px; font-weight: bold;">Workflow Pools</p>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="mainTextbox valueTextbox localTextBox"
                        style="width: 100% !important; max-width: 600px !important;">
                        <div *ngIf="poolsLoading" style="display: flex; justify-content: center; height: 202px;">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div *ngIf="!poolsLoading"
                            style="display: block; width: fit-content; height: 125px; overflow-y: scroll; width: 100%;">
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked" style="width: 100px;"
                                aria-label="Permissions selection" multiple [(ngModel)]="poolSelected">

                                @for( item of pools; track item){
                                <mat-chip-option style="font-size: 12px; height: 24px;" [selected]="item.inPool" [value]="item.id"
                                    color="accent">{{item.name}}</mat-chip-option>
                                }
                            </mat-chip-listbox>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="secondary" mat-dialog-close [disabled]="usrLoading">Close</button>
        <button mat-flat-button color="primary" (click)="createUser()" *ngIf="data.addUser == true"
            [disabled]="usrLoading">Add</button>
        <button mat-flat-button color="primary" (click)="updateUserDetails()" *ngIf="data.addUser == false"
            [disabled]="usrLoading">Update</button>
    </div>
    <mat-card *ngIf="usrLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
</div>