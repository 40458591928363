import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

export interface queueTable {
  queue: string;
  input: string;
  output: string;
  action: string;
  id: string;
}

export interface addQueueDialogData {
  queue: string;
  input: string;
  output: string;
}


@Component({
  selector: 'app-queue-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
  ],
  templateUrl: './queue-settings.component.html',
  styleUrl: './queue-settings.component.scss'
})
export class QueueSettingsComponent implements OnInit {

  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatTable) table: MatTable<queueTable>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['queue', 'input', 'output', 'action'];
  queuesList: queueTable[] = [];
  dataSource = new MatTableDataSource(this.queuesList);
  quelistLoading = false;
  filterValue = '';

  ngOnInit(): void {
    this.getQueueList();

  }
  getQueueList() {
    this.queuesList = [];
    this.quelistLoading = true;
    this.api.postService("/settings/listqueuesfortenant", { tenantID: localStorage.getItem('currentTenant') })
      .then((response) => {
        this.quelistLoading = false;
        // console.log(response);
        if (response.status == 200) {
          response.data.forEach((element: { QueueName: { S: any; }; S3Input: { S: any; }; S3Output: { S: any; }; id: { S: any; }; }) => {
            this.queuesList.push({
              queue: element.QueueName.S,
              input: element.S3Input.S,
              output: element.S3Output.S,
              action: "button",
              id: element.id.S
            });
          });
          this.dataSource = new MatTableDataSource(this.queuesList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.table.renderRows();
        }
        else{
          this.toastTemplates.showErrorToast("API error - Get Queue List", "Could not get Queue List.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Queue List", err);
        this.quelistLoading = false;
      })
  }
  openAddQueue() {

    const dialogRef = this.dialog.open(AddQueueDialog, {
      data: {queue: "", input: "", output: ""},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.quelistLoading = true;
        var request = {
          TenantID: localStorage.getItem('currentTenant'),
          QueueName: result.queue,
          S3Input: result.input,
          S3Output: result.output
        }
        this.api.postService("/settings/addqueuetotenant", request)
          .then((response) => {
            if(response.status == 200){
              if(response.data.statusCode != 200){
                this.toastTemplates.showErrorToast("API error - Add Queue", "Could not add queue to tenant.");
              }
            }
            else{
              this.toastTemplates.showErrorToast("API error - Add Queue", "Could not add queue to tenant.");
            }
            this.quelistLoading = false;
            this.getQueueList();
          })
          .catch((err) => {
            this.quelistLoading = false;
            this.toastTemplates.showErrorToast("API error - Add Queue", err);
            // console.log(err);
          });
      }
    });
  }

  deleteQueue(id: string) {
    this.quelistLoading = true;
    var request = {
      TenantID: localStorage.getItem('currentTenant'),
      QueueID: id
  }
    this.api.postService("/settings/deletequeufromtenant", request)
    .then((response) => {
      if(response.status == 200){
        if(response.data.statusCode != 200){
          this.toastTemplates.showErrorToast("API error - Delete Queue", "Could not delete queue from tenant.");
        }
      }
      else{
        this.toastTemplates.showErrorToast("API error - Delete Queue", "Could not delete queue from tenant.");
      }
      this.quelistLoading = false;
      this.getQueueList();
    })
    .catch((err) => {
      this.quelistLoading = false;
      this.toastTemplates.showErrorToast("API error - Delete Queue", err);
      // console.log(err);
    })
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}


@Component({
  selector: 'add-queue-dialog',
  templateUrl: 'add-queue-dialog.html',
  styleUrl: './queue-settings.component.scss',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, FormsModule,],
})
export class AddQueueDialog {
  constructor(public api: WebMethodsService, public dialogRef: MatDialogRef<AddQueueDialog>, @Inject(MAT_DIALOG_DATA) public data: addQueueDialogData,) { }
  closeDialog(){
    this.dialogRef.close();
  }
}