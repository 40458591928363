<div class="userSettingsCardsDiv">
    <div class="columnFlex">


        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <div class="userMenuProfileItemsInSettings">
                        <P class="userMenuProfileItemsInSettings userMenuProfileItemsNameSettings">{{name}}</P>
                        <div class="flexItems" style="padding-bottom: 5px;">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256"
                                height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120ZM72.57,200a64,64,0,0,1,110.86,0ZM216,200H201.33a80.14,80.14,0,0,0-43.69-42.28,48,48,0,1,0-59.28,0A80.14,80.14,0,0,0,54.67,200H40V56H216V200Z">
                                </path>
                            </svg>
                            <p class="userMenuProfileItemsInSettings userMenuProfileItemsEmailSettings"
                                style="margin-left: 5px;">{{username}}</p>
                        </div>
                        <div class="flexItems" style="padding-bottom: 5px;">
                            <svg style="padding-right: 5px;" stroke="currentColor" fill="none" stroke-width="2"
                                viewBox="0 0 24 24" aria-hidden="true" height="20px" width="20px"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                                </path>
                            </svg>
                            <p class="userMenuProfileItemsInSettings userMenuProfileItemsEmailSettings">{{email}}</p>
                        </div>
                        <!-- <div class="flexItems" style="padding-bottom: 5px;">
                            <svg style="padding-right: 5px;" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 256 256" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z">
                                </path>
                            </svg>
                            <p class="userMenuProfileItemsEmailSettings">{{userID!=''?userID:'Not yet captured.'}}</p>
                        </div> -->
                        <div class="flexItems" style="padding-bottom: 5px;">
                            <svg style="padding-right: 5px;" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 320 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z">
                                </path>
                            </svg>
                            <p *ngIf="editMobile == false" class="userMenuProfileItemsEmailSettings">
                                {{mobile==null||mobile==""?'Not captured.':mobile}}</p>
                            <input [ngClass]="!phoneValid?'smallTextboxError':'smallTextbox'" *ngIf="editMobile == true"
                                placeholder="27761230000" type="tel" [(ngModel)]="newMobile"
                                pattern="[2]{1}[7]{1}[1-9]{1}[0-9]{8}" [disabled]="phoneUpdateLoading"
                                (input)="checkMobileValid($event)">
                            <div (click)="editMobileButton()" *ngIf="editMobile == false"
                                style="margin-left: 2px; cursor: pointer; width: 20px; height: 20px; align-items: center; justify-content: center; display: flex;"
                                class="pencilEdit">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                    height="15px" width="15px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path
                                        d="m14.06 9.02.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z">
                                    </path>
                                </svg>
                            </div>
                            <div (click)="updateMobileButton()" *ngIf="editMobile == true && !phoneUpdateLoading"
                                style="margin-left: 2px;  width: 20px; height: 20px; align-items: center; justify-content: center; display: flex;"
                                [ngClass]="!phoneValid?'pencilEditDiable':'pencilEdit'">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                    height="15px" width="15px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                                </svg>
                            </div>
                            <div (click)="cancelMobileButton()" *ngIf="editMobile == true && !phoneUpdateLoading"
                                style="margin-left: 2px; cursor: pointer; width: 20px; height: 20px; align-items: center; justify-content: center; display: flex;"
                                class="pencilEdit">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                    height="15px" width="15px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path
                                        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                                    </path>
                                </svg>
                            </div>
                            <div *ngIf="phoneUpdateLoading" style="width: 25px; height: 25px; margin-left: 3px;">
                                <mat-spinner style="width: 25px; height: 25px;"></mat-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="userMenuProfileIconSettings">
                        <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 20 20" height="70%"
                            width="70%" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clip-rule="evenodd">
                            </path>
                        </svg>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Change My Password</p>
                </div>
                <div class="permissionIconItemsFlex">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p class="passwordText">New Password</p>
                                </td>
                                <td>
                                    <input class="mainTextbox" placeholder="Password" type="password"
                                        [(ngModel)]="nPassword">
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="passwordText">Repeat New Password</p>
                                </td>
                                <td>
                                    <input class="mainTextbox" placeholder="Password" type="password"
                                        [(ngModel)]="rPassword">
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <button class="blueButton" (click)="changePassword()">Set New Password</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <p class="setPasswordError">{{passwordChangeError}}</p>
                <p class="setPasswordMessage">{{passwordChangeMessage}}</p>
                <div class="divSpacing">
                    <div class="passwordRequirements"><br>
                        <p class="passwordRequirements">*Minimum 8 characters</p>
                        <p class="passwordRequirements">*Require numbers</p>
                        <p class="passwordRequirements">*Require special characters</p>
                        <p class="passwordRequirements">*Require uppercase letters</p>
                        <p class="passwordRequirements">*Require lowercase letters</p>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-footer *ngIf="changePasswordLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Notifications</p>
                </div>
                <div class="permissionIconItemsFlex" style="justify-content: space-between;">
                    <mat-slide-toggle [checked]="emailNotification" [disabled]="emailNotifLoading"
                        (change)="updateNotifications($event, 'email')">Email</mat-slide-toggle>
                    <mat-slide-toggle [checked]="waNotification" [disabled]="emailNotifLoading"
                        (change)="updateNotifications($event, 'wa')">WhatsApp</mat-slide-toggle>
                    <mat-slide-toggle [checked]="smsNotification" [disabled]="emailNotifLoading"
                        (change)="updateNotifications($event, 'sms')">SMS</mat-slide-toggle>
                </div>
            </mat-card-content>
            <mat-card-footer *ngIf="emailNotifLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">MFA Devices</p>
                </div>
                <div *ngIf="mfaStatus == 'Enable'" class="permissionIconItemsFlex">
                    <button class="blueButton" (click)="enableMFAClick()">Enable MFA</button>
                </div>
                <div *ngIf="mfaStatus == 'Setup'">
                    <div class="divSpacing">
                        <p class="passwordRequirements">Multi Factor Authentication Configured. </p>
                        <p class="passwordRequirements">Follow instructions on next login for setup.</p>
                    </div>
                </div>
                <div *ngIf="mfaStatus == 'Avail'">
                    <div class="divSpacing">
                        <p class="passwordRequirements">2 Factor Authentication is enabled on your profile.</p>
                        <p class="passwordRequirements">Device: <b>{{OTPDevice}}</b></p>
                        <br>
                        <button (click)="disableMFAClick()" class="blueButton">Disable MFA</button>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-footer *ngIf="mfaLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </div>

    <div class="rowFlex">
        <!-- <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Tenants</p>
                </div>
                @for (item of tenantList; track item) {
                <div class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2"></path>
                        <path d="M19 13.488v-1.488h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h4.525"></path>
                        <path d="M15 19l2 2l4 -4"></path>
                    </svg>
                    <p class="permissionsTextSettings">{{item}}</p>
                </div>
                }
            </mat-card-content>
        </mat-card> -->

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Permissions</p>
                </div>
                <div *ngIf="permCGAdmin" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2"
                            d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L8,23 M10,19.5 C10,20.88 11.12,22 12.5,22 C13.881,22 15,20.88 15,19.5 C15,18.119 13.881,17 12.5,17 C11.12,17 10,18.119 10,19.5 L10,19.5 Z M23,15 L20,12 L14,18 M17.5,14.5 L20.5,17.5 L17.5,14.5 Z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">CG Admin</p>
                </div>
                <div *ngIf="permAdmin" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2"
                            d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">Admin</p>
                </div>
                <div *ngIf="permArchive" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3 10H2V4.00293C2 3.44903 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.43788 22 4.00293V10H21V20.0015C21 20.553 20.5551 21 20.0066 21H3.9934C3.44476 21 3 20.5525 3 20.0015V10ZM19 10H5V19H19V10ZM4 5V8H20V5H4ZM9 12H15V14H9V12Z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">Archive User</p>
                </div>
                <div *ngIf="permInteractive" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                        height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM304.8 524h50.7c3.7 0 6.8-3 6.8-6.8v-78.9c0-19.7 15.9-35.6 35.5-35.6h205.7v53.4c0 5.7 6.5 8.8 10.9 5.3l109.1-85.7c3.5-2.7 3.5-8 0-10.7l-109.1-85.7c-4.4-3.5-10.9-.3-10.9 5.3V338H397.7c-55.1 0-99.7 44.8-99.7 100.1V517c0 4 3 7 6.8 7zm-4.2 134.9l109.1 85.7c4.4 3.5 10.9.3 10.9-5.3v-53.4h205.7c55.1 0 99.7-44.8 99.7-100.1v-78.9c0-3.7-3-6.8-6.8-6.8h-50.7c-3.7 0-6.8 3-6.8 6.8v78.9c0 19.7-15.9 35.6-35.5 35.6H420.6V568c0-5.7-6.5-8.8-10.9-5.3l-109.1 85.7c-3.5 2.5-3.5 7.8 0 10.5z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">Interactive User</p>
                </div>
                <div *ngIf="permTemplate" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true"
                        height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">Template User</p>
                </div>
                <div *ngIf="permView" class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M216.49,79.52l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40V216a20,20,0,0,0,20,20H200a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.52ZM183,80H160V57ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm96.48-48.49a36,36,0,1,0-17,17l12,12a12,12,0,0,0,17-17ZM112,148a12,12,0,1,1,12,12A12,12,0,0,1,112,148Z">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">View User</p>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Templating Groups</p>
                </div>
                <div *ngIf="groupsList.length == 0">
                    <div class="permissionIconItemsFlex" style="align-items: center;">
                        <svg style="color: red;" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z">
                            </path>
                        </svg>
                        <p class="permissionsTextSettings">No Templating Groups Assigned</p>
                    </div>
                </div>
                @for (item of groupsList; track item){
                <div class="permissionIconItemsFlex">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2"
                            d="M12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 Z M6,22 L6,19 C6,15.6862915 8.6862915,13 12,13 C15.3137085,13 18,15.6862915 18,19 L18,22 M13,5 C13.4037285,3.33566165 15.0151447,2 17,2 C19.172216,2 20.98052,3.790861 21,6 C20.98052,8.209139 19.172216,10 17,10 L16,10 L17,10 C20.287544,10 23,12.6862915 23,16 L23,18 M11,5 C10.5962715,3.33566165 8.98485529,2 7,2 C4.82778404,2 3.01948003,3.790861 3,6 C3.01948003,8.209139 4.82778404,10 7,10 L8,10 L7,10 C3.71245602,10 1,12.6862915 1,16 L1,18">
                        </path>
                    </svg>
                    <p class="permissionsTextSettings">{{item}}</p>
                </div>
                }
            </mat-card-content>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Templating Roles</p>
                </div>
                <div *ngIf="!rolesLoading">
                    <div *ngIf="rolesDivData.length == 0">
                        <div class="permissionIconItemsFlex" style="align-items: center;">
                            <svg style="color: red;" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z">
                                </path>
                            </svg>
                            <p class="permissionsTextSettings">No Templating Roles Assigned</p>
                        </div>
                    </div>
                    @for (item of rolesDivData; track item) {
                    <div class="permissionIconItemsFlex">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M168.531 215.469l-29.864 29.864 96 96L448 128l-29.864-29.864-183.469 182.395-66.136-65.062zm236.802 189.864H106.667V106.667H320V64H106.667C83.198 64 64 83.198 64 106.667v298.666C64 428.802 83.198 448 106.667 448h298.666C428.802 448 448 428.802 448 405.333V234.667h-42.667v170.666z">
                            </path>
                        </svg>
                        <p class="permissionsTextSettings">{{item}}</p>
                    </div>
                    }
                </div>
            </mat-card-content>
            <mat-card-footer *ngIf="rolesLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>

        <mat-card class="userProfileCard">
            <mat-card-content>
                <div class="sidebarWrapSettings">
                    <p class="subHeadingInSettings">Workflow Pools</p>
                </div>
                <div *ngIf="poolsList == undefined">
                    <div class="permissionIconItemsFlex" style="align-items: center;">
                        <svg style="color: red;" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z">
                            </path>
                        </svg>
                        <p class="permissionsTextSettings">No Workflow Pools Assigned</p>
                    </div>
                </div>
                @for (item of poolsList; track item) {
                <div class="permissionIconItemsFlex">
                    <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                        viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path _ngcontent-ng-c2785473262="" fill="none" d="M0 0h24v24H0V0z"></path>
                        <path _ngcontent-ng-c2785473262=""
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                        </path>
                        <circle _ngcontent-ng-c2785473262="" cx="8" cy="14" r="2"></circle>
                        <circle _ngcontent-ng-c2785473262="" cx="12" cy="8" r="2"></circle>
                        <circle _ngcontent-ng-c2785473262="" cx="16" cy="14" r="2"></circle>
                    </svg>
                    <p class="permissionsTextSettings">{{item}}</p>
                </div>
                }
            </mat-card-content>
            <!-- <mat-card-footer *ngIf="rolesLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-card-footer> -->
        </mat-card>

    </div>



</div>