import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, NgModel, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { WebMethodsService } from '../web-methods.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { AcceptValidator, MaxSizeValidator } from '@angular-material-components/file-input';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbdToastGlobal } from '../toast/toast-global.component';

export interface jobData {
  jobName: string;
  projectName: string;
  templateSelector: string;
  fileType: string;
  queueData: any;
  file: any;
}

@Component({
  selector: 'app-submit-jobs',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatFileInputModule,
    MatProgressBarModule,
    MatCardModule,
    ReactiveFormsModule,
  ],
  templateUrl: './submit-jobs.component.html',
  styleUrl: './submit-jobs.component.scss'
})
export class SubmitJobsComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) {
    this.fileControl = new FormControl(this.files, [])
  }
  projects: any[] = [];
  templates: any[] = [];
  queues: any[] = [];
  fileText = "";
  projectLoading = false;
  templatesLoading = false;
  templatesDisabled = true;
  queuesLoading = false;
  jobName = "";
  selectedProject = "";
  selectedTemplate = "";
  selectedFileType = "application/xml";
  selectedQueue = "";
  fileControl: FormControl;
  files: any = {};
  submitJobButtonEnable = true;


  ngOnInit(): void {
    this.getProjects();
    this.getQueues();
    this.fileControl.valueChanges.subscribe((file: any) => {
      // console.log(file);
      this.files = file;
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const text = (event.target.result);
        this.fileText = text;
      };
      if (file.name) {
        if (file.size / 1048576 < 1) {
          reader.readAsText(file)
        }
        else {
          this.fileText = 'Can not view file. The file is larger that 1MB';
        }
      }
      this.inputValidation();
    });
  }

  inputValidation() {
    if (
      this.jobName == "" || this.selectedProject == "" || this.selectedTemplate == "" || this.selectedQueue == "" || this.files.name == undefined
    ) {
      this.submitJobButtonEnable = true;
    }
    else {
      this.submitJobButtonEnable = false;
    }
  }

  getProjects() {
    this.projectLoading = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant')
    }
    this.api.postService("/sc/listprojects", request)
      .then((response) => {
        this.projectLoading = false;
        if (response.status == 200) {
          response.data.forEach((element: any) => {
            var item = {
              value: element.id,
              text: element.name
            };
            this.projects.push(item);
          });
        }
        else{
          this.toastTemplates.showErrorToast("API error - List Projects", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - List Projects", "Could not read project data.");
        this.projectLoading = false;
      });
  }

  getTemplates(event: any) {
    this.inputValidation();
    this.selectedTemplate = "";
    this.selectedProject = event;
    this.templatesLoading = true;
    this.templatesDisabled = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      projectID: event
    }
    this.api.postService("/sc/listtemplateselector", request)
      .then((response) => {
        this.templatesLoading = false;
        this.templatesDisabled = false;
        // console.log(response);
        if (response.status == 200) {
          this.templates = response.data;
        }
        else{
          this.toastTemplates.showErrorToast("API error - List Templates", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - List Projects", "Could not read template data.");
        this.templatesLoading = false;
        this.templatesDisabled = false;
      });
  }

  getQueues() {
    this.queuesLoading = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant')
    };
    this.api.postService("/settings/listqueuesfortenant", request)
      .then((response) => {
        this.queuesLoading = false;
        // console.log(response);
        if (response.status == 200) {
          this.queues = response.data;
        }
        else{
          this.toastTemplates.showErrorToast("API error - List Queues", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - List Projects", "Could not read queue data.");
        this.queuesLoading = false;
      });
  }


  submitJob() {
    const dialogRef = this.dialog.open(SubmitJobDialog, {
      data: { queueData: this.selectedQueue, file: this.files, fileType: this.selectedFileType, projectName: this.selectedProject, templateSelector: this.selectedTemplate, jobName: this.jobName }, disableClose: true
    });
  }
}

@Component({
  selector: 'submit-job-dialog',
  templateUrl: 'submit-job-dialog.html',
  styleUrl: './submit-jobs.component.scss',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    FormsModule,
    MatProgressBarModule,
    MatSliderModule,
    CommonModule,
  ],
})
export class SubmitJobDialog implements OnInit {
  constructor(public api: WebMethodsService, public dialogRef: MatDialogRef<SubmitJobDialog>, @Inject(MAT_DIALOG_DATA) public data: jobData, private toastTemplates: NgbdToastGlobal) {
    window.addEventListener("storage", ((e: any) => {

      this.uploadProgress = localStorage.getItem('fileUploadProgress');

    }).bind(this));
  }


  uploadProgress: any = '0';
  closeDisable = true;
  uploading = false;
  completed = false;
  error = false;
  errorMessage = "";
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.closeDisable = true;
    this.uploading = true;
    var request1 = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: this.data.queueData.S3Input.S,
      FileName: this.data.file.name
    };
    // console.log(request1);
    this.api.postService("/document/get-presigend-s3-url", request1)
      .then((response1) => {
        // console.log(response1);
        if (response1.status == 200) {
          if (response1.data.statusCode == 200) {
            var body = JSON.parse(response1.data.body);
            // console.log(body);
            // console.log(body.signedURL);
            var presignerURL = body.signedURL;
            this.api.putService(presignerURL, this.data.file)
              .then((response2) => {
                // console.log(response2);
                var requestSubmit = {
                  tenantId: localStorage.getItem('currentTenant'),
                  queue: this.data.queueData.QueueName.S,
                  purgeLocked: 'true',
                  transactionDataType: this.data.fileType,
                  input: this.data.file.name,
                  project: this.data.projectName,
                  type: "TRANSACTION_FILE",
                  config: this.data.templateSelector,
                  name: this.data.jobName
                }
                this.api.postService("/sc/submitjobs3", requestSubmit)
                  .then((responseSubmit) => {
                    // console.log(responseSubmit);
                    if(responseSubmit.status == 200){
                      this.errorMessage = responseSubmit.data.Status;
                    }
                    else{
                      this.toastTemplates.showErrorToast("API error - Submit Job", "A connection error has occured. Please contact support for assistance.");
                    }
                    this.uploading = false;
                    this.closeDisable = false;
                    this.completed = true;
                  })
                  .catch((err) => {
                    this.toastTemplates.showErrorToast("API error - Submit Job", "Job could not be submitted.");
                    // console.log(err);
                    this.uploading = false;
                    this.closeDisable = false;
                    this.error = true;
                  })
              })
              .catch((err) => {
                this.toastTemplates.showErrorToast("API error - Upload to S3", "Could not upload document to S3. Please check permissions on your S3 bucket.");
                // console.log(err);
                this.uploading = false;
                this.closeDisable = false;
                this.error = true;
              });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get upload URL", "Could not get upload URL.");
            this.uploading = false;
            this.closeDisable = false;
            this.error = true;
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get upload URL", "Could not get upload URL.");
          this.uploading = false;
          this.closeDisable = false;
          this.error = true;
        }
      })
      .catch((err) => {
        this.toastTemplates.showErrorToast("API error - List Queues", "Could not get upload URL.");
        // console.log(err);
        this.uploading = false;
        this.closeDisable = false;
        this.error = true;
      });

  }
}