<div class="mainDiv">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-card *ngIf="rolesLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>