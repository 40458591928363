<div>
    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <div
            style="margin-top: 10px; margin-bottom: 5px; margin-left: 10px; display: flex; flex-direction: row; justify-content: flex-start;">
            <div class="input-icons">
                <i class="icon">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </i>
                <input class="filterInput" [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Find Project"
                    #input>
                <i class=" icon iconRight" (click)="clearFilter()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z">
                        </path>
                    </svg>
                </i>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" multiTemplateDataRows matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Project ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    @if (expandedElement === element) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    } @else {
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    }
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="element-description">
                        
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Last Changed</b>
                                    </td>
                                    <td style="padding-left: 10px;">
                                        {{element.changeDate}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>In Workflow</b>
                                    </td>
                                    <td style="padding-left: 10px;">
                                        {{element.inWorkflow}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Project Manager</b>

                                    </td>
                                    <td style="padding-left: 10px;">
                                        {{element.pmName}} {{element.pmSurname}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Project Manager Email</b>
                                    </td>
                                    <td style="padding-left: 10px;">
                                        {{element.pmEmail}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
        </ng-container>


    </table>
    <mat-card *ngIf="projectlistLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);"
                    [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
                    aria-label="Select page"></mat-paginator>
</div>