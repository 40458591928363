<h1 mat-dialog-title>Our POPIA Statement</h1>
<div mat-dialog-content class="popiaPopup">
    <table class="popiaTable">
        <tbody>
          <tr><td class="popiaTable">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td  >Which data we gather:</td></tr>
          <tr><td></td><td  >- Name</td></tr>
          <tr><td></td><td  >- Surname</td></tr>
          <tr><td></td><td  >- Company name</td></tr>
          <tr><td></td><td  >- Email address</td></tr>
          <tr><td></td><td  >- Client Elastic Search URL</td></tr>
          <tr><td></td><td  >- Client S3 Bucket (Storage) name</td></tr>
          <tr><td></td><td  > </td></tr>
          <tr><td class="popiaTable">2.</td><td  >Which data we access:</td></tr>
          <tr><td></td><td  >Data in the client S3 (storage) bucket.</td></tr>
          <tr><td></td><td  >Data in the client Elastic Search instance.</td></tr>
          <tr><td></td><td  ></td></tr>
          <tr><td class="popiaTable">3.</td><td  >Although the system has acces to the data in the client S3 bucket we are not the owners of the data and we are not responsible for the data security outside this portal, or access to the data.</td></tr>
          <tr><td class="popiaTable">4.</td><td  >We, as Communication Genetics, will under no circumstances divulge any PI (Personal Information) to any third party, whether it is the gathered information or data to which the system has access to.</td></tr>
        </tbody>
      </table>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</div>