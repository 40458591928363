import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { LoggingInComponent } from './logging-in/logging-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NavigatingComponent } from './navigating/navigating.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { UsageStatsComponent } from './usage-stats/usage-stats.component';
import { DocArchiveComponent } from './doc-archive/doc-archive.component';
import { SubmitJobsComponent } from './submit-jobs/submit-jobs.component';
import { JobStatusComponent } from './job-status/job-status.component';
import { InteractiveComponent } from './interactive/interactive.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { WebServicesComponent } from './web-services/web-services.component';
import { S3BucketsComponent } from './s3-buckets/s3-buckets.component';
import { ElasticSearchComponent } from './elastic-search/elastic-search.component';

enum pages {
  loggingIn,
  forgotPassword,
  navigating,
  dateTime,
  usageStats,
  docArchive,
  submitJobs,
  jobStatus,
  interactiveDocument,
  reports,
  settings,
  webServices,
  s3Buckets,
  elasticSearch
}

@Component({
  selector: 'app-help-documentation',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    LoggingInComponent,
    ForgotPasswordComponent,
    NavigatingComponent,
    DateTimeComponent,
    UsageStatsComponent,
    DocArchiveComponent,
    SubmitJobsComponent,
    JobStatusComponent,
    InteractiveComponent,
    ReportsComponent,
    SettingsComponent,
    WebServicesComponent,
    S3BucketsComponent,
    ElasticSearchComponent,
  ],
  templateUrl: './help-documentation.component.html',
  styleUrl: './help-documentation.component.scss'
})
export class HelpDocumentationComponent implements OnInit {

  currentPage: pages = pages.loggingIn;
  
  loggingInLink = false;
  forgotPasswordLink = false;
  navigatingLink = false;
  dateTimeLink = false;
  usageStatsLink = false;
  docArchiveLink = false;
  submitJobsLink = false;
  jobStatusLink = false;
  interactiveDocumentLink = false;
  reportsLink = false;
  settingsLink = false;
  webServicesLink = false;
  s3BucketsLink = false;
  elasticSearchLink = false;

  ngOnInit(): void {
    this.navigate(pages.loggingIn);
  }

  navigate(to: pages){
    console.log(to);
    this.loggingInLink = false;
    this.forgotPasswordLink = false;
    this.navigatingLink = false;
    this.dateTimeLink = false;
    this.usageStatsLink = false;
    this.docArchiveLink = false;
    this.submitJobsLink = false;
    this.jobStatusLink = false;
    this.interactiveDocumentLink = false;
    this.reportsLink = false;
    this.settingsLink = false;
    this.webServicesLink = false;
    this.s3BucketsLink = false;
    this.elasticSearchLink = false;
    switch(to){
      case pages.loggingIn: this.loggingInLink = true; break;
      case pages.forgotPassword: this.forgotPasswordLink = true; break;
      case pages.navigating: this.navigatingLink = true; break;
      case pages.dateTime: this.dateTimeLink = true; break;
      case pages.usageStats: this.usageStatsLink = true; break;
      case pages.docArchive: this.docArchiveLink = true; break;
      case pages.submitJobs: this.submitJobsLink = true; break;
      case pages.jobStatus: this.jobStatusLink = true; break;
      case pages.interactiveDocument: this.interactiveDocumentLink = true; break;
      case pages.reports: this.reportsLink = true; break;
      case pages.settings: this.settingsLink = true; break;
      case pages.webServices: this.webServicesLink = true; break;
      case pages.s3Buckets: this.s3BucketsLink = true; break;
      case pages.elasticSearch: this.elasticSearchLink = true; break;
    }
  }
}
