<div>Home</div>

<div *ngIf="isAuthenticated; else noAuth">
  <button (click)="logout()">Logout</button>
  <button (click)="refreshSession()">Refresh session</button>
  <hr />

  <br />

  Is Authenticated: {{ isAuthenticated }}

  <br />
  userData
  <pre>{{ userData$ | async | json }}</pre>

  <br />
</div>

<ng-template #noAuth>
  <button (click)="login()">Login</button>
  <hr />
</ng-template>

<!-- Configuration loaded:
<pre>{{ configuration$ | async | json }}</pre> -->