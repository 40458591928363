import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchiveComponent } from '../elasticsearch/archive/archive.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-archive',
  standalone: true,
  imports: [
    CommonModule,
    ArchiveComponent,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule
  ],
  templateUrl: './document-archive.component.html',
  styleUrl: './document-archive.component.scss'
})

export class DocumentArchiveComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer){}
  embedURL: SafeResourceUrl;
  iframeData = ``;
  tenants = [{id: '', Alf_Enable: false, ES_Enable: false, Alf_Embed: ''}];
  ES_Enable = false;
  Alf_Enable = false;
  showSelection = false;
  archiveType = new FormControl('alfresco');
  ngOnInit(): void {
    // console.log(this.archiveType);
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].Alf_Enable);
        // console.log(this.tenants[a].ES_Enable);
        // console.log(this.tenants[a].Alf_Embed);
        this.Alf_Enable = this.tenants[a].Alf_Enable;
        this.ES_Enable = this.tenants[a].ES_Enable;

        if(this.Alf_Enable && this.ES_Enable){
          this.showSelection = true;
        }
        else{
          if(this.Alf_Enable){
            this.archiveType = new FormControl('alfresco');
          }
          else if(this.ES_Enable){
            this.archiveType = new FormControl('elasticSearch');
          }
          else{
            this.archiveType = new FormControl('');
          }
        }
        // console.log("Selection: "+this.showSelection)
        this.embedURL =this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].Alf_Embed);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="`+this.embedURL+`" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
  }
}
