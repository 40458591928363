import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-process-services',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './process-services.component.html',
  styleUrl: './process-services.component.scss'
})
export class ProcessServicesComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer){}
  embedURL: SafeResourceUrl;
  iframeData = ``;
  tenants = [{id: '', Alf_Proc_Embed: ''}];
  ngOnInit(): void {
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].Alf_Proc_Embed);
        this.embedURL =this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].Alf_Proc_Embed);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="`+this.embedURL+`" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
  }
}
