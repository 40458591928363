<div style="user-select: none;">
    <h1 mat-dialog-title><b>Edit Workflow</b></h1>
    <div mat-dialog-content>
        <div class="flexRow">
            <div>
                <div class="flexRow"><p class="itemHeading">Name*</p>&nbsp;<p *ngIf="emptyName" style="color: red; font-size: 9px;">Please provide a name</p></div>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;"
                    [(ngModel)]="data.workflowName" cdkFocusInitial (keyup)="checkName()">
            </div>
            <div style="width: 20px;"></div>
            <div>
                <p class="itemHeading">Description</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;"
                    [(ngModel)]="data.workflowDescription">
            </div>
        </div>
        <div *ngIf="poolsLoading" style="display: flex; justify-content: center; height: 202px;">
            <mat-spinner></mat-spinner>
        </div>
        <div class="flexRow" style="width: 100%;" *ngIf="!poolsLoading">

            <div class="poolWindow">
                <p class="poolHeading">Available pools</p>

                <cdk-virtual-scroll-viewport appendOnly itemSize="10" class="wf-viewport">
                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="availablePools"
                        [cdkDropListConnectedTo]="[doneList]" class="workflow-list" (cdkDropListDropped)="drop($event)">
                        @for (item of availablePools; track item) {
                        <div class="workflow-box" cdkDrag >
                            <p class="wfItemMain">{{item.Name}}</p>
                            <p class="wfItemSub">{{item.Description}}</p>
                        </div>
                        }
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            <div style="width: 20px;"></div>
            <div class="poolWindow">
                <div class="flexRow"><p class="poolHeading">Workflow Steps</p>&nbsp;<p *ngIf="emptyWorkflow" style="color: red; font-size: 9px;">One or more steps required</p></div>
                <cdk-virtual-scroll-viewport appendOnly itemSize="10" class="wf-viewport">
                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="data.wfPools"
                        [cdkDropListConnectedTo]="[todoList]" class="workflow-list" (cdkDropListDropped)="drop($event)">
                        @for (item of data.wfPools; track item) {
                        <div class="workflow-box" cdkDrag>
                            <p class="wfItemMain">{{item.Name}}</p>
                            <p class="wfItemSub">{{item.Description}}</p>
                        </div>
                        }
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>

    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="secondary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="save()" >Update</button>
    </div>
</div>