import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { FormsModule } from '@angular/forms';

export interface projectTable {
  id: string;
  name: string;
  description: string;
  changeDate: string;
  inWorkflow: boolean;
  pmName: string;
  pmSurname: string;
  pmEmail: string;
}


@Component({
  selector: 'app-template-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  templateUrl: './template-settings.component.html',
  styleUrl: './template-settings.component.scss'
})
export class TemplateSettingsComponent implements OnInit {

  constructor(public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatTable) table: MatTable<projectTable>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'name', 'expand'];
  projectList: projectTable[] = [];
  dataSource = new MatTableDataSource(this.projectList);
  projectlistLoading = false;
  expandedElement: projectTable | null;
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  filterValue = '';

  ngOnInit(): void {
    this.getProjectsList();
  }

  getProjectsList() {
    this.projectList = [];
    this.projectlistLoading = true;
    this.api.postService("/sc/listprojects", { tenantID: localStorage.getItem('currentTenant') })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          response.data.forEach((element: any) => {

            var elementItem = {
              id: element.id,
              name: element.name,
              description: element.description,
              changeDate: new Date(element.changeDateTime).toDateString() + " " + new Date(element.changeDateTime).toTimeString(),
              inWorkflow: element.inWorkflow,
              pmName: element.projectManager.forename,
              pmSurname: element.projectManager.surname,
              pmEmail: element.projectManager.emailAddress
            };
            // console.log(elementItem);
            this.projectList.push(elementItem);
          });
          this.dataSource = new MatTableDataSource(this.projectList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.table.renderRows();
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Projects", "Could not get projects.");
        }
        this.projectlistLoading = false;
      })
      .catch((err) => {
        // console.log(err);

        this.projectlistLoading = false;
      });
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }

}
