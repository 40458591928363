<div class="flexRow" style="user-select: none;">
    <mat-form-field appearance="fill">
        <mat-label>Date filter</mat-label>
        <input matInput placeholder="Today" readonly="true" [(ngModel)]="timeframe">
        <button matSuffix mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            style="color: #686868;">
            <!-- <mat-icon>arrow_drop_down</mat-icon> -->
            <svg viewBox="0 0 24 24" width="24px" height="24px" fill="currentColor" focusable="false" aria-hidden="true"
                class="mat-datepicker-toggle-default-icon ng-star-inserted">
                <path
                    d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                </path>
            </svg>
        </button>
    </mat-form-field>
    <mat-menu #menu="matMenu" hasBackdrop="true" class="advDateManu">
        <div (click)="$event.stopPropagation()" style="padding: 10px; user-select: none;">
            <p style="color: #021857; font-weight: bold; padding-bottom: 10px; font-size: 18px;">Date Control
            </p>
            <mat-button-toggle-group name="timeMenuOption" aria-label="" [(ngModel)]="timeMenuOption"
                style="width: 100%;">
                <mat-button-toggle value="Fixed" style="padding: 0px; width: 25%; font-size: 13px;">Fixed
                    Date</mat-button-toggle>
                <mat-button-toggle value="Range" style="padding: 0px; width: 25%; font-size: 13px;">Date
                    Range</mat-button-toggle>
                <mat-button-toggle value="Relative" style="padding: 0px; width: 25%; font-size: 13px;">Relative
                    Date</mat-button-toggle>
                <mat-button-toggle value="Predefined"
                    style="padding: 0px; width: 25%; font-size: 13px;">Predefined</mat-button-toggle>
            </mat-button-toggle-group>
            <div *ngIf="timeMenuOption == 'Relative'" style="user-select: none;">
                <mat-card style="padding: 5px; height: 441px;">
                    <p style="padding-top: 10px; font-weight: 500;">Offset</p>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="timeOffset" type="number" min="1" (change)="setDate(false)">
                    </mat-form-field>
                    <p style="padding-top: 6px;  font-weight: 500;">Units</p>
                    <mat-button-toggle-group name="timeMenuOptionPeriod" aria-label=""
                        [(ngModel)]="timeMenuOptionPeriod" (change)="setDate(false)">
                        <mat-button-toggle value="Day" style="padding: 0px; width: 33%;">Day(s)</mat-button-toggle>
                        <mat-button-toggle value="Week" style="padding: 0px; width: 33%;">Week(s)</mat-button-toggle>
                        <mat-button-toggle value="Month" style="padding: 0px; width: 34%;">Month(s)</mat-button-toggle>
                    </mat-button-toggle-group>
                    <br />
                    <br />
                    <p><b>Start Date:</b>&nbsp;&nbsp;&nbsp;&nbsp;{{timeFrom.toLocaleDateString()}}
                        {{timeFrom.toLocaleTimeString()}}</p>
                    <p><b>End Date:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{timeTo.toLocaleDateString()}}
                        {{timeTo.toLocaleTimeString()}}</p>
                </mat-card>
                <div style="padding-top: 10px; display: flex; flex-direction: row; justify-content: end;">
                    <button class="blueButton" (click)="setDate(true); menuTrigger.closeMenu()">Set Relative
                        Date</button>
                </div>
            </div>
            <div *ngIf="timeMenuOption == 'Fixed'" style="user-select: none;">
                <mat-card style="height: 441px;">
                    <mat-calendar [(selected)]="selectedFixedDate"></mat-calendar>
                </mat-card>
                <div style="padding-top: 10px; display: flex; flex-direction: row; justify-content: space-between;">
                    <button class="blueButton" (click)="setDateToday();">Today</button>
                    <button class="blueButton" (click)="setDate(true); menuTrigger.closeMenu()">Set Fixed Date</button>
                </div>
            </div>
            <div *ngIf="timeMenuOption == 'Range'" style="user-select: none;">
                <mat-card style="height: 441px;">
                    <mat-calendar [selected]="rangeDate" (selectedChange)="rangeChange($event)"></mat-calendar>
                </mat-card>
                <div style="padding-top: 10px; display: flex; flex-direction: row; justify-content: end;">
                    <button class="blueButton" (click)="setDate(true); menuTrigger.closeMenu()">Set Date Range</button>
                </div>
            </div>
            <div *ngIf="timeMenuOption == 'Predefined'" style="user-select: none;">
                <mat-card style="padding: 5px; height: 441px;">
                    <!-- <mat-calendar [selected]="rangeDate" (selectedChange)="rangeChange($event)"></mat-calendar> -->
                    <br />
                    <div style="display: flex; flex-direction: row; margin-bottom: 5px;">
                        <button mat-flat-button [color]="predefinedTime=='today'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predToday()">Today</button>
                        <button mat-flat-button [color]="predefinedTime=='yesterday'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predYesterday()">Yesterday</button>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-bottom: 5px;">
                        <button mat-flat-button [color]="predefinedTime=='thisweek'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predThisWeek()">This
                            Week</button>
                        <button mat-flat-button [color]="predefinedTime=='oneweek'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predOneWeek()">One
                            Week</button>
                        <button mat-flat-button [color]="predefinedTime=='lastweek'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predLastWeek()">Last
                            Week</button>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-bottom: 5px;">
                        <button mat-flat-button [color]="predefinedTime=='thismonth'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predThisMonth()">This
                            Month</button>
                        <button mat-flat-button [color]="predefinedTime=='lastmonth'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predLastMonth()">Last
                            Month</button>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-bottom: 5px;">
                        <button mat-flat-button [color]="predefinedTime=='thisyear'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predThisYear()">This
                            Year</button>
                        <button mat-flat-button [color]="predefinedTime=='lastyear'?'accent':'primary'"
                            style="margin-bottom: 2px; margin-right: 2px;" (click)="predLastYear()">Last
                            Year</button>
                    </div>
                    <br />
                    <br />
                    <p><b>Start Date:</b>&nbsp;&nbsp;&nbsp;&nbsp;{{timeFrom.toLocaleDateString()}}
                        {{timeFrom.toLocaleTimeString()}}</p>
                    <p><b>End Date:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{timeTo.toLocaleDateString()}}
                        {{timeTo.toLocaleTimeString()}}</p>
                </mat-card>
                <div style="padding-top: 10px; display: flex; flex-direction: row; justify-content: end;">
                    <button class="blueButton" (click)="setDate(true); menuTrigger.closeMenu()">Set Predefined</button>
                </div>
            </div>
        </div>


    </mat-menu>

</div>