import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

export interface dirTable {
  directoryName: string;
  dataBucket: string;
  directory: string;
  action: string;
  id: string;
}

export interface addDirDialogData {
  directoryName: string;
  dataBucket: string;
  directory: string;
}



@Component({
  selector: 'app-interactive-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
  ],
  templateUrl: './interactive-settings.component.html',
  styleUrl: './interactive-settings.component.scss'
})
export class InteractiveSettingsComponent implements OnInit {

  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatTable) table: MatTable<dirTable>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['directoryName', 'dataBucket', 'directory', 'action'];
  dirList: dirTable[] = [];
  dataSource = new MatTableDataSource(this.dirList);
  dirlistLoading = false;
  filterValue = '';

  ngOnInit(): void {
    this.getDirList();
  }

  getDirList() {
    this.dirList = []
    this.dirlistLoading = true;
    this.api.postService("/settings/listdirectoriesfortenant", { tenantID: localStorage.getItem('currentTenant') })
      .then((response) => {
        this.dirlistLoading = false;
        // console.log(response);
        if (response.status == 200) {
          response.data.forEach((element: { DirName: { S: any; }; bucket: { S: any; }; path: { S: any; }; id: { S: any; }; }) => {
            this.dirList.push({
              directoryName: element.DirName.S,
              dataBucket: element.bucket.S,
              directory: element.path.S,
              action: "button",
              id: element.id.S
            });
            // console.log(this.dirList);
          });
          this.dataSource = new MatTableDataSource(this.dirList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // this.table.renderRows();
        }
        else{
          this.toastTemplates.showErrorToast("API error - Get Directories", "Could not get Directories.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Directories", err);
        this.dirlistLoading = false;
      });
  }

  openAddDir() {

    const dialogRef = this.dialog.open(AddDirDialog, {
      data: { directoryName: "", dataBucket: "", directory: "" },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.dirlistLoading = true;
        var request = {
          TenantID: localStorage.getItem('currentTenant'),
          DirName: result.directoryName,
          bucket: result.dataBucket,
          path: result.directory
        }
        // console.log(request);
        this.api.postService("/settings/adddirtotenant", request)
          .then((response) => {
            this.dirlistLoading = false;
            if(response.status == 200){
              if(response.data.statusCode != 200){
                this.toastTemplates.showErrorToast("API error - Add Directory", "Could not add Directory to tenant.");
              }
            }
            else{
              this.toastTemplates.showErrorToast("API error - Add Directory", "Could not add Directory to tenant.");
            }
            this.getDirList();
          })
          .catch((err) => {
            this.dirlistLoading = false;
            // console.log(err);
          });
      }
    });
  }

  deleteQueue(id: string) {
    this.dirlistLoading = true;
    var request = {
      TenantID: localStorage.getItem('currentTenant'),
      DirID: id
    }
    this.api.postService("/settings/deletedirfromtenant", request)
      .then((response) => {
        if(response.status == 200){
          if(response.data.statusCode != 200){
            this.toastTemplates.showErrorToast("API error - Delete Directory", "Could not delete Directory from tenant.");
          }
        }
        else{
          this.toastTemplates.showErrorToast("API error - Delete Directory", "Could not delete Directory from tenant.");
        }
        this.dirlistLoading = false;
        this.getDirList();
      })
      .catch((err) => {
        this.dirlistLoading = false;
        this.toastTemplates.showErrorToast("API error - Delete Directory", err);
        // console.log(err);
      })
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }

}


@Component({
  selector: 'add-dir-dialog',
  templateUrl: 'add-dir-dialog.html',
  styleUrl: './interactive-settings.component.scss',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, FormsModule,],
})
export class AddDirDialog {
  constructor(public api: WebMethodsService, public dialogRef: MatDialogRef<AddDirDialog>, @Inject(MAT_DIALOG_DATA) public data: addDirDialogData,) { }
  closeDialog() {
    this.dialogRef.close();
  }
}