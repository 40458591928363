<div style="padding: 20px; user-select: none;">
    <div class="divHeaderLine">
        <h3>Drafting</h3>

        <div *ngIf="state == enum.inDraft">
            <div style="display: flex; align-items: center; color: #021857; font-weight: bold; font-size: 32px;">
                <p>{{taskName}}</p>
            </div>
        </div>
        <div *ngIf="state == enum.end">
            <div style="display: flex; align-items: center; color: #021857; font-weight: bold; font-size: 32px;">
                <p>{{taskName}}</p>
            </div>
        </div>

        <div class="divHeaderLine">
            <!-- {{taskID | json}} -->
            <!-- {{workflow}} -->
            <div *ngIf="state == enum.inDraft">
                <button class="blueButton" (click)="cancelDraft()">
                    Cancel
                </button>
                <button class="blueButton" (click)="saveDraft(false, false)">
                    Save Draft
                </button>
                <button class="blueButton" (click)="saveDraft(true, true)" *ngIf="workflow != undefined">
                    Submit to Workflow
                </button>
                <button class="blueButton" (click)="completeDraft()" *ngIf="workflow == undefined">
                    Complete Draft
                </button>
            </div>
            <div *ngIf="state == enum.end">
                <button class="blueButton" (click)="sendEmail()" [disabled]="sendLoading">
                    Send Email
                </button>
                <button class="blueButton" (click)=" reworkDraft()">
                    Rework Draft
                </button>
                <button class="blueButton" (click)="saveDraft(true, false)">
                    Done
                </button>
            </div>
        </div>
    </div>
    <br>

    <div *ngIf="state==enum.start" class="rowFlex" style="background-color: #f3f3f3; height: calc(100vh - 175px);">
        <div class="colFlex" style="background-color: #ffffff; padding-right: 20px; width: 450px;">
            <p class="subHeading">1. Select Directory</p>
            <br>
            <mat-form-field style="width: 100%;">
                <mat-label>Directory Name</mat-label>
                <mat-select [disabled]="directoryLoading" (selectionChange)="getFiles($event.value)">
                    @for (item of directories; track item) {
                    <mat-option [value]="item">{{item.DirName.S}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="directoryLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
        </div>
        <div style="background-color: #ffffff; padding-left: 20px; padding-right: 20px; width: 100%;">
            <p class="subHeading">2. Choose File</p>
            <br>
            <mat-card>
                <div *ngIf="dirFiles.length==0" style="padding: 10px;">
                    <p>No files available.</p>
                </div>
                <mat-selection-list *ngIf="dirFiles.length>0" [multiple]="false" (selectionChange)="selectFile($event)">
                    @for (file of dirFiles; track file) {
                    <mat-list-option [value]="file">
                        <button mat-mini-fab color="primary" style="width: 30px !important; height: 30px !important; margin: 10px;" (click)="viewFileInformation(file)"><svg
                                stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                                </path>
                            </svg>
                        </button>{{file.label}}</mat-list-option>
                    }
                </mat-selection-list>
                <mat-progress-bar *ngIf="dirFilesLoading" mode="indeterminate"></mat-progress-bar>
            </mat-card>
        </div>
        <div style="background-color: #ffffff; padding-left: 20px; width: 200px;">
            <p class="subHeading">3. Create Draft</p>
            <br>
            <button class="blueButton" [disabled]="selectedDirFile==null" (click)="loadData()">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true"
                    height="40px" width="40px" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
                        clip-rule="evenodd"></path>
                    <path fill-rule="evenodd"
                        d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
                        clip-rule="evenodd"></path>
                </svg><br><br>Create Draft</button>
        </div>
    </div>

    <div *ngIf="state==enum.loading"
        style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 240px); align-items: center;">
        <p class="loadingText">Draft Loading</p>
        <br>
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="state==enum.save"
        style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 240px); align-items: center;">
        <p class="loadingText">Saving Draft</p>
        <br>
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="state==enum.completing"
        style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 240px); align-items: center;">
        <p class="loadingText">Processing documents</p>
        <br>
        <mat-spinner></mat-spinner>
    </div>

    <div [ngStyle]="{'display': state==enum.inDraft? 'block': 'none'}">
        <div class="rowFlex">
            <mat-drawer-container style="background-color: rgb(230, 230, 230); width: 100%;" autosize>
                <mat-drawer #drawer mode="side" style="background-color: rgb(230, 230, 230); width: 350px;"
                    [opened]="commentsNav" position="end">
                    <div style="width: 100%; display: flex; justify-content: center; padding-top: 15px; user-select: none;"
                        *ngIf="steps.length == 0">
                        <p class="cardCommentHeader">No steps in this task.</p>
                    </div>
                    @for (step of steps; track $index) {
                    <div style="padding: 10px;">
                        <mat-card>
                            <mat-card-content>
                                <div class="flexRow flexJustify">
                                    <div>
                                        <p class="cardHeader">{{step.Pool}}</p>
                                    </div>
                                    <div>
                                        <div class="flexRow" style="color: green;" *ngIf="step.Action=='Approved'">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                viewBox="0 0 512 512" height="10px" width="10px"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                                                </path>
                                            </svg>
                                            <p class="cardHeader" style="color: green;">{{step.Action}}</p>
                                        </div>
                                        <div class="flexRow" style="color: red;" *ngIf="step.Action=='Rejected'">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                viewBox="0 0 352 512" height="10px" width="10px"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                                </path>
                                            </svg>
                                            <p class="cardHeader" style="color: red;">{{step.Action}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="cardCommentHeader">Comments:</p>
                                    <div class="commentBox">
                                        <cdk-virtual-scroll-viewport itemSize="50" class="commentBoxScroll">
                                            <p class="cardCommentBody">{{step.Comment}}</p>
                                        </cdk-virtual-scroll-viewport>

                                    </div>
                                </div>
                                <div class="flexRow flexJustify" style="padding-top: 2px;">
                                    <div>
                                        <p class="cardFooterLeft">{{step.User}}</p>
                                    </div>
                                    <div>
                                        <p class="cardFooterRight">{{step.DateTime}}</p>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    }
                </mat-drawer>
                <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px; box-shadow: 0px 0px 2px 2px grey;">
                    <iframe name="draftEditorWindow" id="draftEditorWindow" allow="clipboard-read; clipboard-write"
                        width='100%' style="height: calc(100vh - 198px);" [frameBorder]="0"></iframe>
                </div>
            </mat-drawer-container>
            <div style="width: 30px; padding-top: 5px;">
                <mat-card class="commentNavButton" style="width: 30px; height: 100%;" (click)="openComments()">
                    <mat-card-content style="height: 100%; padding: 0px;">
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: space-between; width: 30px; height: 100%;">
                            <div style="height: 50px; color: #777777;">
                                <svg *ngIf="!commentsNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                                    viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path
                                        d="M11 17h10v-2H11v2zm-8-5 4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                                    </path>
                                </svg>
                                <svg *ngIf="commentsNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                                    viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path
                                        d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                                    </path>
                                </svg>
                            </div>
                            <div style="width: 30px; rotate: -90deg;">
                                <p
                                    style="font-weight: bold; width: 100px;  font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #777777;">
                                    Comments</p>
                            </div>
                            <div style="height: 50px;"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div *ngIf="state==enum.end">
        <mat-form-field style="width: 100%;">
            <mat-label>Email Address</mat-label>
            <input matInput [(ngModel)]="toEmail">
            <mat-progress-bar *ngIf="sendLoading" mode="indeterminate"></mat-progress-bar>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Email Subject</mat-label>
            <input matInput [(ngModel)]="toSubject">

        </mat-form-field>
        <table mat-table [dataSource]="finalDocuments" class="mat-elevation-z8">
            <ng-container matColumnDef="docName">
                <th mat-header-cell *matHeaderCellDef> Document Name </th>
                <td mat-cell *matCellDef="let element"> {{element.documentName}} </td>
            </ng-container>
            <ng-container matColumnDef="channel">
                <th mat-header-cell *matHeaderCellDef> Channel </th>
                <td mat-cell *matCellDef="let element"> {{element.channelName}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="primary" aria-label="User Details" (click)="viewDocument(element)">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                            </path>
                        </svg>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <!-- <iframe style="display: none;" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" [src]="embedURL"
        allow="clipboard-read; clipboard-write" width='100%'></iframe> -->
</div>