<div class="mainDiv">
    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <button class="blueButton" style="margin-bottom: 15px;" (click)="addUser()">
            <div style="display: flex; flex-direction: row; align-items: baseline; gap: 10px;">
                <div style="height: 20px;">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15px"
                        width="15px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18 15L17.999 18H21V20H17.999L18 23H16L15.999 20H13V18H15.999L16 15H18ZM11 18V20H3V18H11ZM21 11V13H3V11H21ZM21 4V6H3V4H21Z">
                        </path>
                    </svg>
                </div>
                <div style="height: 15px;">
                    <p>Add User</p>
                </div>
            </div>
        </button>
        <div
            style="margin-top: 10px; margin-bottom: 5px; margin-left: 10px; display: flex; flex-direction: row; justify-content: flex-start;">
            <div class="input-icons">
                <i class="icon">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </i>
                <input class="filterInput" [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Find User"
                    #input>
                <i class=" icon iconRight" (click)="clearFilter()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z">
                        </path>
                    </svg>
                </i>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="RequiredActions">
            <th mat-header-cell *matHeaderCellDef> Required Actions </th>
            <td mat-cell *matCellDef="let element">
                <mat-chip-listbox aria-label="Required Actions"></mat-chip-listbox>
                @for(item of element.requiredActions; track item){
                <mat-chip style="background-color: #5B9BD5; color: white !important;">
                    <!-- <p style="color: white;">{{item}}</p> -->
                    <p style="color: white;">{{getValue(item)}}</p>
                </mat-chip>
                &nbsp;
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.enabled" class="flexCenter" matTooltip="User Inactive" matTooltipPosition="above">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="30px"
                        width="30px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M633.8 458.1L389.6 269.3C433.8 244.7 464 198.1 464 144 464 64.5 399.5 0 320 0c-67.1 0-123 46.1-139 108.2L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM198.4 320C124.2 320 64 380.2 64 454.4v9.6c0 26.5 21.5 48 48 48h382.2L245.8 320h-47.4z">
                        </path>
                    </svg>
                </div>
                <div *ngIf="element.enabled" class="flexCenter" matTooltip="User Active" matTooltipPosition="above">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="30px"
                        width="30px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z">
                        </path>
                    </svg>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="ViewUser">
            <th mat-header-cell *matHeaderCellDef> View User </th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab color="primary" aria-label="User Details" (click)="viewUser(element)">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-card *ngIf="usersLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>

<!-- {{usersTable | json}} -->