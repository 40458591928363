import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

@Component({
  selector: 'app-permissions',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './permissions.component.html',
  styleUrl: './permissions.component.scss'
})
export class PermissionsComponent implements OnInit {
  constructor(public api: WebMethodsService, private toastTemplates: NgbdToastGlobal){}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['GroupName', 'Description'];
  permissions: any = [];
  dataSource = new MatTableDataSource(this.permissions);
  permissionsLoading = false;

  ngOnInit(): void {
    this.permissionsLoading = true;
    this.api.postService("/um/getgroups",{"none": ""})
    .then((response) => {
      this.permissionsLoading = false;
      // console.log(response);
      if(response.status == 200){
        if(response.data.statusCode == 200){
          // console.log(JSON.parse(response.data.body).Groups);
          this.permissions = JSON.parse(response.data.body).Groups;
          this.dataSource = new MatTableDataSource(this.permissions);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else{
          this.toastTemplates.showErrorToast("API error - Get Permissions", "Could not get permissions.");
        }
      }
      else{
        this.toastTemplates.showErrorToast("API error - Get Permissions", "Could not get permissions.");
      }
    })
    .catch((err) => {
      // console.log(err);
      this.toastTemplates.showErrorToast("API error - Get Permissions", err);
      this.permissionsLoading = false;
    });
  }

}
