import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

export interface DialogData {
  id: string;
  extId: string;
  name: string;
  endpoint: string;
  userId: string;
  password: string;
  clientId: string;
  clientSecret: string;
  esDomain: string;
  esIndex: string;
  s3RoleARN: string;
}

@Component({
  selector: 'app-tenants',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './tenants.component.html',
  styleUrl: './tenants.component.scss'
})
export class TenantsComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tenantTable: [any]=[{}];
  dataSource = new MatTableDataSource(this.tenantTable);
  tenantsLoading = false;
  displayedColumns: string[] = ['id', 'SC_ExternalID', 'Name', 'action'];
  filterValue = '';
  

  ngOnInit(): void {
    this.getTenants();
    
  }
  getTenants() {
    this.tenantsLoading = true;
    this.api.postService("/um/tenantperuser", { Username: "#" })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          this.tenantTable = response.data.data.listTenantss.items;
          this.dataSource = new MatTableDataSource(this.tenantTable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.filterPredicate = function(data, filter: string): boolean {
            return data.Name.toLowerCase().includes(filter);
          };
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tenants", "Could not get tenants.");
        }
        this.tenantsLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Tenants", err);
        this.tenantsLoading = false;
      });
  }

  viewTenant(data: DialogData) {
    const dialogRef = this.dialog.open(ViewTenantDialog, { width: 'fit-content', data: { addTenant: false, tenantInfo: data } });
    dialogRef.afterClosed().subscribe((results) => {
      // console.log(results);
      this.getTenants();
    });
  }


  addTenant() {
    const dialogRef = this.dialog.open(ViewTenantDialog, { width: 'fit-content', data: { addTenant: true, tenantInfo: null } });
    dialogRef.afterClosed().subscribe((results) => {
      // console.log(results);
      this.getTenants();
    });
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}

@Component({
  selector: 'view-tenant-dialog',
  templateUrl: 'view-tenant-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    MatCardModule,
    MatProgressBarModule,
    MatCheckboxModule
  ],
  styleUrl: './tenants.component.scss'
})
export class ViewTenantDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<ViewTenantDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  tID = "";
  Name = "";
  SC_ExternalID = "";
  Endpoint = "";
  UserID = "";
  Password = "";
  ClientID = "";
  ClientSecret = "";
  EmbedURL = "";
  ES_Enable = false;
  ES_Domain = "";
  ES_Index = "";
  S3_Role = "";
  Alf_Enable = false;
  Alf_Embed = "";
  Alf_Proc_Embed = "";
  Alf_Proc_Enable = false;
  errorMessage = "";
  tenantLoading = false;

  ngOnInit(): void {
    // console.log(this.data);
    if (this.data.tenantInfo == null) {
      // console.log("No data in payload");
    }
    else {
      // console.log("Valid data in payload");
      this.tID = this.data.tenantInfo.id;
      this.Name = this.data.tenantInfo.Name;
      this.SC_ExternalID = this.data.tenantInfo.SC_ExternalID;
      this.Endpoint = this.data.tenantInfo.Endpoint;
      this.UserID = this.data.tenantInfo.UserID;
      this.ClientID = this.data.tenantInfo.ClientID;
      this.ClientSecret = this.data.tenantInfo.ClientSecret;
      this.EmbedURL = this.data.tenantInfo.EmbedURL;
      this.ES_Enable = this.data.tenantInfo.ES_Enable;
      this.ES_Domain = this.data.tenantInfo.ES_Domain;
      this.ES_Index = this.data.tenantInfo.ES_Index;
      this.S3_Role = this.data.tenantInfo.S3_Role;
      this.Alf_Enable = this.data.tenantInfo.Alf_Enable;
      this.Alf_Embed = this.data.tenantInfo.Alf_Embed;
      this.Alf_Proc_Embed = this.data.tenantInfo.Alf_Proc_Embed;
      this.Alf_Proc_Enable = this.data.tenantInfo.Alf_Proc_Enable;
    }
  }
  addTenant() {
    if (
      this.Name === "" ||
      this.SC_ExternalID === "" ||
      this.Endpoint === "" ||
      this.UserID === "" ||
      this.Password === "" ||
      this.ClientID === "" ||
      this.ClientSecret === "" ||
      this.EmbedURL === "" ||
      (this.ES_Domain === "" && this.ES_Enable) ||
      (this.ES_Index === "" && this.ES_Enable) ||
      (this.S3_Role === "" && this.ES_Enable) ||
      (this.Alf_Embed === "" && this.Alf_Enable) ||
      (this.Alf_Proc_Embed === "" && this.Alf_Proc_Enable)
    ) {
      this.errorMessage = "Please complete all fields";
    }
    else {
      this.tenantLoading = true;
      var request = {
        TenantID: this.SC_ExternalID,
        TenantName: this.Name,
        TenantEndpoint: this.Endpoint,
        TenantUserID: this.UserID,
        TenantPassword: this.Password,
        TenantClientID: this.ClientID,
        TenantClientSecret: this.ClientSecret,
        tenantEmbedEndpoint: this.EmbedURL,
        TenantESDomain: this.ES_Domain,
        TenantESIndex: this.ES_Index,
        TenantS3Role: this.S3_Role,
        TenantESEnable: this.ES_Enable,
        TenantAlfEnable: this.Alf_Enable,
        TenantAlfEmbed: this.Alf_Embed,
        TenantAlfProcEmbed: this.Alf_Proc_Embed,
        TenantAlfProcEnable: this.Alf_Proc_Enable
      }

      this.api.postService("/um/createtenant", request)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
            if (response.data.statusCode == 200) {
              this.dialogRef.close();
            }
            else {
              this.errorMessage = "Error adding tenant.";
            }
          }
          else {
            this.errorMessage = "Error adding tenant.";
          }
          this.tenantLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Add Tenant", err);
          this.tenantLoading = false;
        })
    }

  }
  updateTenant() {
    if (
      this.Name === "" ||
      this.SC_ExternalID === "" ||
      this.Endpoint === "" ||
      this.UserID === "" ||
      this.ClientID === "" ||
      this.ClientSecret === "" ||
      this.EmbedURL === "" ||
      (this.ES_Domain === "" && this.ES_Enable) ||
      (this.ES_Index === "" && this.ES_Enable) ||
      (this.S3_Role === "" && this.ES_Enable) ||
      (this.Alf_Embed === "" && this.Alf_Enable) ||
      (this.Alf_Proc_Embed === "" && this.Alf_Proc_Enable)
    ) {
      this.errorMessage = "Please complete all fields";
    }
    else {
      this.tenantLoading = true;
      var request = {
        ID: this.tID,
        TenantID: this.SC_ExternalID,
        TenantName: this.Name,
        TenantEndpoint: this.Endpoint,
        TenantUserID: this.UserID,
        TenantPassword: this.Password,
        TenantClientID: this.ClientID,
        TenantClientSecret: this.ClientSecret,
        tenantEmbedEndpoint: this.EmbedURL,
        TenantESDomain: this.ES_Domain,
        TenantESIndex: this.ES_Index,
        TenantS3Role: this.S3_Role,
        TenantESEnable: this.ES_Enable,
        TenantAlfEnable: this.Alf_Enable,
        TenantAlfEmbed: this.Alf_Embed,
        TenantAlfProcEmbed: this.Alf_Proc_Embed,
        TenantAlfProcEnable: this.Alf_Proc_Enable
      }

      this.api.postService("/um/updatetenant", request)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
            if (response.data.statusCode == 200) {
              this.dialogRef.close();
            }
            else {
              this.errorMessage = "Error updating tenant.";
            }
          }
          else {
            this.errorMessage = "Error updating tenant.";
          }
          this.tenantLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Update Tenant", err);
          this.tenantLoading = false;
        })
    }
  }
}
