import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { QueueSettingsComponent } from './queue-settings/queue-settings.component';
import { InteractiveSettingsComponent } from './interactive-settings/interactive-settings.component';
import { TemplateSettingsComponent } from './template-settings/template-settings.component';
import { ApiSettingsComponent } from './api-settings/api-settings.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { MatCardModule } from '@angular/material/card';
import { UsersComponent } from './users/users.component';
import { GroupsComponent } from './groups/groups.component';
import { RolesComponent } from './roles/roles.component';
import { PoolsComponent } from './pools/pools.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule,
    AccountSettingsComponent,
    QueueSettingsComponent,
    InteractiveSettingsComponent,
    TemplateSettingsComponent,
    ApiSettingsComponent,
    WorkflowsComponent,
    MatCardModule,
    UsersComponent,
    GroupsComponent,
    RolesComponent, 
    PoolsComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, private avtiveRoute: ActivatedRoute, private route: Router) {
    avtiveRoute.url.subscribe((data) => {
      this.ngOnInit();
    })
  }
  accountSettingLink = false;
  queuesLink = false;
  interactiveLink = false;
  templatesLink = false;
  apiLink = false;
  workflowLink = false;
  usersLink = false;
  groupsLink = false;
  rolesLink = false;
  poolsLink = false;
  isAdmin = false;
  ngOnInit(): void {

    this.oidcSecurityService.getUserData().subscribe((userData) => {
      // console.log(userData)
      var groups = userData.groups;
      var adminFound = groups.find((element: string) => {return (element == "Admin" || element == "CGAdmin") });
      if(adminFound != undefined){
        this.isAdmin = true
      }
    });

    this.accountSettingLink = false;
    this.queuesLink = false;
    this.interactiveLink = false;
    this.templatesLink = false;
    this.apiLink = false;
    this.workflowLink = false;
    this.usersLink = false;
    this.groupsLink = false;
    this.rolesLink = false;
    this.poolsLink = false;

    if (this.avtiveRoute.snapshot.url.length > 1) {
      switch (this.avtiveRoute.snapshot.url[1].path) {
        case 'account-settings': this.accountSettingLink = true; break;
        case 'queues': this.queuesLink = true; break;
        case 'interactive': this.interactiveLink = true; break;
        case 'templates': this.templatesLink = true; break;
        case 'api': this.apiLink = true; break;
        case 'workflows': this.workflowLink = true; break;
        case 'users': this.usersLink = true; break;
        case 'groups': this.groupsLink = true; break;
        case 'roles': this.rolesLink = true; break;
        case 'pools': this.poolsLink = true; break;
      }
    }
    else {
    }
  }


  accountSettingClick() {
    this.route.navigateByUrl('/settings/account-settings');
  }
  queuesClick() {
    this.route.navigateByUrl('/settings/queues');
  }
  interactiveClick() {
    this.route.navigateByUrl('/settings/interactive');
  }
  templatesClick() {
    this.route.navigateByUrl('/settings/templates');
  }
  apiClick() {
    this.route.navigateByUrl('/settings/api');
  }
  workflowClick() {
    this.route.navigateByUrl('/settings/workflows');
  }
  usersClick() {
    this.route.navigateByUrl('/settings/users');
  }
  groupsClick() {
    this.route.navigateByUrl('/settings/groups');
  }
  roleslick() {
    this.route.navigateByUrl('/settings/roles');
  }
  poolsClick() {
    this.route.navigateByUrl('/settings/pools');
  }
}
