<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Submit Job</h3>
        <div class="divHeaderLine">

        </div>
    </div>
    <br>
    <div class="rowFlex" style="background-color: #f3f3f3; height: calc(100vh - 175px);">
        <div class="colFlex" style="background-color: #ffffff; padding-right: 20px; width: 450px;">
            <p class="subHeading">1. Job Information</p>
            <br>
            <mat-form-field style="width: 100%;">
                <mat-label>Job Name</mat-label>
                <input matInput [(ngModel)]="jobName" (change)="inputValidation()">
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Project Name</mat-label>
                <mat-select [disabled]="projectLoading" (selectionChange)="getTemplates($event.value)">
                    @for (item of projects; track item) {
                    <mat-option [value]="item.value">{{item.text}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="projectLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Template Selector</mat-label>
                <mat-select [disabled]="templatesDisabled" [(ngModel)]="selectedTemplate"
                    (selectionChange)="inputValidation()">
                    @for (item of templates; track item) {
                    <mat-option [value]="item.resourceId">{{item.resourceName}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="templatesLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>File Type</mat-label>
                <mat-select [(ngModel)]="selectedFileType" (selectionChange)="inputValidation()">
                    <mat-option value="application/xml">XML</mat-option>
                    <mat-option value="application/json">JSON</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Queue Name</mat-label>
                <mat-select [(ngModel)]="selectedQueue" (selectionChange)="inputValidation()">
                    @for (item of queues; track item) {
                    <mat-option [value]="item">{{item.QueueName.S}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="queuesLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
        </div>
        <div style="background-color: #ffffff; padding-left: 20px; padding-right: 20px; width: 100%;">
            <p class="subHeading">2. Upload File</p>
            <br>
            <mat-form-field style="width: 100%;">
                <ngx-mat-file-input [formControl]="fileControl" [accept]="selectedFileType">
                </ngx-mat-file-input>
            </mat-form-field>
            <textarea disabled
                style="resize: none; width: 100%; height: calc(100vh - 325px); white-space: pre; overflow-wrap: normal; overflow-x: scroll; display: block;">{{fileText}}</textarea>
        </div>
        <div style="background-color: #ffffff; padding-left: 20px; width: 200px;">
            <p class="subHeading">3. Submit Job</p>
            <br>
            <button class="blueButton" [disabled]="submitJobButtonEnable" (click)="submitJob()">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true"
                    height="40px" width="40px" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
                        clip-rule="evenodd"></path>
                    <path fill-rule="evenodd"
                        d="M6 10a.75.75 0 01.75-.75h9.546l-1.048-.943a.75.75 0 111.004-1.114l2.5 2.25a.75.75 0 010 1.114l-2.5 2.25a.75.75 0 11-1.004-1.114l1.048-.943H6.75A.75.75 0 016 10z"
                        clip-rule="evenodd"></path>
                </svg><br><br>Execute Batch Job</button>
        </div>

    </div>

</div>