import { Directive, Input, TemplateRef } from '@angular/core';
import { TemplateStore } from "./template-store";

@Directive({
  selector: '[appToastDirective]'
})
export class ToastDirectiveDirective {

  constructor(
    private readonly template: TemplateRef<any>,
    private readonly templateStore: TemplateStore
) { }

@Input("appToastDirective")
public set key(value: string) {
    this.templateStore.add(value, this.template);
}

}
