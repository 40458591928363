<div style="padding: 20px; user-select: none;">
    <div>
        <p class="h2">Thank you for choosing <span style="color: #021857;">CG SaaS</span> as your CCM provider!</p>
        <br>
        <div class="h2">
            <div class='bannerImageOverlayText'>
                <table [ngStyle]="{ width: '100%' }">
                    <tbody>
                        <tr>
                            <td [ngStyle]="{ width: '40%' }">
                                Customer Communication Management Trusted Advisors
                                <br />
                                <p class='bannerImageOverlayText2'>Communication is in our DNA</p>
                            </td>
                            <td [ngStyle]="{ width: '60%' }"></td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <img class="CG_Banner" alt='' src='./assets/img/COM-GEN-BANNERS-6.png' draggable="false"/>
        </div>
        <div class="landingPageMainContent">
            <p class="paragraph"
                [ngStyle]="{ 'fontSize': '30px', 'fontFamily': 'Calibri', 'color': '#021857', 'fontWeight': 'bold', textAlign: 'center' }">
                Trusted Customer Communication experts who improve the way you engage with your customers</p>
            <br />
            <p class="paragraph"
                [ngStyle]="{ 'fontSize': '18px', 'fontFamily': 'Calibri', 'color': '#021857', textAlign: 'center' }">
                Customer
                communications management (CCM) is defined as the strategy to improve the creation, delivery, storage
                and
                retrieval of outbound communications, including those for marketing, new product introductions, renewal
                notifications, claims correspondence and documentation, and bill and payment notifications.
                Communication
                Genetics provides a world-class solution to achieve your goal of reaching your customers in a
                professional
                and secure way.</p>
            <br />
            <br />
            <p class="paragraph"
                [ngStyle]="{ 'fontSize': '18px', textAlign: 'center', 'fontFamily': 'Calibri', 'color': '#021857', }">To
                begin, click on the 3 bars icon&nbsp;<img src='./assets/img/Help/MainMenuButton.png' alt=''
                    [ngStyle]="{ 'verticalAlign': 'middle', 'width': '30px' }" draggable="false"/>&nbsp;at the top left corner.</p>
            <br />
            <br />
            <p class="paragraph"
                [ngStyle]="{ 'fontSize': '18px', textAlign: 'center', 'fontFamily': 'Calibri', 'color': '#021857', }">To
                Log
                Out, click on the user icon &nbsp; <img src='./assets/img/Help/UserIconButton.png' alt=''
                    [ngStyle]="{ 'verticalAlign': 'middle', 'width': '30px' }" draggable="false"/> &nbsp; in the top right corner. </p>
            <br />
            <br />
        </div>
    </div>
</div>