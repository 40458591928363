import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WebMethodsService } from '../web-methods.service';
import { Buffer } from 'buffer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    AdvancedDateSelectionComponent,
  ],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent implements OnInit {
  constructor(public api: WebMethodsService, private sanitizer: DomSanitizer, private toastTemplates: NgbdToastGlobal) { }

  ngOnInit(): void {
    this.getReport();
  }

  chartChip = true;
  summaryChip = true;
  transactionChip = true;
  emailChip = true;
  pdfData: SafeResourceUrl;
  reportItems: any[] = [];
  pdfLoading = false;
  csvLoading = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };


  getChips(event: any) {
    // console.log(event);
    switch (event.source.id) {
      case "Chart": this.chartChip = event.selected; break;
      case "Summary": this.summaryChip = event.selected; break;
      case "Transactions": this.transactionChip = event.selected; break;
      case "Emails": this.emailChip = event.selected; break;
    }
    this.getReport();
  }

  dateUpdated(data: any) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getReport();
  }

  getReport() {
    if (!this.range.controls.start.hasError('matStartDateInvalid') &&
      !this.range.controls.end.hasError('matEndDateInvalid') &&
      this.range.controls.start.value != null &&
      this.range.controls.end.value != null) {
      this.pdfLoading = true;
      this.reportItems = [];
      if (this.chartChip) {
        this.reportItems.push({ label: 'Chart' });
      }
      if (this.summaryChip) {
        this.reportItems.push({ label: 'Summary' });
      }
      if (this.transactionChip) {
        this.reportItems.push({ label: 'Transactions' });
      }
      if (this.emailChip) {
        this.reportItems.push({ label: 'Emails' });
      }
      var request = {
        tenantID: localStorage.getItem('currentTenant'),
        dateStart: this.range.controls.start.value,
        dateEnd: this.range.controls.end.value,
        ReportType: "SimpleReport",
        reportItems: this.reportItems
      }
      // console.log(request);
      this.api.postService("/document/get-report", request)
        .then((response) => {
          this.pdfLoading = false;
          // console.log(response);
          if (response.status == 200) {
            const url = URL.createObjectURL(new Blob([Buffer.from(response.data.envelopes[0].primaryChannel.data, 'base64')], { type: 'application/pdf' }));
            // console.log(url);
            this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          }
          else{
            this.toastTemplates.showErrorToast("API error - Get Report", "A connection error has occured. Please contact support for assistance.");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Get Report", "Report could not be generated.");
          this.pdfLoading = false;
        });
    }
  }

  getCSV() {
    this.csvLoading = true;
    this.reportItems = [];
    if (this.chartChip) {
      this.reportItems.push({ label: 'Chart' });
    }
    if (this.summaryChip) {
      this.reportItems.push({ label: 'Summary' });
    }
    if (this.transactionChip) {
      this.reportItems.push({ label: 'Transactions' });
    }
    if (this.emailChip) {
      this.reportItems.push({ label: 'Emails' });
    }
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      dateStart: this.range.controls.start.value,
      dateEnd: this.range.controls.end.value,
      ReportType: 'SimpleReport',
      reportItems: this.reportItems
    }
    this.api.postService("/document/get-csv-report", request)
      .then((response) => {
        this.csvLoading = false;
        // console.log(response);
        var respJson = response.data;
        if (respJson.statusCode === 400) {
          // console.log("Error", respJson.body);
          this.toastTemplates.showErrorToast("API error - Get CSV Report", "A connection error has occured. Please contact support for assistance.");
        }
        else if (respJson.statusCode === 200) {
          const url = URL.createObjectURL(new Blob([Buffer.from(respJson.body, 'base64')], { type: 'text/plain' }));
          //setDocumentBlob(url);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Report ' + new Date(this.range.controls.start.value!).toDateString() + ' to ' + new Date(this.range.controls.end.value!).toDateString() + '.csv' || 'download';
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();
        }
        else{
          this.toastTemplates.showErrorToast("API error - Get CSV Report", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        this.csvLoading = false;
        this.toastTemplates.showErrorToast("API error - Get CSV Report", "Could not generate CSV report");
        // console.log(err);
      })
  }
}
