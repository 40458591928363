<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Help</h3>
        <div class="divHeaderLine">

        </div>
    </div>
    <br style="user-select: none;">
    <div class="ManuPage">
        <mat-card style="margin-right: 20px; height: max-content;">
            <mat-card-content style="padding-left: 0px;">
                <div (click)="navigate(0)" *ngIf="!loggingInLink" class="PageButton">
                    <p class="PageButtonText">Logging In</p>
                </div>
                <div (click)="navigate(0)" *ngIf="loggingInLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Logging In</p>
                </div>

                <div (click)="navigate(1)" *ngIf="!forgotPasswordLink" class="PageButton">
                    <p class="PageButtonText">Forgot Password</p>
                </div>
                <div (click)="navigate(1)" *ngIf="forgotPasswordLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Forgot Password</p>
                </div>

                <div (click)="navigate(2)" *ngIf="!navigatingLink" class="PageButton">
                    <p class="PageButtonText">Navigating the portal</p>
                </div>
                <div (click)="navigate(2)" *ngIf="navigatingLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Navigating the portal</p>
                </div>

                <div (click)="navigate(3)" *ngIf="!dateTimeLink" class="PageButton">
                    <p class="PageButtonText">Date Time Selection</p>
                </div>
                <div (click)="navigate(3)" *ngIf="dateTimeLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Date Time Selection</p>
                </div>

                <div (click)="navigate(4)" *ngIf="!usageStatsLink" class="PageButton">
                    <p class="PageButtonText">Usage Statistics</p>
                </div>
                <div (click)="navigate(4)" *ngIf="usageStatsLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Usage Statistics</p>
                </div>

                <div (click)="navigate(5)" *ngIf="!docArchiveLink" class="PageButton">
                    <p class="PageButtonText">Document Archive</p>
                </div>
                <div (click)="navigate(5)" *ngIf="docArchiveLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Document Archive</p>
                </div>

                <div (click)="navigate(6)" *ngIf="!submitJobsLink" class="PageButton">
                    <p class="PageButtonText">Submit Jobs</p>
                </div>
                <div (click)="navigate(6)" *ngIf="submitJobsLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Submit Jobs</p>
                </div>

                <div (click)="navigate(7)" *ngIf="!jobStatusLink" class="PageButton">
                    <p class="PageButtonText">Job Status</p>
                </div>
                <div (click)="navigate(7)" *ngIf="jobStatusLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Job Status</p>
                </div>

                <div (click)="navigate(8)" *ngIf="!interactiveDocumentLink" class="PageButton">
                    <p class="PageButtonText">Interactive Documents</p>
                </div>
                <div (click)="navigate(8)" *ngIf="interactiveDocumentLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Interactive Documents</p>
                </div>

                <div (click)="navigate(9)" *ngIf="!reportsLink" class="PageButton">
                    <p class="PageButtonText">Reports</p>
                </div>
                <div (click)="navigate(9)" *ngIf="reportsLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Reports</p>
                </div>

                <div (click)="navigate(10)" *ngIf="!settingsLink" class="PageButton">
                    <p class="PageButtonText">Settings</p>
                </div>
                <div (click)="navigate(10)" *ngIf="settingsLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Settings</p>
                </div>

                <div (click)="navigate(11)" *ngIf="!webServicesLink" class="PageButton">
                    <p class="PageButtonText">Web Services</p>
                </div>
                <div (click)="navigate(11)" *ngIf="webServicesLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">Web Services</p>
                </div>

                <div (click)="navigate(12)" *ngIf="!s3BucketsLink" class="PageButton">
                    <p class="PageButtonText">S3 Buckets</p>
                </div>
                <div (click)="navigate(12)" *ngIf="s3BucketsLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">S3 Buckets</p>
                </div>

                <div (click)="navigate(13)" *ngIf="!elasticSearchLink" class="PageButton">
                    <p class="PageButtonText">ElasticSearch</p>
                </div>
                <div (click)="navigate(13)" *ngIf="elasticSearchLink" class="PageButtonSelected">
                    <p class="PageButtonSelectedText">ElasticSearch</p>
                </div>

            </mat-card-content>
        </mat-card>

        <div class="PageSection">
            <app-logging-in *ngIf="loggingInLink"></app-logging-in>
            <app-forgot-password *ngIf="forgotPasswordLink"></app-forgot-password>
            <app-navigating *ngIf="navigatingLink"></app-navigating>
            <app-date-time *ngIf="dateTimeLink"></app-date-time>
            <app-usage-stats *ngIf="usageStatsLink"></app-usage-stats>
            <app-doc-archive *ngIf="docArchiveLink"></app-doc-archive>
            <app-submit-jobs *ngIf="submitJobsLink"></app-submit-jobs>
            <app-job-status *ngIf="jobStatusLink"></app-job-status>
            <app-interactive *ngIf="interactiveDocumentLink"></app-interactive>
            <app-reports *ngIf="reportsLink"></app-reports>
            <app-settings *ngIf="settingsLink"></app-settings>
            <app-web-services *ngIf="webServicesLink"></app-web-services>
            <app-s3-buckets *ngIf="s3BucketsLink"></app-s3-buckets>
            <app-elastic-search *ngIf="elasticSearchLink"></app-elastic-search>
        </div>
    </div>
</div>