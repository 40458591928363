import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { Observable, interval, Subscription } from 'rxjs'; 
import { map } from 'rxjs/operators'; 
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'; 

import { NotificationsService } from './notifications.service';
import { NgbdToastGlobal } from './toast/toast-global.component';
import { environment } from '../environments/environment';

import {
  OidcSecurityService
} from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class webSocketService implements OnInit {
  public socket$!: WebSocketSubject<any>; 
  mySubscription: Subscription
  constructor(public notifService: NotificationsService, public oidcSecurityService: OidcSecurityService, private toastTemplates: NgbdToastGlobal) {
    this.mySubscription= interval(5000).subscribe((x =>{
      if(this.connected && !this.socket$.closed){
        this.ping();
      }
      else{
        this.connect();
      }
      
  }));
  }
  ngOnInit(): void {
    
  }

  connected = false;
  

  connect(){
    var userID = "";
    this.oidcSecurityService.getUserData().subscribe((userData: any) => {
      userID = userData.sub;
    });
    console.log('Connecting to websocket!!!!');
    this.socket$ = webSocket(environment.websocketURL+'?userid='+userID);
    this.connected = true;
    // this.socket$.asObservable()
    this.socket$.subscribe((data: any) => {
      console.log(data);
      // alert(data.message);
      console.log(data.message);
      var event = JSON.parse(data.message).event;
      console.log(JSON.parse(data.message));
      // console.log(JSON.parse(data.message).action);
      console.log(event);
      // console.log(JSON.parse(data.message).message);
      console.log(JSON.parse(data.message).message);
      var message = JSON.parse(data.message).message;
      if(event == 'checkNotifications'){
        this.notifService.getNotifications();
      }
      else if(event == 'alert'){
        alert(message);
      }
      else if(event == 'popupStandard'){
        this.toastTemplates.showStandardToast("", message);
      }
      else if(event == 'popupError'){
        this.toastTemplates.showErrorToast("Error", message);
      }
      else if(event == 'popupSuccess'){
        this.toastTemplates.showSuccessToast("Notification", message);
      }
      else if(event == 'popupNotification'){
        this.toastTemplates.showNotificationToast("Notification", message);
      }
    });
    // this.socket$.error((err: any) => {
    //   console.log(err);
    // })
    
  }

  ping(){
    this.socket$.next({ action: "ping", message: 'ping' });
  }

  disconnect(){
    console.log("Disconnecting!!")
    this.socket$.complete(); 
    this.connected = false;
  }
}