<div style="user-select: none;">
    <h1 mat-dialog-title><b>Delete Workflow</b></h1>
    <div mat-dialog-content>
        <div class="flexRow">
            <p class="ConfirmText">Are you sure you want to delete workflow: <strong>{{data.workflowName}}</strong></p>
        </div>
    </div>

    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="secondary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="data" >Confirm</button>
    </div>
</div>