<div class="mainDiv">
    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
        <button class="blueButton" style="margin-bottom: 15px;" (click)="createWorkflow()">
            <div style="display: flex; flex-direction: row; align-items: baseline; gap: 10px;">
                <div style="height: 20px;">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="15px"
                        width="15px" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18 15L17.999 18H21V20H17.999L18 23H16L15.999 20H13V18H15.999L16 15H18ZM11 18V20H3V18H11ZM21 11V13H3V11H21ZM21 4V6H3V4H21Z">
                        </path>
                    </svg>
                </div>
                <div style="height: 15px;">
                    <p>Add Workflow</p>
                </div>
            </div>
        </button>
        <div
            style="margin-top: 10px; margin-bottom: 5px; margin-left: 10px; display: flex; flex-direction: row; justify-content: flex-start;">
            <div class="input-icons">
                <i class="icon">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                        </path>
                    </svg>
                </i>
                <input class="filterInput" [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Find Workflow"
                    #input>
                <i class=" icon iconRight" (click)="clearFilter()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z">
                        </path>
                    </svg>
                </i>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element" style="vertical-align: middle;">
                <div class="flexRow" style="align-items: center;">
                    <button mat-icon-button aria-label="Copy" [cdkCopyToClipboard]="element.id"
                        (click)="tooltip1.show()">
                        <span #tooltip1="matTooltip" matTooltip="Copied to clipboard" matTooltipPosition="above"
                            matTooltipTouchGestures="off" matTooltipShowDelay="0" matTooltipHideDelay="500"></span>
                        <svg _ngcontent-ng-c3944431652="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-ng-c3944431652="" fill="none" d="M0 0h24v24H0V0z"></path>
                            <path _ngcontent-ng-c3944431652=""
                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                            </path>
                        </svg>
                    </button>
                    <p style="height: fit-content;">{{element.id}}</p>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="workflowName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.workflowName}} </td>
        </ng-container>

        <ng-container matColumnDef="workflowDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.workflowDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="WorkflowPreview">
            <th mat-header-cell *matHeaderCellDef> Workflow </th>
            <td mat-cell *matCellDef="let element">
                <mat-chip-set aria-label="WorkflowItems">
                    <div *ngFor="let wi of element.wfPools; let last = last" class="workflowItemDivDenter">
                        <mat-chip style="background-color: #5B9BD5;">{{wi.Name}}</mat-chip>
                        <svg *ngIf="!last" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <polyline fill="none" stroke-width="2" points="9 6 15 12 9 18"></polyline>
                        </svg>
                    </div>
                </mat-chip-set>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 50px;"> Actions </th>
            <td mat-cell *matCellDef="let element">
                <div class="flexRow">
                    <button mat-mini-fab color="primary" aria-label="Edit Workflow" (click)="editWorkflow(element)">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 18.89H6.41421L15.7279 9.57627L14.3137 8.16206L5 17.4758V18.89ZM21 20.89H3V16.6473L16.435 3.21231C16.8256 2.82179 17.4587 2.82179 17.8492 3.21231L20.6777 6.04074C21.0682 6.43126 21.0682 7.06443 20.6777 7.45495L9.24264 18.89H21V20.89ZM15.7279 6.74785L17.1421 8.16206L18.5563 6.74785L17.1421 5.33363L15.7279 6.74785Z">
                            </path>
                        </svg>


                    </button>
                    <div style="width: 10px;"></div>
                    <button mat-mini-fab color="primary" aria-label="Delete Workflow" (click)="deleteWorkflow(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

    </table>
    <mat-card *ngIf="wfLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
        showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>