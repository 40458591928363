import { Component, ViewChild, OnInit, Inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WebMethodsService } from '../web-methods.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import * as echarts from 'echarts';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';

export interface jobsTable {
  JobID: string;
  JobName: string;
  SubmittedDate: string;
  Queue: string;
  Type: string;
  Status: string;
  ProcessedBy: string;
  Started: string;
  Finished: string;
  Duration: string;
  Progress: string;
  InputFile: string;
  DataType: string;
  Range: string;
  Reports: string[];
}

export interface Report {
  fileType: string;
  fileName: string;
  contentType: string;
  s3: string;
}

@Component({
  selector: 'app-job-status',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatProgressBarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatChipsModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    ScrollingModule,
    MatPaginatorModule,
    MatSortModule,
    AdvancedDateSelectionComponent,
  ],
  templateUrl: './job-status.component.html',
  styleUrl: './job-status.component.scss'
})
export class JobStatusComponent implements OnInit, OnDestroy {
  jobsTableData: jobsTable[] = [];
  dataSource = new MatTableDataSource(this.jobsTableData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnDestroy(): void {
    this.autoRefersh = false;
  }

  autoRefersh: boolean = localStorage.getItem('JobAutoRefresh') ? (localStorage.getItem('JobAutoRefresh') == "true" ? true : false) : false;
  statuses = new FormControl('');
  statusList: string[] = ['Waiting', 'Spooled', 'Stopped', 'Completed', 'In Progress', 'Errored'];

  displayedColumns: string[] = ['JobID', 'JobName', 'SubmittedDate', 'Queue', 'Type', 'Status', 'action'];
  jobsLoading = false;
  jobDrawerOpen = false;
  jobDrawerLoading = false;
  jobDrawerData: jobsTable = {
    JobID: "",
    Queue: "",
    JobName: "",
    Status: "",
    ProcessedBy: "",
    SubmittedDate: "",
    Started: "",
    Finished: "",
    Duration: "",
    Progress: "",
    Type: "",
    InputFile: "",
    DataType: "",
    Range: "",
    Reports: [],
  };
  reportData: string;
  reportDataLoading = false;
  reports: Report[];
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };

  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) {
    setInterval(() => { if (this.autoRefersh) { this.getJobs(); } }, 10 * 1000);
  }

  ngOnInit(): void {
    this.getJobs();
  }



  setChart(element: string, value: number, total: number, valueColor: string, totalColor: string) {
    var chartDom = document.getElementById(element);
    document.getElementById(element + "Start")!.innerHTML = value.toString();
    document.getElementById(element + "End")!.innerHTML = total.toString();

    var myChart = echarts.init(chartDom);
    var option;

    option = {
      width: "200px",
      height: "100px",
      series: [
        {
          type: 'gauge',
          center: ['50%', '85%'],
          radius: '150%',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: total,
          splitNumber: 1,

          itemStyle: {
            color: valueColor
          },
          progress: {
            roundCap: true,
            show: true,
            width: 30
          },

          pointer: {
            show: false
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 30,
              color: [
                [1, totalColor]
              ]
            }
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,


          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 1,
            offsetCenter: [0, '-4%'],
            fontSize: 20,
            fontWeight: 'bolder',
            formatter: '{value}',
            color: 'inherit'
          },
          data: [
            {
              value: value
            }
          ]
        }
      ]
    };
    option && myChart.setOption(option);
  }

  toggleAutoRefersh(event: any) {
    localStorage.setItem('JobAutoRefresh', event.checked);
  }

  closeJobOption() {
    this.jobDrawerOpen = false;
  }


  getJobs() {
    this.jobsLoading = true;
    this.jobDrawerOpen = false;
    if (
      !this.range.controls.start.hasError('matStartDateInvalid') &&
      !this.range.controls.end.hasError('matEndDateInvalid') &&
      this.range.controls.start.value != null &&
      this.range.controls.end.value != null) {

      // console.log(this.statuses.value);
      // console.log(this.range.controls.start.value?.toISOString());
      // console.log(this.range.controls.end.value?.toISOString());
      var statusesList: string[] = [];
      for (var y = 0; y < this.statuses.value!.length; y++) {
        var statusItem = "";
        switch (this.statuses.value![y]) {
          case 'Waiting': statusItem = "QUEUED"; break;
          case 'In Progress': statusItem = "RUNNING"; break;
          case 'Spooled': statusItem = "SPOOLED"; break;
          case 'Completed': statusItem = "FINISHED"; break;
          case 'Stopped': statusItem = "STOPPED"; break;
          case 'Errored': statusItem = "FAILED"; break;
          default: statusItem = "UNKNOWN";
        }
        statusesList.push(statusItem);
      }
      var request = {
        tenantID: localStorage.getItem('currentTenant'),
        fromDate: this.range.controls.start.value,
        toDate: this.range.controls.end.value,
        status: statusesList
      }
      this.api.postService("/sc/listjobs", request)
        .then((response) => {
          this.jobsLoading = false;
          // console.log(response);
          if (response.status == 200) {
            this.jobsTableData = [];
            var summary = response.data.groupSummaries[0].summary;
            this.setChart('Waiting', summary.QUEUED, response.data.items.length, "#57B9D6", "#DDF1F7");
            this.setChart('Progress', summary.RUNNING, response.data.items.length, "#57B9D6", "#DDF1F7");
            this.setChart('Spooled', summary.SPOOLED, response.data.items.length, "#777777", "#E4E4E4");
            this.setChart('Completed', summary.FINISHED, response.data.items.length, "#69B02C", "#E1EFD5");
            this.setChart('Stopped', summary.STOPPED, response.data.items.length, "#FFF032", "#FFFCD6");
            this.setChart('Errored', summary.FAILED, response.data.items.length, "#FF3228", "#FFD6D4");
            response.data.items.forEach((element: any) => {
              var jobTableRow = {
                JobID: element.id,
                JobName: element.name,
                SubmittedDate: new Date(element.submitted).toLocaleString('af-za'),
                Queue: element.queue,
                Type: element.type,
                Status: element.status,
                ProcessedBy: element.appliance,
                Started: new Date(element.started).toLocaleString('af-za'),
                Finished: element.finished == null ? "" : new Date(element.finished).toLocaleString('af-za'),
                Duration: element.finished == null ? "" : new Date((((new Date(element.finished).getTime()) - (new Date(element.started).getTime())) / 1000)).getTime() + " seconds",
                Progress: element.progress,
                InputFile: element.input,
                DataType: element.transactionDataType,
                Range: element.range == null ? "All Transactions" : element.range,
                Reports: []
              }
              this.jobsTableData.push(jobTableRow);
            });
            this.dataSource = new MatTableDataSource(this.jobsTableData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          else {
            this.toastTemplates.showErrorToast("API error - List Jobs", "A connection error has occured. Please contact support for assistance.");
          }
          this.jobsLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - List Jobs", "Could not read job data. This could be due to a configuration issue.");
          this.jobsLoading = false;
        });
    }
  }

  getJobInfo(item: any) {

    this.jobDrawerData = item;
    // console.log(item);
    this.jobDrawerLoading = true;
    this.jobDrawerOpen = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      jobID: item.JobID,
      queue: item.Queue,
    }
    this.api.postService("/sc/listjoblogs", request)
      .then((response) => {
        this.jobDrawerLoading = false;
        if (response.status == 200) {
          this.reports = response.data;
          // console.log(response.data);
        }
        else {
          this.toastTemplates.showErrorToast("API error - List Job logs", "A connection error has occured. Please contact support for assistance.");
        }

      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - List Job Logs", "Could not read job logs. This could be due to configuration issue.");
        this.jobDrawerLoading = false;
      })

  }

  dateUpdated(data: any){
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getJobs();
  }

  getLogFile(item: Report) {

    this.reportData = "";
    this.jobDrawerLoading = true;
    // console.log(item);
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      file: item.fileName,
      s3: item.s3,
    }
    this.api.postService("/document/get-log", request)
      .then((response) => {
        this.jobDrawerLoading = false;
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            // console.log(atob(JSON.parse(response.data.body).document));
            // console.log(atob(JSON.parse(response.data.body).document));
            this.reportData = atob(JSON.parse(response.data.body).document);

            this.dialog.open(JobLogDialog, { width: '100%', maxWidth: '99%', maxHeight: '99%', data: { logData: this.reportData, loading: this.reportDataLoading, isCSV: item.fileName.split(".")[1] == "csv", fileName: item.fileName } });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get Log File", "A connection error has occured. Please contact support for assistance.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Log File", "A connection error has occured. Please contact support for assistance.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Log File", "Could not read job logs. This could be due to configuration issue.");
        this.jobDrawerLoading = false;
      });

  }

  drawerClose() {
    this.jobDrawerOpen = false;
  }

  
}


@Component({
  selector: 'job-log-dialog',
  templateUrl: 'job-log-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatTableModule,
  ],
})
export class JobLogDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<JobLogDialog>, @Inject(MAT_DIALOG_DATA) public data: any,) { }

  tableData: any = [];

  rows = this.data.logData.replaceAll("\"", "").split("\n");
  row: string = this.rows[0];
  displayedColumns = this.row.split(",");

  // displayedColumns: string[] = ['time','status','job.id'];

  ngOnInit(): void {
    // console.log("displayedColumns");
    // console.log(this.displayedColumns);

    if (this.data.isCSV) {
      // console.log(this.data);
      var rows = this.data.logData.replaceAll("\"", "").split("\n");
      //this.displayedColumns = rows[0];
      var csv = rows.map(function (row: string) {
        return row.split(",");
      });
      // console.log(csv);
      csv.forEach((element1: any, index1: number) => {
        if (index1 != 0) {
          type MyObject = Record<string, any>;
          let lineItem: MyObject = {};
          element1.forEach((element2: string, index2: number) => {
            // lineItem[csv[0][index2]] = element2;
            if (csv[0][index2] == "time") {
              lineItem[csv[0][index2]] = new Date(element2.replace("GMT", "")).toLocaleDateString() + "T" + new Date(element2.replace("GMT", "")).getHours() + ":" + new Date(element2.replace("GMT", "")).getMinutes() + ":" + new Date(element2.replace("GMT", "")).getSeconds();

            }
            else {
              lineItem[csv[0][index2]] = element2;
            }

          });
          // console.log(lineItem);
          this.tableData.push(lineItem);
        }
      });
      // console.log(this.tableData);
    }

  }
}