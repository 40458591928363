<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Notifications</h3>
        <div class="divHeaderLine">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <mat-select [formControl]="filter" required (selectionChange)="getNotifications()">
                    <mat-option [value]="'All'">All</mat-option>
                    <mat-option [value]="'Read'">Read</mat-option>
                    <mat-option [value]="'Unread'">Unread</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <br>
    <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="user-select: none;" matSort matSortActive="DateTime" matSortDirection="desc">
            <ng-container matColumnDef="Header">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 300px;"> Header </th>
                <td mat-cell *matCellDef="let element"> {{element.Header}} </td>
            </ng-container>
            <ng-container matColumnDef="Body">
                <th mat-header-cell *matHeaderCellDef> Notification </th>
                <td mat-cell *matCellDef="let element"> {{element.Body}} </td>
            </ng-container>
            <ng-container matColumnDef="DateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px;"> DateTime </th>
                <td mat-cell *matCellDef="let element"> {{element.DateTime}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[5, 10, 20, 50, 100]" pageSize="10"
            showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>

    </div>
</div>