import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface DialogData {
  poolName: string;
  poolDescription: string;
  tenantID: any;
}

@Component({
  selector: 'app-pools',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './pools.component.html',
  styleUrl: './pools.component.scss'
})
export class PoolsComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Name', 'Description', 'action'];
  pools: any = [];
  dataSource = new MatTableDataSource(this.pools);
  poolsLoading = false;
  // filter = new FormControl('All');
  tenantID: any;
  tenants: any = [{ id: 'All', Name: 'All' }];
  tenantsLoading = false;
  filterValue = '';
  
  ngOnInit(): void {
    this.tenantID = localStorage.getItem('currentTenant');
    // this.getTenants();
    this.getPools();
  }


  getPools() {
    this.poolsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listpools", { tenantID: tenantID })
      .then((response) => {
        this.poolsLoading = false;
        if (response.status == 200) {
          this.pools = [];
          response.data.forEach((element: any) => {
            if (this.tenantID == element.TenantID) {
              var tenantName = this.tenants.find((tItem: any) => { return tItem.id == element.TenantID });
              var poolListItem = {
                id: element.id,
                Name: element.Name,
                Description: element.Description,
                TenantID: tenantName
              }
              this.pools.push(poolListItem);
            }
          });
          this.dataSource = new MatTableDataSource(this.pools);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Pools", err);
        this.poolsLoading = false;
      });
  }

  // getTenants() {
  //   this.tenantsLoading = true;
  //   this.api.postService("/um/tenantperuser", { Username: "#" })
  //     .then((response) => {
  //       if (response.status == 200) {
  //         this.tenants = [{ id: 'All', Name: 'All' }];
  //         this.tenants.push.apply(this.tenants, response.data.data.listTenantss.items);
  //       }
  //       else {
  //         this.toastTemplates.showErrorToast("API error - Get Tenants", "Could not get tenants.");
  //       }
  //       this.tenantsLoading = false;
  //       this.getPools();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       this.toastTemplates.showErrorToast("API error - Get Tenants", err);
  //       this.tenantsLoading = false;
  //     });
  // }


  filterPools() {
    this.getPools();
  }

  addPool() {
    const dialogRef = this.dialog.open(AddPoolDialog, { width: 'fit-content', data: { poolName: "", poolDescription: "", tenantID: new FormControl() } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if ((result.filter != "") && (result.poolName != "")) {
          this.poolsLoading = true;
          this.api.postService("/settings/addpool", { Name: result.poolName, Description: result.poolDescription, TenantID: result.tenantID })
            .then((results) => {
              this.poolsLoading = false;
              if (results.status == 200) {
                if (results.data.statusCode != 200) {
                  this.toastTemplates.showErrorToast("API error - Add Pool", "A connection error has occured. Please contact support for assistance.");
                }
              }
              else {
                this.toastTemplates.showErrorToast("API error - Add Pool", "A connection error has occured. Please contact support for assistance.");
              }
              this.getPools();
            })
            .catch((err) => {
              // console.log(err);
              this.toastTemplates.showErrorToast("API error - Add Pool", err);
              this.poolsLoading = false;
            });
        }
      }
    });
  }

  deletePool(id: string, name: string) {
    this.poolsLoading = true;
    this.api.postService("/settings/deletepool", { PoolID: id })
      .then((response) => {
        this.api.postService("/um/updatealluserpools", { PoolName: name })
          .then((response) => {
            this.poolsLoading = false;
            this.getPools();
          })
          .catch((err) => {
            this.toastTemplates.showErrorToast("API error - Delete Pool", err);
            this.poolsLoading = false;
          });
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Delete Pool", err);
        this.poolsLoading = false;
      });
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}


@Component({
  selector: 'add-pool-dialog',
  templateUrl: 'add-pool-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressBarModule,
  ],
  styleUrl: './pools.component.scss'
})
export class AddPoolDialog implements OnInit {
  currentTenant: any;
  tenantsLoading = false;
  tenants: any = [];
  filter = new FormControl('All');
  constructor(public dialogRef: MatDialogRef<AddPoolDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  ngOnInit(): void {
    this.data.tenantID = localStorage.getItem('currentTenant');
  }
}