<mat-radio-group aria-label="Select an option" *ngIf="showSelection" [formControl]="archiveType" class="arcSelect">
    <mat-radio-button value="elasticSearch">Simple Archive</mat-radio-button>
    <mat-radio-button value="alfresco">Advanced Archive</mat-radio-button>
</mat-radio-group>
<app-archive *ngIf="archiveType.value == 'elasticSearch'"></app-archive>
<div *ngIf="archiveType.value == 'alfresco'" class="options-sidenav-content" style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Document Archive</h3>
    </div>
</div>

<div *ngIf="archiveType.value == 'alfresco'" class="mainArchiveDiv">
    <iframe class="archiveIframe" [src]="embedURL" allow="clipboard-read; clipboard-write" width='100%'></iframe>
</div>

<div *ngIf="archiveType.value == ''" class="mainArchiveDiv">
    <p class="noArchiveMessage">No archive option enabled.</p>
</div>