<div style="padding: 20px;">
  <div class="divHeaderLine">
    <h3>Usage Statistics</h3>
    <div class="divHeaderLine">
      <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
    </div>
  </div>

  <div class="cardsDiv">
    <div class="flexCol">
      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">TOTAL DOCUMENTS</p>
              <p class="cardValue">{{totDocs}}</p>
            </div>
            <div class="roundIconDiv">
              <svg stroke="currentColor" fill="#ffffff" stroke-width="0" viewBox="0 0 24 24" height="25px" width="25px"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 2.75a.75.75 0 0 0-1.5 0v18.5c0 .414.336.75.75.75H20a.75.75 0 0 0 0-1.5H2.5V2.75Z"></path>
                <path
                  d="M22.28 7.78a.75.75 0 0 0-1.06-1.06l-5.72 5.72-3.72-3.72a.75.75 0 0 0-1.06 0l-6 6a.75.75 0 1 0 1.06 1.06l5.47-5.47 3.72 3.72a.75.75 0 0 0 1.06 0l6.25-6.25Z">
                </path>
              </svg>
            </div>
          </div>
          <br style="user-select: none;">
          <div class="cardsDiv">
            <div [innerHTML]="percentChangeOnTotal"></div>
            <p class="statLine1">&nbsp;</p>
            <p class="statLine2">Since last month</p>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotDocsCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>


      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">TOTAL PER OUTPUT</p>
              <div class="cardsDiv" [innerHTML]="totalOutputItems" style="max-width: 250px;">
              </div>
            </div>
            <div class="roundIconDiv">
              <svg stroke="currentColor" fill="#ffffff" stroke-width="0" viewBox="0 0 16 16" height="25px" width="25px"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z">
                </path>
              </svg>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerOutputCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>


      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">TOTAL PER TEMPLATE</p>
              <div [innerHTML]="totalTemplateItems"
                style="max-height: 150px; overflow-x: hidden; overflow-y:auto; width: auto;"></div>
            </div>
            <div class="roundIconDiv">
              <svg stroke="#ffffff" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="25px"
                width="25px" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" stroke-linejoin="round" stroke-width="32"
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z">
                </path>
                <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                  d="M256 56v120a32 32 0 0032 32h120"></path>
              </svg>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerTemplateCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

    </div>

    <div>
      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">TOTAL ENVELOPES</p>
              <div style="max-height: 500px; overflow-x: hidden; overflow-y:auto; width: 500px; height: 490px;">
                <div id="TotPerTemplateCardGraph" style="overflow: hidden; width: 100%; height: 100%;"> </div>
              </div>
            </div>

          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerTemplateCardGraph">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>

    <div class="flexCol">
      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">DOCUMENTS OVER PERIOD</p>
              <p class="cardValue">{{totDocsPeriod}}</p>
            </div>
            <div class="roundIconDiv">
              <svg stroke="currentColor" fill="#ffffff" stroke-width="0" viewBox="0 0 24 24" height="25px" width="25px"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 2.75a.75.75 0 0 0-1.5 0v18.5c0 .414.336.75.75.75H20a.75.75 0 0 0 0-1.5H2.5V2.75Z"></path>
                <path
                  d="M22.28 7.78a.75.75 0 0 0-1.06-1.06l-5.72 5.72-3.72-3.72a.75.75 0 0 0-1.06 0l-6 6a.75.75 0 1 0 1.06 1.06l5.47-5.47 3.72 3.72a.75.75 0 0 0 1.06 0l6.25-6.25Z">
                </path>
              </svg>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerOutputCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">OUTPUT OVER PERIOD</p>
              <div class="cardsDiv" [innerHTML]="timeOutputItems" style="max-width: 250px;">
              </div>
            </div>
            <div class="roundIconDiv">
              <svg stroke="currentColor" fill="#ffffff" stroke-width="0" viewBox="0 0 16 16" height="25px" width="25px"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z">
                </path>
              </svg>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerOutputCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <p class="cardHeader">TEMPLATES OVER PERIOD</p>
              <div [innerHTML]="timeTemplateItems"
                style="max-height: 150px; overflow-x: hidden; overflow-y:auto; width: auto;"></div>
            </div>
            <div class="roundIconDiv">
              <svg stroke="#ffffff" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="25px"
                width="25px" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" stroke-linejoin="round" stroke-width="32"
                  d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z">
                </path>
                <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                  d="M256 56v120a32 32 0 0032 32h120"></path>
              </svg>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTotPerTemplateCard">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>

    <div>
      <mat-card class="smallStats">
        <mat-card-content>
          <div class="cardTopSection">
            <div>
              <div class="flexRow">
                <p class="cardHeader">GRAPH OVER PERIOD</p>
                <ng-template #standardTpl>
                  <div class="flexRowNoSpace">
                  
                    <mat-form-field appearance="outline" class="smallSelect">
                      <mat-label>Filter</mat-label>
                      <mat-select disableOptionCentering matNativeControl required [formControl]="selectedGroup" (selectionChange)="groupChanged()">
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px;" value="total">Envelope</mat-option>
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="channel">Channel</mat-option>
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="template">Template</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="smallSelect">
                      <mat-label>Scale</mat-label>
                      <mat-select disableOptionCentering matNativeControl required [formControl]="selectedScale" (selectionChange)="scaleChanged()">
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="day">Day</mat-option>
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="month">Month</mat-option>
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="year">Year</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="smallSelect">
                      <mat-label>Type</mat-label>
                      <mat-select disableOptionCentering matNativeControl required [formControl]="selectedGraphType" (selectionChange)="graphChanged()">
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="line">Line</mat-option>
                        <mat-option style="height: 20px; min-height: 30px; font-size: 14px" value="bar">Bar</mat-option>
                      </mat-select>
                    </mat-form-field>
                    
                  </div>
                </ng-template>
                <button mat-icon-button [autoClose]="'outside'" [ngbPopover]="standardTpl" aria-label="Example icon-button with a menu">
                  <mat-icon>filter_list</mat-icon>
                </button>
              </div>

              <div style="max-height: 500px; overflow-x: hidden; overflow-y:auto; width: 500px; height: 490px;">
                <div id="TemplatePeriodCardGraph" style="overflow: hidden; width: 100%; height: 100%;"> </div>
              </div>
            </div>

          </div>
        </mat-card-content>
        <mat-card-footer *ngIf="loadingTemplatePeriodCardGraph">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>