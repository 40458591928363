<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Settings</h3>
        <div class="divHeaderLine">

        </div>
    </div>
    <br>
    <div class="ManuPage">
        <!-- <div style="min-width: 150px;">
            <div (click)="accountSettingClick()" *ngIf="!accountSettingLink" class="PageButton">
                <p class="PageButtonText">My Account</p>
            </div>
            <div (click)="accountSettingClick()" *ngIf="accountSettingLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">My Account</p>
            </div>

            <div (click)="queuesClick()" *ngIf="!queuesLink" class="PageButton">
                <p class="PageButtonText">Queues</p>
            </div>
            <div (click)="queuesClick()" *ngIf="queuesLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Queues</p>
            </div>

            <div (click)="interactiveClick()" *ngIf="!interactiveLink" class="PageButton">
                <p class="PageButtonText">Interactive</p>
            </div>
            <div (click)="interactiveClick()" *ngIf="interactiveLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Interactive</p>
            </div>

            <div (click)="templatesClick()" *ngIf="!templatesLink" class="PageButton">
                <p class="PageButtonText">Templates</p>
            </div>
            <div (click)="templatesClick()" *ngIf="templatesLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Templates</p>
            </div>

            <div (click)="apiClick()" *ngIf="!apiLink" class="PageButton">
                <p class="PageButtonText">API</p>
            </div>
            <div (click)="apiClick()" *ngIf="apiLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">API</p>
            </div>
        </div> -->

        <mat-card style="margin-right: 20px; height: max-content;">
            <mat-card-content style="padding-left: 0px;">
                <div (click)="accountSettingClick()" *ngIf="!accountSettingLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <circle cx="10" cy="8" r="4"></circle>
                            <path
                                d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26a6.963 6.963 0 01-.59-6.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">My Account</p>
                </div>
                <div (click)="accountSettingClick()" *ngIf="accountSettingLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <circle cx="10" cy="8" r="4"></circle>
                            <path
                                d="M10.67 13.02c-.22-.01-.44-.02-.67-.02-2.42 0-4.68.67-6.61 1.82-.88.52-1.39 1.5-1.39 2.53V20h9.26a6.963 6.963 0 01-.59-6.98zM20.75 16c0-.22-.03-.42-.06-.63l1.14-1.01-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L18 11h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1.01c-.03.21-.06.41-.06.63s.03.42.06.63l-1.14 1.01 1 1.73 1.45-.49c.32.27.68.48 1.08.63L16 21h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1.01c.03-.21.06-.41.06-.63zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">My Account</p>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="queuesClick()" *ngIf="!queuesLink" class="PageButton">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                aria-hidden="true" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">Queues</p>
                    </div>
                    <div (click)="queuesClick()" *ngIf="queuesLink" class="PageButtonSelected">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                aria-hidden="true" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.625 3.75a2.625 2.625 0 100 5.25h12.75a2.625 2.625 0 000-5.25H5.625zM3.75 11.25a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75zM3 15.75a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zM3.75 18.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5H3.75z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Queues</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="interactiveClick()" *ngIf="!interactiveLink" class="PageButton">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                                height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM304.8 524h50.7c3.7 0 6.8-3 6.8-6.8v-78.9c0-19.7 15.9-35.6 35.5-35.6h205.7v53.4c0 5.7 6.5 8.8 10.9 5.3l109.1-85.7c3.5-2.7 3.5-8 0-10.7l-109.1-85.7c-4.4-3.5-10.9-.3-10.9 5.3V338H397.7c-55.1 0-99.7 44.8-99.7 100.1V517c0 4 3 7 6.8 7zm-4.2 134.9l109.1 85.7c4.4 3.5 10.9.3 10.9-5.3v-53.4h205.7c55.1 0 99.7-44.8 99.7-100.1v-78.9c0-3.7-3-6.8-6.8-6.8h-50.7c-3.7 0-6.8 3-6.8 6.8v78.9c0 19.7-15.9 35.6-35.5 35.6H420.6V568c0-5.7-6.5-8.8-10.9-5.3l-109.1 85.7c-3.5 2.5-3.5 7.8 0 10.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">Interactive</p>
                    </div>
                    <div (click)="interactiveClick()" *ngIf="interactiveLink" class="PageButtonSelected">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                                height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM304.8 524h50.7c3.7 0 6.8-3 6.8-6.8v-78.9c0-19.7 15.9-35.6 35.5-35.6h205.7v53.4c0 5.7 6.5 8.8 10.9 5.3l109.1-85.7c3.5-2.7 3.5-8 0-10.7l-109.1-85.7c-4.4-3.5-10.9-.3-10.9 5.3V338H397.7c-55.1 0-99.7 44.8-99.7 100.1V517c0 4 3 7 6.8 7zm-4.2 134.9l109.1 85.7c4.4 3.5 10.9.3 10.9-5.3v-53.4h205.7c55.1 0 99.7-44.8 99.7-100.1v-78.9c0-3.7-3-6.8-6.8-6.8h-50.7c-3.7 0-6.8 3-6.8 6.8v78.9c0 19.7-15.9 35.6-35.5 35.6H420.6V568c0-5.7-6.5-8.8-10.9-5.3l-109.1 85.7c-3.5 2.5-3.5 7.8 0 10.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Interactive</p>
                    </div>
                </div>

                <div (click)="templatesClick()" *ngIf="!templatesLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="20px"
                            width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Templates</p>
                </div>
                <div (click)="templatesClick()" *ngIf="templatesLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="20px"
                            width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Templates</p>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="apiClick()" *ngIf="!apiLink" class="PageButton">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M14 12l-2 2-2-2 2-2 2 2zm-2-6l2.12 2.12 2.5-2.5L12 1 7.38 5.62l2.5 2.5L12 6zm-6 6l2.12-2.12-2.5-2.5L1 12l4.62 4.62 2.5-2.5L6 12zm12 0l-2.12 2.12 2.5 2.5L23 12l-4.62-4.62-2.5 2.5L18 12zm-6 6l-2.12-2.12-2.5 2.5L12 23l4.62-4.62-2.5-2.5L12 18z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">API</p>
                    </div>
                    <div (click)="apiClick()" *ngIf="apiLink" class="PageButtonSelected">
                        <div>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path
                                    d="M14 12l-2 2-2-2 2-2 2 2zm-2-6l2.12 2.12 2.5-2.5L12 1 7.38 5.62l2.5 2.5L12 6zm-6 6l2.12-2.12-2.5-2.5L1 12l4.62 4.62 2.5-2.5L6 12zm12 0l-2.12 2.12 2.5 2.5L23 12l-4.62-4.62-2.5 2.5L18 12zm-6 6l-2.12-2.12-2.5 2.5L12 23l4.62-4.62-2.5-2.5L12 18z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">API</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="usersClick()" *ngIf="!usersLink" class="PageButton">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" stroke-width="2"
                                    d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">Users</p>
                    </div>
                    <div (click)="usersClick()" *ngIf="usersLink" class="PageButtonSelected">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" stroke-width="2"
                                    d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Users</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="groupsClick()" *ngIf="!groupsLink" class="PageButton">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" _ngcontent-ng-c2421312070="" stroke="currentColor"
                                fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px"
                                xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" _ngcontent-ng-c2421312070="" fill="none"
                                    stroke-width="2"
                                    d="M12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 Z M6,22 L6,19 C6,15.6862915 8.6862915,13 12,13 C15.3137085,13 18,15.6862915 18,19 L18,22 M13,5 C13.4037285,3.33566165 15.0151447,2 17,2 C19.172216,2 20.98052,3.790861 21,6 C20.98052,8.209139 19.172216,10 17,10 L16,10 L17,10 C20.287544,10 23,12.6862915 23,16 L23,18 M11,5 C10.5962715,3.33566165 8.98485529,2 7,2 C4.82778404,2 3.01948003,3.790861 3,6 C3.01948003,8.209139 4.82778404,10 7,10 L8,10 L7,10 C3.71245602,10 1,12.6862915 1,16 L1,18">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">Templating Groups</p>
                    </div>
                    <div (click)="groupsClick()" *ngIf="groupsLink" class="PageButtonSelected">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" _ngcontent-ng-c2421312070="" stroke="currentColor"
                                fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px"
                                xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" _ngcontent-ng-c2421312070="" fill="none"
                                    stroke-width="2"
                                    d="M12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 Z M6,22 L6,19 C6,15.6862915 8.6862915,13 12,13 C15.3137085,13 18,15.6862915 18,19 L18,22 M13,5 C13.4037285,3.33566165 15.0151447,2 17,2 C19.172216,2 20.98052,3.790861 21,6 C20.98052,8.209139 19.172216,10 17,10 L16,10 L17,10 C20.287544,10 23,12.6862915 23,16 L23,18 M11,5 C10.5962715,3.33566165 8.98485529,2 7,2 C4.82778404,2 3.01948003,3.790861 3,6 C3.01948003,8.209139 4.82778404,10 7,10 L8,10 L7,10 C3.71245602,10 1,12.6862915 1,16 L1,18">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Templating Groups</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="roleslick()" *ngIf="!rolesLink" class="PageButton">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" stroke-width="2"
                                    d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M14.6431888,15.6961461 C14.3091703,14.6675626 13.7524493,13.7598949 13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L11,23 M12,18.8235294 L16.1904762,22 L23,13">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonText">Templating Roles</p>
                    </div>
                    <div (click)="roleslick()" *ngIf="rolesLink" class="PageButtonSelected">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" stroke-width="2"
                                    d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M14.6431888,15.6961461 C14.3091703,14.6675626 13.7524493,13.7598949 13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L11,23 M12,18.8235294 L16.1904762,22 L23,13">
                                </path>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Templating Roles</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="workflowClick()" *ngIf="!workflowLink" class="PageButton">
                        <div>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                                stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="8" height="8" x="3" y="3" rx="2"></rect>
                                <path d="M7 11v4a2 2 0 0 0 2 2h4"></path>
                                <rect width="8" height="8" x="13" y="13" rx="2"></rect>
                            </svg>
                        </div>
                        <p class="PageButtonText">Workflows</p>
                    </div>
                    <div (click)="workflowClick()" *ngIf="workflowLink" class="PageButtonSelected">
                        <div>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                                stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect width="8" height="8" x="3" y="3" rx="2"></rect>
                                <path d="M7 11v4a2 2 0 0 0 2 2h4"></path>
                                <rect width="8" height="8" x="13" y="13" rx="2"></rect>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Workflows</p>
                    </div>
                </div>

                <div *ngIf="isAdmin">
                    <div (click)="poolsClick()" *ngIf="!poolsLink" class="PageButton">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" d="M0 0h24v24H0V0z"></path>
                                <path _ngcontent-ng-c2785473262=""
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                                </path>
                                <circle _ngcontent-ng-c2785473262="" cx="8" cy="14" r="2"></circle>
                                <circle _ngcontent-ng-c2785473262="" cx="12" cy="8" r="2"></circle>
                                <circle _ngcontent-ng-c2785473262="" cx="16" cy="14" r="2"></circle>
                            </svg>
                        </div>
                        <p class="PageButtonText">Workflow Pools</p>
                    </div>
                    <div (click)="poolsClick()" *ngIf="poolsLink" class="PageButtonSelected">
                        <div>
                            <svg _ngcontent-ng-c2785473262="" stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ng-c2785473262="" fill="none" d="M0 0h24v24H0V0z"></path>
                                <path _ngcontent-ng-c2785473262=""
                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                                </path>
                                <circle _ngcontent-ng-c2785473262="" cx="8" cy="14" r="2"></circle>
                                <circle _ngcontent-ng-c2785473262="" cx="12" cy="8" r="2"></circle>
                                <circle _ngcontent-ng-c2785473262="" cx="16" cy="14" r="2"></circle>
                            </svg>
                        </div>
                        <p class="PageButtonSelectedText">Workflow Pools</p>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <div class="PageSection">
            <app-account-settings *ngIf="accountSettingLink"></app-account-settings>
            <app-queue-settings *ngIf="queuesLink"></app-queue-settings>
            <app-interactive-settings *ngIf="interactiveLink"></app-interactive-settings>
            <app-template-settings *ngIf="templatesLink"></app-template-settings>
            <app-api-settings *ngIf="apiLink"></app-api-settings>
            <app-workflows *ngIf="workflowLink"></app-workflows>
            <app-users *ngIf="usersLink"></app-users>
            <app-groups *ngIf="groupsLink"></app-groups>
            <app-roles *ngIf="rolesLink"></app-roles>
            <app-pools *ngIf="poolsLink"></app-pools>

        </div>
    </div>
</div>