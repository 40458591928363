import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { WebMethodsService } from '../../web-methods.service';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-account-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    FormsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  name: string;
  username: string;
  email: string;
  userID: string;
  mobile: string;
  newMobile: string;
  permCGAdmin = false;
  permAdmin = false;
  permArchive = false;
  permInteractive = false;
  permTemplate = false;
  permView = false;
  rolesDivData = [""];
  rolesLoading = false;
  tenantList = [""];
  groupsList = [];
  poolsList = [];
  emailNotification = false;
  smsNotification = false;
  waNotification = false;



  nPassword = "";
  rPassword = "";
  passwordChangeError = "";
  passwordChangeMessage = "";
  changePasswordLoading = false;
  mfaLoading = false;
  emailNotifLoading = false;
  phoneUpdateLoading = false;
  phoneValid = true;
  mfaStatus = "";
  OTPDevice = "";
  OTPCredID = "";

  editMobile = false;


  ngOnInit(): void {
    this.tenantList = [];
    this.emailNotifLoading = true;
    let tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log(tenants);
    tenants.map((item: any, index: number) => { this.tenantList.push(item.tenantItem) });
    // console.log(this.tenantList);
    // this.oidcSecurityService.checkAuth().subscribe((data)=> {
    //   console.log("Check Auth data");
    //   console.log(data);
    // })
    this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
      this.oidcSecurityService.getPayloadFromAccessToken().subscribe((data) => {
        this.emailNotifLoading = false;
        this.mobile = data.phone_number;
        var emailEnable = data.EmailNotification;
        if (emailEnable == "true") {
          this.emailNotification = true;
        }
        else {
          this.emailNotification = false;
        }

        var waEnable = data.WANotification;
        if (waEnable == "true") {
          this.waNotification = true;
        }
        else {
          this.waNotification = false;
        }

        var smsEnable = data.SMSNotification;
        if (smsEnable == "true") {
          this.smsNotification = true;
        }
        else {
          this.smsNotification = false;
        }
      });
    });


    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.name = userData.name;
      this.username = userData.preferred_username;
      this.email = userData.email;
      this.userID = userData.sub;
      // this.mobile = userData.phone_number;
      this.rolesLoading = true;
      if(userData.GR != undefined){
        this.groupsList = userData.GR;
      }
      this.poolsList = userData.Pool;
      this.rolesDivData = [];



      userData.groups.forEach((element: string) => {
        if (element == "CGAdmin") {
          this.permCGAdmin = true;
        }
        else if (element == "Admin") {
          this.permAdmin = true;
        }
        else if (element == "DocumentArchive") {
          this.permArchive = true;
        }
        else if (element == "InteractiveUser") {
          this.permInteractive = true;
        }
        else if (element == "TemplateUser") {
          this.permTemplate = true;
        }
        else if (element == "ViewUser") {
          this.permView = true;
        }
      });




      this.api.postService("/settings/listroles", { Username: "" })
        .then((response) => {
          // console.log(response);
          this.rolesLoading = false;
          if (response.status == 200) {
            var data = response.data;
            data.sort((a: any, b: any) => {
              return a.RoleName - b.RoleName;
            });
            data.forEach((element: any) => {
              if (userData.SCRole != undefined) {
                if (userData.SCRole.find((elementFind: string) => elementFind == element.Name)) {
                  this.rolesDivData.push(element.Description);
                }
              }
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          this.rolesLoading = false;
        });
    });
    this.getOTPStatus();
  }

  getOTPStatus() {
    this.mfaLoading = true;
    this.api.postService("/um/userotpconfig", { "Username": this.userID })
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            var bodyData = JSON.parse(response.data.body);
            if (bodyData.length > 0) {
              if (bodyData[0].type == "config_otp") {
                this.mfaStatus = "Setup";
              }
              else {
                this.mfaStatus = "Avail";
                this.OTPDevice = bodyData[0].userLabel;
                this.OTPCredID = bodyData[0].id;
              }
            }
            else {
              this.mfaStatus = "Enable";
            }
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get MFA Status", "A connection error has occured. Please contact support for assistance.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get MFA Status", "Could not get MFA Statuse.");
        }
        this.mfaLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get MFA Status", "Could not get MFA Status.");
        this.mfaLoading = false;
      })
  }

  enableMFAClick() {
    this.mfaLoading = true;
    this.mfaStatus = "";
    this.api.postService("/um/enableotp", { "Username": this.userID })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.statusCode != 200) {
            // console.log("Error");
            this.toastTemplates.showErrorToast("API error - Enable MFA", "Could not enable MFA.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Enable MFA", "Could not enable MFA.");
        }
        this.getOTPStatus();
      })
      .catch((err) => {
        this.toastTemplates.showErrorToast("API error - Enable MFA", "Could not enable MFA.");
        // console.log(err);
      })
  }

  disableMFAClick() {
    this.mfaLoading = true;
    this.mfaStatus = "";
    this.api.postService("/um/disableotp", { "Username": this.userID, "CredID": this.OTPCredID })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.statusCode != 200) {
            // console.log("Error");
            this.toastTemplates.showErrorToast("API error - Disable MFA", "Could not disable MFA.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Disable MFA", "Could not disable MFA.");
        }
        this.getOTPStatus();
      })
      .catch((err) => {
        this.toastTemplates.showErrorToast("API error - Disable MFA", "Could not disable MFA.");
        // console.log(err);
      })
  }

  changePassword() {
    this.passwordChangeError = "";
    this.passwordChangeMessage = ""
    if (this.nPassword == "") {
      this.passwordChangeError = "Please enter a password"
    }
    else if (this.nPassword != this.rPassword) {
      this.passwordChangeError = "Passwords do not match"
    }
    else {
      this.changePasswordLoading = true;
      this.passwordChangeError = "";
      this.api.postService("/um/resetpassword", { "Username": this.userID, "password": this.nPassword })
        .then((response) => {

          if (response.status == 200) {
            if (response.data.statusCode == 200) {
              this.nPassword = "";
              this.rPassword = "";
              this.passwordChangeMessage = "Your password has been updated";
            }
            else {
              this.passwordChangeError = JSON.parse(JSON.parse(response.data.body)).error_description;
            }
          }
          else {
            this.toastTemplates.showErrorToast("API error - Change password", "Could not change password.");
          }
          this.changePasswordLoading = false;
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Change password", "Could not change password.");
          this.changePasswordLoading = false;
        })
    }
  }

  updateNotifications(event: any, type: any) {
    // console.log(event);
    this.emailNotifLoading = true;
    this.api.postService("/um/updateusernotification", { "userID": this.userID, updateType: type, "notification": event.checked })
      .then((response) => {
        this.emailNotifLoading = false;
        if (response.status == 200) {
          this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
            this.oidcSecurityService.getPayloadFromAccessToken().subscribe((data) => {
              this.emailNotifLoading = false;
              var emailEnable = data.EmailNotification;
              if (emailEnable == "true") {
                this.emailNotification = true;
              }
              else {
                this.emailNotification = false;
              }
              var waEnable = data.WANotification;
              if (waEnable == "true") {
                this.waNotification = true;
              }
              else {
                this.waNotification = false;
              }

              var smsEnable = data.SMSNotification;
              if (smsEnable == "true") {
                this.smsNotification = true;
              }
              else {
                this.smsNotification = false;
              }
            });
          });
        }
        else {
          this.toastTemplates.showErrorToast("API error - Enable MFA", "Could not enable MFA.");
        }
      })
      .catch((err) => {
        this.toastTemplates.showErrorToast("API error - Enable MFA", "Could not enable MFA.");
        // console.log(err);
      })
  }

  editMobileButton() {
    this.newMobile = this.mobile;
    this.editMobile = true;
    this.phoneValid = true;
  }

  updateMobileButton() {
    if (this.phoneValid) {
      this.phoneUpdateLoading = true;

      this.api.postService("/um/updateusernotification", { "userID": this.userID, updateType: 'mobile', "notification": this.newMobile })
        .then((response) => {
          this.emailNotifLoading = false;
          if (response.status == 200) {
            this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
              this.oidcSecurityService.getPayloadFromAccessToken().subscribe((data) => {
                this.mobile = data.phone_number;
                this.phoneUpdateLoading = false;
                this.editMobile = false;

              });
            });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Update Mobile", "Could not update mobile number.");
          }
        })
        .catch((err) => {
          this.toastTemplates.showErrorToast("API error - Update Mobile", "Could not update mobile number.");
          // console.log(err);
        })
    }
  }
  cancelMobileButton() {
    this.editMobile = false;
  }

  checkMobileValid(event: any) {
    // console.log(event);
    // console.log(event.target.validity);
    this.phoneValid = !event.target.validity.patternMismatch;

  }
}
