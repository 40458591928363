<div style="user-select: none; width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center;">
    <div style="margin: 10px; width: 100%; display: flex; flex-direction: row; justify-content: center;">
        <p style="color: #021857; font-weight: bold; font-size: 20px;">File Info</p>
    </div>
    <div style="margin: 10px; width: 100%; display: flex; flex-direction: row; justify-content: flex-start;">
        <table style="margin: 10px;">
            <tr>
                <td style="width: 200px;">
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">File Name:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;">{{data.file.label}}</p>
                </td>
            </tr>
            <tr>
                <td style="width: 150px;">
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Project ID:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="projectID != 'loading'">{{projectID}}</p>
                    <mat-spinner diameter="20" *ngIf="projectID == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!projectID">No Project ID Provided</p>
                </td>
            </tr>
            <tr>
                <td style="width: 150px;">
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Project Name:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="projectName != 'loading'">{{projectName}}</p>
                    <mat-spinner diameter="20" *ngIf="projectName == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!projectName">No Project Found</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Template Selector ID:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="templateSelector != 'loading'">{{templateSelector}}</p>
                    <mat-spinner diameter="20" *ngIf="templateSelector == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!templateSelector">No Template Selector Provided</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Template Selector Name:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="templateSelectorName != 'loading'">{{templateSelectorName}}</p>
                    <mat-spinner diameter="20" *ngIf="templateSelectorName == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!templateSelectorName">No Template Selector Found</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Workflow ID:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="workflow != 'loading'">{{workflow}}</p>
                    <mat-spinner diameter="20" *ngIf="workflow == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!workflow">No Workflow Provided</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Workflow Name:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="workflowName != 'loading'">{{workflowName}}</p>
                    <mat-spinner diameter="20" *ngIf="workflowName == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!workflowName">No Workflow Found</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="color: #021857; font-weight: bold; font-size: 14px;">Queue:</p>
                </td>
                <td>
                    <p style="color: #000000; font-size: 14px;" *ngIf="queueName != 'loading'">{{queueName}}</p>
                    <mat-spinner diameter="20" *ngIf="queueName == 'loading'"></mat-spinner>
                    <p style="color: #000000; font-size: 14px; font-weight: bold;" *ngIf="!queueName">No Queue Provided</p>
                </td>
            </tr>

        </table>
    </div>

    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="primary" mat-dialog-close>Close</button>
    </div>
</div>