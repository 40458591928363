import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { WebMethodsService } from '../web-methods.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  message = "";
  messageInfo = "";
  messageError = "";
  userEmail: string;
  userName: string;
  userSub: string;

  ngOnInit(): void {
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.userName = userData.name;
      this.userEmail = userData.email;
      this.userSub = userData.sub;
    });
  }



  clearMessage() {
    this.message = "";
  }
  sendMessage() {
    if (this.message == "") {
      this.messageError = "Please add a message and send again."
    }
    else {
      var request = {
        FromEmail: this.userEmail,
        FromName: this.userName,
        UserID: this.userSub,
        UserMessage: this.message
      }
      this.api.postService("/mail/supportmessage", request)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.messageInfo = "Message Sent";
            this.message = "";
            this.messageError = "";
          }
          else {
            this.messageInfo = "";
            this.messageError = "Message could not be sent.";
          }

        })
        .catch((err) => {
          this.toastTemplates.showErrorToast("API error - Send email", "Email could not be sent.");
          console.log(err);
        })
    }
  }
}
