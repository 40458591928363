import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss'
})
export class RolesComponent implements OnInit {
  constructor(public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'description'];
  roles: any = [];
  dataSource = new MatTableDataSource(this.roles);
  rolesLoading = false;

  ngOnInit(): void {
    this.rolesLoading = true;
    this.api.postService("/um/getroles", { "none": "" })
      .then((response) => {
        this.rolesLoading = false;
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            // console.log(JSON.parse(response.data.body));
            var respRoles = JSON.parse(response.data.body);
            var rolesFiltered: any[] = [];
            for (var a = 0; a < respRoles.length; a++) {
              if ((respRoles[a].name == "1") ||
                (respRoles[a].name == "2") ||
                (respRoles[a].name == "3") ||
                (respRoles[a].name == "4") ||
                (respRoles[a].name == "5") ||
                (respRoles[a].name == "6") ||
                (respRoles[a].name == "7") ||
                (respRoles[a].name == "8") ||
                (respRoles[a].name == "9") ||
                (respRoles[a].name == "10") ||
                (respRoles[a].name == "11") ||
                (respRoles[a].name == "12") ||
                (respRoles[a].name == "13") ||
                (respRoles[a].name == "14") ||
                (respRoles[a].name == "15") ||
                (respRoles[a].name == "16") ||
                (respRoles[a].name == "17") ||
                (respRoles[a].name == "18") ||
                (respRoles[a].name == "19") ||
                (respRoles[a].name == "20")) {
                rolesFiltered.push(respRoles[a]);
              }
            }
            rolesFiltered.sort(this.sortData);
            this.roles = rolesFiltered;
            this.dataSource = new MatTableDataSource(this.roles);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get Roles", "Could not get roles.");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Roles", "Could not get roles.");
        }
      })
      .catch((err) => {
        console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Roles", err);
        this.rolesLoading = false;
      });
  }

  sortData(a: any, b: any) {
    return parseInt(a.name) - parseInt(b.name);
  }
}