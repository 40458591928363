<div>
    <mat-card class="userProfileCard">
        <mat-card-content>
            <div class="sidebarWrapSettings">
                <p class="subHeadingInSettings">API Keys</p>
            </div>
            <div class="sidebarWrapSettings" *ngIf="!apiKeysLoading">



                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p class="itemHeading">External ID</p>
                                <div class="mainTextbox valueTextbox">
                                    <input class="internalTextBox" [value]="externalID" disabled>
                                    <button aria-label="Copy" class="copyButton" style="width: 25px !important;" 
                                        [cdkCopyToClipboard]="externalID" (click)="tooltip1.show()">
                                        <span #tooltip1="matTooltip" matTooltip="Copied to clipboard" matTooltipPosition="above" matTooltipTouchGestures="off" matTooltipShowDelay="0" matTooltipHideDelay="500"></span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            <path
                                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Client Name</p>
                                <div class="mainTextbox valueTextbox">
                                    <input class="internalTextBox" [value]="clientName" disabled>
                                    <button aria-label="Copy" class="copyButton" style="width: 25px !important;"
                                        [cdkCopyToClipboard]="clientName" (click)="tooltip2.show()">
                                        <span #tooltip2="matTooltip" matTooltip="Copied to clipboard" matTooltipPosition="above" matTooltipTouchGestures="off" matTooltipShowDelay="0" matTooltipHideDelay="500"></span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            <path
                                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Client ID</p>
                                <div class="mainTextbox valueTextbox">
                                    <input class="internalTextBox" [value]="clientID" disabled>
                                    <button aria-label="Copy" class="copyButton" style="width: 25px !important;"
                                        [cdkCopyToClipboard]="clientID" (click)="tooltip3.show()">
                                        <span #tooltip3="matTooltip" matTooltip="Copied to clipboard" matTooltipPosition="above" matTooltipTouchGestures="off" matTooltipShowDelay="0" matTooltipHideDelay="500"></span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            <path
                                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Client Secret</p>
                                <div class="mainTextbox valueTextbox">
                                    <input class="internalTextBox" [value]="clientSecret"  [type]="showClientSecret" disabled>
                                    <button (click)="showHideSecret()" aria-label="Show" class="copyButton" style="width: 25px !important;" >
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 512 512" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z">
                                            </path>
                                        </svg>
                                    </button>
                                    <button aria-label="Copy" class="copyButton" style="width: 25px !important;"
                                        [cdkCopyToClipboard]="clientSecret" (click)="tooltip4.show()">
                                        <span #tooltip4="matTooltip" matTooltip="Copied to clipboard" matTooltipPosition="above" matTooltipTouchGestures="off" matTooltipShowDelay="0" matTooltipHideDelay="500"></span>
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 24 24" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                                            <path
                                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Access Token Expiration</p>
                                <input class="mainTextbox valueTextbox" [value]="tokenExpire" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Scopes</p>
                                <input class="mainTextbox valueTextbox" [value]="scopes" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">Allowed OAuth Flows</p>
                                <input class="mainTextbox valueTextbox" [value]="flows" disabled>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="itemHeading">OAuth2 Token URL</p>
                                <input class="mainTextbox valueTextbox" [value]="tokenURL" disabled>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
        <mat-card-footer *ngIf="apiKeysLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
    </mat-card>
</div>