import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { WebMethodsService } from '../../web-methods.service';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { ClipboardModule } from '@angular/cdk/clipboard';

export interface DialogData {
  workflowName: string;
  workflowDescription: string;
  wfPools: any;
}


@Component({
  selector: 'app-workflows',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatCardModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    ClipboardModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './workflows.component.html',
  styleUrl: './workflows.component.scss'
})
export class WorkflowsComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  wfLoading = false;

  displayedColumns: string[] = ['id', 'workflowName', 'workflowDescription', 'WorkflowPreview', 'action'];
  workflows: any = [];
  dataSource = new MatTableDataSource(this.workflows);
  filterValue = '';


  ngOnInit(): void {
    this.getWorkflows();
  }

  createWorkflow() {
    const dialogRef = this.dialog.open(AddWorkflowDialog, { disableClose: true, width: 'fit-content', data: { workflowName: "", workflowDescription: "", wfPools: [] } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.wfLoading = true;
        // console.log(result);
        var request = result;
        var tenantID = localStorage.getItem('currentTenant');
        request.TenantID = tenantID;
        this.api.postService("/settings/addworkflow", request)
          .then((response) => {
            this.wfLoading = false;
            // console.log(response);
            if (response.status == 200) {
              this.getWorkflows();
            }
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Add Workflow", err);
            this.wfLoading = false;
          });
      }
      else {
        // alert("Cancelled");
      }

    });
  }

  getWorkflows() {
    this.workflows = [];
    this.wfLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
      .then((response) => {
        this.wfLoading = false;
        // console.log(response);
        if (response.status == 200) {
          this.workflows = response.data;
          this.dataSource = new MatTableDataSource(this.workflows);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Add Workflow", err);
        this.wfLoading = false;
      });
  }


  deleteWorkflow(wfItem: any) {
    // console.log(wfItem);
    const dialogRef = this.dialog.open(DeleteWorkflowDialog, { disableClose: true, width: 'fit-content', data: wfItem });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.api.postService("/settings/deleteworkflow", { "WorkflowID": result.id })
          .then((response) => {
            this.wfLoading = false;
            // console.log(response);
            if (response.status == 200) {
              this.getWorkflows();
            }
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Delete Workflow", err);
            this.wfLoading = false;
          });
      }
    });
  }

  editWorkflow(wfItem: any) {
    // console.log(wfItem);
    // var wfItemNew = {
    //   id: wfItem.id,
    //   wfName: wfItem.workflowName,
    //   wfDescription: wfItem.workflowDescription,
    //   workflowPools: wfItem.wfPools
    // };
    this.workflows = [];
    const dialogRef = this.dialog.open(EditWorkflowDialog, { disableClose: true, width: 'fit-content', data: wfItem });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.wfLoading = true;
        // console.log(result);
        var request = result;
        var tenantID = localStorage.getItem('currentTenant');
        request.TenantID = tenantID;
        this.api.postService("/settings/updateworkflow", request)
          .then((response) => {
            this.wfLoading = false;
            // console.log(response);
            if (response.status == 200) {
              this.getWorkflows();
            }
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Add Workflow", err);
            this.wfLoading = false;
          });
      }
      else {
        // alert("Cancelled");
        this.getWorkflows();
      }

    });
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}

@Component({
  selector: 'add-workflow-dialog',
  templateUrl: 'add-workflow-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    CdkDropList,
    CdkDrag,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  styleUrl: './workflows.component.scss'
})
export class AddWorkflowDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddWorkflowDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  ngOnInit(): void {
    this.getPools();
  }
  emptyName = false;
  emptyWorkflow = false;
  availPools: any = [];
  poolsLoading = false;
  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.checkWorkflow();
  }


  checkName() {
    if (this.data.workflowName == '') {
      this.emptyName = true;
    }
    else {
      this.emptyName = false;
    }
  }
  checkWorkflow() {
    if (this.data.wfPools.length == 0) {
      this.emptyWorkflow = true;
    }
    else {
      this.emptyWorkflow = false;
    }
  }

  getPools() {
    this.poolsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listpools", { tenantID: tenantID })
      .then((response) => {
        this.poolsLoading = false;
        // console.log(response);
        if (response.status == 200) {
          // console.log(response.data);
          var tenantID = localStorage.getItem('currentTenant');
          this.availPools = [];
          response.data.forEach((element: any) => {
            if (element.TenantID == tenantID) {
              this.availPools.push(element);
            }
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Pools", err);
        this.poolsLoading = false;
      });
  }
  save() {
    this.checkName();
    this.checkWorkflow();

    if (this.data.workflowName == '') {
      //this.toastTemplates.showErrorToast("Name cannot be empty", "Please provide a name for this workflow");
    }
    else if (this.data.wfPools.length == 0) {
    }
    else {
      this.dialogRef.close(this.data);
    }
  }
}

@Component({
  selector: 'edit-workflow-dialog',
  templateUrl: 'edit-workflow-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    CdkDropList,
    CdkDrag,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  styleUrl: './workflows.component.scss'
})
export class EditWorkflowDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<EditWorkflowDialog>, @Inject(MAT_DIALOG_DATA) private dataPriv: DialogData, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  ngOnInit(): void {
    this.getPools();
  }
  data: DialogData = this.dataPriv;
  emptyName = false;
  emptyWorkflow = false;
  availablePools: any = [];
  poolsLoading = false;
  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.checkWorkflow();
  }


  checkName() {
    if (this.data.workflowName == '') {
      this.emptyName = true;
    }
    else {
      this.emptyName = false;
    }
  }
  checkWorkflow() {
    if (this.data.wfPools.length == 0) {
      this.emptyWorkflow = true;
    }
    else {
      this.emptyWorkflow = false;
    }
  }

  getPools() {
    this.poolsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listpools", { tenantID: tenantID })
      .then((response) => {
        this.poolsLoading = false;
        if (response.status == 200) {
          var tenantID = localStorage.getItem('currentTenant');
          this.availablePools = [];
          response.data.forEach((element: any) => {
            if (element.TenantID == tenantID) {
              var itemfound = this.data.wfPools.find((elementPool: any) => { return elementPool.id == element.id; })
              if (itemfound == undefined) {
                this.availablePools.push(element);
              }
            }
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Pools", err);
        this.poolsLoading = false;
      });
  }
  save() {
    this.checkName();
    this.checkWorkflow();

    if (this.data.workflowName == '') {
      //this.toastTemplates.showErrorToast("Name cannot be empty", "Please provide a name for this workflow");
    }
    else if (this.data.wfPools.length == 0) {
    }
    else {
      this.dialogRef.close(this.data);
    }
  }
}

@Component({
  selector: 'delete-workflow-confirm-dialog',
  templateUrl: 'delete-workflow-confirm-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
  ],
  styleUrl: './workflows.component.scss'
})
export class DeleteWorkflowDialog {
  constructor(public dialogRef: MatDialogRef<DeleteWorkflowDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

}