import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ToastsContainer } from '../toast/toasts-container.component';
import { Toast } from '../toast/toast-service';
import { TemplateStore } from '../toast/template-store';

@Component({
    selector: 'ngbd-toast-global',
    standalone: true,
    imports: [],
    templateUrl: './toast-global.component.html',
})

export class NgbdToastGlobal {
    constructor(private toastContainer: ToastsContainer, public templateStore: TemplateStore) { }
    showStandardToast(header: string, message: any) {
        var toast1: Toast = {
            template: this.templateStore.get('standardTpl'),
            delay: 50000,
            header: header,
            body: message
        };
        this.toastContainer.toastService.show(toast1);
    }
    showSuccessToast(header: string, message: string) {
        var toast1: Toast = {
            template: this.templateStore.get('successTpl'),
            delay: 5000,
            header: header,
            classname: 'bg-success text-light',
            body: message
            
        };
        this.toastContainer.toastService.show(toast1);
    }
    showErrorToast(header: string, message: any) {
        
        var toast1: Toast = {
            template: this.templateStore.get('dangerTpl'),
            delay: 5000,
            header: header,
            classname: 'bg-danger text-light',
            body: message
        };
        this.toastContainer.toastService.show(toast1);
    }
    showNotificationToast(header: string, message: any) {
        
        var toast1: Toast = {
            template: this.templateStore.get('notifTpl'),
            delay: 5000,
            header: header,
            classname: 'toastNotification text-light',
            body: message
        };
        this.toastContainer.toastService.show(toast1);
    }
}
