import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.authority,
        redirectUrl: window.location.href,
        postLoginRoute: window.location.pathname+window.location.search,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.client_id,
        triggerAuthorizationResultEvent: false,
        renewTimeBeforeTokenExpiresInSeconds: 5,
        scope: 'openid offline_access',
        responseType: 'id_token token',
        silentRenew: true,
        useRefreshToken: true,
        silentRenewUrl: window.location.origin+"/silent-renew.html",
        
        ignoreNonceAfterRefresh: true,
        logLevel: LogLevel.Debug,
        customParamsCodeRequest: {
          client_secret: environment.client_secret,
          redirect_uri: window.location.origin,
        },
        customParamsRefreshTokenRequest: {
          client_secret: environment.client_secret,
          redirect_uri: window.location.origin,
          redirectUrl: window.location.origin
        },
        customParamsEndSessionRequest: {
          client_secret: environment.client_secret,
        }
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}