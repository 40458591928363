import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import { WebMethodsService } from './web-methods.service';
import {
  OidcSecurityService
} from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from './toast/toast-global.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private _notificationItems = new BehaviorSubject<[]>([]);
  notificationItems$: Observable<[]>;
  constructor(private api: WebMethodsService, public oidcSecurityService: OidcSecurityService, private toastTemplates: NgbdToastGlobal) {
    this.notificationItems$ = this._notificationItems.asObservable();
  }
  updateNotifications(newValue: any): void {
    this._notificationItems.next(newValue);
  }
  getNotifications(): void {
    var userID = "";
    var tenantID = localStorage.getItem("currentTenant");
    this.oidcSecurityService.getUserData().subscribe((userData: any) => {
      userID = userData.sub;
    });
    this.api.postService("/notifications/getNotifications", { userID: userID, tenantID: tenantID })
      .then((response) => {
        if (response.status == 200) {
          var localNotifications: any = [];
          response.data.forEach((element: any) => {
            var dateNow = new Date(element.DateTime).toISOString().split("T")[0];
            var timeNow = new Date(element.DateTime).toLocaleTimeString();
            localNotifications.push({
              id: element.id,
              header: element.Header,
              body: element.Body,
              date: dateNow + " " + timeNow,
              Read: element.Read, 
              Link: element.Link
            });
          });
          this._notificationItems.next(localNotifications);
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Notification", "A connection error has occured. Please contact support for assistance.");
        }

      });
  }
}
