<div class="options-sidenav-content" style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Document Archive</h3>
        <div class="divHeaderLine">
            <button mat-mini-fab color="primary" (click)="drawer.toggle()">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px"
                    width="20px" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M32 384h272v32H32zM400 384h80v32h-80zM384 447.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z">
                    </path>
                    <g>
                        <path
                            d="M32 240h80v32H32zM208 240h272v32H208zM192 303.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z">
                        </path>
                    </g>
                    <g>
                        <path
                            d="M32 96h272v32H32zM400 96h80v32h-80zM384 159.5c0 17.949-14.327 32.5-32 32.5-17.673 0-32-14.551-32-32.5v-95c0-17.949 14.327-32.5 32-32.5 17.673 0 32 14.551 32 32.5v95z">
                        </path>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</div>

<mat-drawer-container class="options-container" hasBackdrop="false">

    <mat-drawer #drawer class="options-sidenav" mode="side" position="end" [opened]="searchOpened">
        <p class="optionsHeading" style="padding-top: 12px;">Options</p>
        <br>
        <!-- <mat-form-field style="width: 268px;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="readArchive()">
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="readArchive()">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            @if (range.controls.start.hasError('matStartDateInvalid')) {
            <mat-error>Invalid start date</mat-error>
            }
            @if (range.controls.end.hasError('matEndDateInvalid')) {
            <mat-error>Invalid end date</mat-error>
            }
        </mat-form-field> -->

        <app-advanced-date-selection [date]="initDateTime" (dateChanged)="dateUpdated($event)"></app-advanced-date-selection>
        <br>
        <mat-form-field style="width: 268px;">
            <mat-label>Filter</mat-label>
            <mat-select [formControl]="filter" (selectionChange)="filterChange($event)">
                <mat-option value="Document">Document</mat-option>
                <mat-option value="Job">Job</mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <div *ngIf="slectedFilter == 'Document'">
            <mat-form-field style="width: 268px;">
                <mat-label>Templates</mat-label>
                <mat-select [formControl]="templates" multiple [disabled]="templatesLoading">
                    <mat-select-trigger>
                        {{templates.value?.[0] || ''}}
                        @if ((templates.value?.length || 0) > 1) {
                        <span class="additional-selection">
                            (+{{(templates.value?.length || 0) - 1}} {{templates.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                        }
                    </mat-select-trigger>
                    @for (tList of templatesList; track tList) {
                    <mat-option [value]="tList">{{tList}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="templatesLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
            <br>
            <mat-form-field style="width: 268px;">
                <mat-label>Channels</mat-label>
                <mat-select [formControl]="channels" multiple [disabled]="channelsLoading">
                    <mat-select-trigger>
                        {{channels.value?.[0] || ''}}
                        @if ((channels.value?.length || 0) > 1) {
                        <span class="additional-selection">
                            (+{{(channels.value?.length || 0) - 1}} {{channels.value?.length === 2 ? 'other' :
                            'others'}})
                        </span>
                        }
                    </mat-select-trigger>
                    @for (cList of channelsList; track cList) {
                    <mat-option [value]="cList">{{cList}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="channelsLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
            <br>
            <mat-form-field style="width: 268px;">
                <mat-label>Generic search</mat-label>
                <input matInput [(ngModel)]="searchText">
            </mat-form-field>
            <br>
        </div>
        <button class="blueButton" (click)="getDocumentList()">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="15px"
                width="15px" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M456.69 421.39L362.6 327.3a173.81 173.81 0 0034.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 00327.3 362.6l94.09 94.09a25 25 0 0035.3-35.3zM97.92 222.72a124.8 124.8 0 11124.8 124.8 124.95 124.95 0 01-124.8-124.8z">
                </path>
            </svg>
            Search</button>
    </mat-drawer>


    <mat-drawer-container class="options-container" hasBackdrop="false">

        <mat-drawer #drawerDocument [opened]="documentOptionSideNavOpen" class="options-sidenav" mode="side"
            position="end" style="max-width: 300px; width: 300px;">
            <div class="flexRowHeading">
                <p class="optionsHeading" style="padding-top: 12px;">Document information</p>
                <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                    (click)="closeDocumentOption()">
                    <svg stroke="grey" fill="grey" stroke-width="0" viewBox="0 0 512 512" height="30px" width="30px"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z">
                        </path>
                    </svg>
                </button>
            </div>
            <div class="flexRow">
                <button mat-mini-fab color="primary" matTooltip="Preview document" matTooltipPosition="above"
                    [disabled]="documentActionLoading" (click)="previewDocument()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2"
                            d="M5.5,21 C7.98528137,21 10,18.9852814 10,16.5 C10,14.0147186 7.98528137,12 5.5,12 C3.01471863,12 1,14.0147186 1,16.5 C1,18.9852814 3.01471863,21 5.5,21 Z M1,16 L1,7 L1,6.5 C1,4.01471863 3.01471863,2 5.5,2 L6,2 M23,16 L23,7 L23,6.5 C23,4.01471863 20.9852814,2 18.5,2 L18,2 M18.5,21 C20.9852814,21 23,18.9852814 23,16.5 C23,14.0147186 20.9852814,12 18.5,12 C16.0147186,12 14,14.0147186 14,16.5 C14,18.9852814 16.0147186,21 18.5,21 Z M10,17 C10,17 10,15 12,15 C14,15 14,17 14,17">
                        </path>
                    </svg>
                </button>
                &nbsp;
                <button mat-mini-fab color="primary" matTooltip="Download document" matTooltipPosition="above"
                    [disabled]="documentActionLoading" (click)="downloadDocument()">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-width="2"
                            d="M2.99787498,0.999999992 L17.4999998,0.999999992 L20.9999998,4.50000005 L21,23 L3,23 L2.99787498,0.999999992 Z M16,1 L16,6 L21,6 M12,9 L12,18 M8,15 L12,19 L16,15">
                        </path>
                    </svg>
                </button>
                &nbsp;
                <button mat-mini-fab color="primary" matTooltip="Send email to yourself" matTooltipPosition="above"
                    [disabled]="documentActionLoading" (click)="resendEnvelope(true)">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M15.854.146a.5.5 0 0 1 .11.54l-2.8 7a.5.5 0 1 1-.928-.372l1.895-4.738-7.494 7.494 1.376 2.162a.5.5 0 1 1-.844.537l-1.531-2.407L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM5.93 9.363l7.494-7.494L1.591 6.602l4.339 2.76Z">
                        </path>
                        <path fill-rule="evenodd"
                            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-1.646a.5.5 0 0 1-.722-.016l-1.149-1.25a.5.5 0 1 1 .737-.676l.28.305V11a.5.5 0 0 1 1 0v1.793l.396-.397a.5.5 0 0 1 .708.708l-1.25 1.25Z">
                        </path>
                    </svg>
                </button>
                &nbsp;
                <button mat-mini-fab color="primary" matTooltip="Send email to original recipient"
                    (click)="resendEnvelope(false)" matTooltipPosition="above" [disabled]="documentActionLoading">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="20px"
                        width="20px" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M15.854.146a.5.5 0 0 1 .11.54l-2.8 7a.5.5 0 1 1-.928-.372l1.895-4.738-7.494 7.494 1.376 2.162a.5.5 0 1 1-.844.537l-1.531-2.407L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM5.93 9.363l7.494-7.494L1.591 6.602l4.339 2.76Z">
                        </path>
                        <path fill-rule="evenodd"
                            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708l-1.25-1.25Z">
                        </path>
                    </svg>
                </button>
            </div>
            <div *ngIf="documentActionLoading" style="width: 175px; padding-top: 3px;">
                <mat-card style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </div>
            <br>
            <div>
                <p><b>File Name</b></p>
                <p class="docOptionsValue">{{dispFileName}}</p>
                <br>
                <p><b>Batch ID</b></p>
                <p class="docOptionsValue">{{dispBatchID}}</p>
                <p><b>Batch Name</b></p>
                <p class="docOptionsValue">{{dispBatchName}}</p>
                <br>
                <p><b>Email Subject</b></p>
                <p class="docOptionsValue">{{dispEmailSubject}}</p>
                <p><b>Email Sent To</b></p>
                <p class="docOptionsValue">{{dispEmailTo}}</p>
                <p><b>Email Sent Status</b></p>
                <p class="docOptionsValue">{{dispEmailStatus}}</p>
                <br>
                <p><b>S3 Bucket Path</b></p>
                <p class="docOptionsValue">{{dispS3}}</p>
                <br>
                <p><b>Documents in Package</b></p>
                <ul style="padding-left: 20px;">
                    @for (item of dispDocs; track $index) {
                    <li class="docOptionsValue">{{item.fileName}}</li>
                    }
                </ul>

            </div>

        </mat-drawer>

        <div class="options-sidenav-content" style="padding: 0px; height: calc(100vh - 210px);">
            <div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="fileType" style="width: 20px;">
                        <th mat-header-cell *matHeaderCellDef style="width: 20px;"> </th>
                        <td mat-cell *matCellDef="let element" style="width: 20px;">
                            <!-- <mat-icon [svgIcon]="element.image"></mat-icon> -->
                            <img style="width: 30px;" [src]="element.image" />
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="fileName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> File Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="delDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.delDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="pages">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Page Count </th>
                        <td mat-cell *matCellDef="let element"> {{element.pages}} </td>
                    </ng-container>

                    <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
                        <td mat-cell *matCellDef="let element"> {{element.channel}} </td>
                    </ng-container>

                    <ng-container matColumnDef="template">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Template </th>
                        <td mat-cell *matCellDef="let element"> {{element.template}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsDocument; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsDocument;"
                        (click)="documentOptionButton(row)" [class.row-is-clicked]="clickedRows.has(row)"></tr>
                </table>
                <mat-card *ngIf="documentTableLoading"
                    style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
                <mat-paginator style="border-top: 1px solid rgb(207, 207, 207);" [pageSizeOptions]="[10, 20, 50, 100]"
                    showFirstLastButtons aria-label="Select page of periodic elements">
                </mat-paginator>

            </div>
        </div>

    </mat-drawer-container>

</mat-drawer-container>