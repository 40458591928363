<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>Contact Us</h3>
        <div class="divHeaderLine">

        </div>
    </div>
    <br style="user-select: none;">
    <div>
        <div class="contactUsMain">
            <div class="contactUsBlock">
                <p class="contactUsH1">CONTACT INFO</p>
                <br />
                <br />
                <p class="contactUsP1"><strong>Johannesburg: </strong>+27 10 595 9700</p>
                <br />
                <p class="contactUsP2"><strong>Email: </strong><a class="contactUsP1"
                        href="mailto:cgsales@communicationgenetics.com">cgsales&#64;communicationgenetics.com</a></p>
                <p class="contactUsP2"><strong>Support: </strong><a class="contactUsP1"
                        href="mailto:support@communicationgenetics.com">support&#64;communicationgenetics.com</a></p>
                <br />
                <br />
                <p class="contactUsH2">ADDRESS:</p>
                <br />
                <p class="contactUsP2"><strong>The Block</strong></p>
                <p class="contactUsP2"><strong>Units G8 &amp; G9</strong></p>
                <p class="contactUsP2"><strong>Langhams Lifestyle Estate</strong></p>
                <p class="contactUsP2"><strong>1 Tamchele Avenue Beverly</strong></p>
                <p class="contactUsP2"><strong>Fourways ext 27</strong></p>
                <p class="contactUsP2"><strong>Gauteng</strong></p>
                <p class="contactUsP2"><strong>2191</strong></p>
            </div>
            <div class="contactUsMainMessageBlock" style="user-select: none;">
                <p class="contactUsH1">CONTACT FORM</p>
                <br />
                <div class="contactUsMessageBlock">
                    <mat-form-field class="message-full-width">
                        <mat-label>Message</mat-label>
                        <textarea matInput class="message-height" [(ngModel)]="message"></textarea>
                    </mat-form-field>
                    <div class="flexRow">
                    <button mat-flat-button color="primary" (click)="clearMessage()">Clear</button>&nbsp;
                    <button mat-flat-button color="primary" (click)="sendMessage()">Send Message</button>
                    <p class="infoMessage">{{messageInfo}}</p>
                    <p class="errorMessage">{{messageError}}</p>
                    </div>
                    <br />
                    <p class="footnote">*This message will reach the CG Support team securely.<br /> Be sure to monitor
                        your email used to login for a response from us.</p>
                </div>
            </div>

            <div class="contactUsMainMessageBlock" style="user-select: none;">
                <p class="contactUsH1">WHERE TO FIND US</p>
                <br />
                <div class="contactUsMessageBlock">
                    <iframe title="CG_GoogleMaps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.6313743599653!2d27.92265141502738!3d-26.07830098349404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x713a92ccce8cf6c!2sCommunication%20Genetics%20(Pty)%20Ltd!5e0!3m2!1sen!2sza!4v1639120159546!5m2!1sen!2sza"
            width="100%" height="480" [frameBorder]="0"></iframe>
                </div>
            </div>
        </div>
        <br />
        
    </div>
</div>