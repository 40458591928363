import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsService } from '../notifications.service';
import { Subscription } from 'rxjs';
import { WebMethodsService } from '../web-methods.service';
import {
  OidcSecurityService
} from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';

export interface notificationsTable {
  id: string,
  Header: string,
  Body: String,
  DateTime: String
}

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  notificationItems: any;
  notificationTableData: notificationsTable[] = [];
  dataSource = new MatTableDataSource<any>(this.notificationTableData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter = new FormControl('All');
  displayedColumns: string[] = ['Header', 'Body', 'DateTime'];

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(private api: WebMethodsService, private readonly notificationsService: NotificationsService, public oidcSecurityService: OidcSecurityService, private toastTemplates: NgbdToastGlobal){
  }

  ngOnInit(): void {
    this.subscription = this.notificationsService.notificationItems$.subscribe(notificationItems => {
      if(this.filter.value == "All"){
        this.notificationItems = notificationItems;
      }
      else{
        this.notificationItems = [];
        notificationItems.forEach((element: any) => {
        if(element.Read == (this.filter.value == "Read")){
          this.notificationItems.push(element);
        }
      });
      }
      this.notificationTableData = [];
      this.notificationItems.forEach((element: any) => {
        var notifTableRow: notificationsTable = {
          id: element.id,
          Header: element.header,
          Body: element.body,
          DateTime: element.date
        };
        this.notificationTableData.push(notifTableRow);
      });
      this.dataSource = new MatTableDataSource<any>(this.notificationTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNotifications() {
    this.notificationsService.getNotifications();
  }
}