<div style="user-select: none;">
    <h1 mat-dialog-title><b>Add Group</b></h1>
    <div mat-dialog-content>

        <div class="flexRow">
            <mat-form-field style="width: 100%;">
                <mat-label>Tenant</mat-label>
                <mat-select [disabled]="tenantsLoading" [formControl]="data.tenantID" required>
                    @for (tenant of tenants; track tenant){
                        <mat-option [value]="tenant.id">{{tenant.Name}}</mat-option>
                    }
                </mat-select>
                <mat-card *ngIf="tenantsLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-card>
            </mat-form-field>
        </div>




        <div class="flexRow">
            <div>
                <p class="itemHeading">Name</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [(ngModel)]="data.groupName" cdkFocusInitial>

                <p class="itemHeading">Description</p>
                <input class="mainTextbox valueTextbox localTextBox" style="background-color: white;" [(ngModel)]="data.groupDescription">
            </div>
        </div>
        <div class="flexRow">
            
            
            
           
        </div>
    </div>

    <div mat-dialog-actions style="justify-content: right;">
        <button mat-flat-button color="secondary" mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="data" >Add</button>
    </div>
</div>