<div>

    <div *ngIf="state==enum.loading"
        style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 100px); align-items: center; user-select: none;">
        <p class="loadingText">Document Loading</p>
        <br>
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="state==enum.saving"
        style="display: flex; flex-direction: column; justify-content: center; height: calc(100vh - 100px); align-items: center; user-select: none;">
        <p class="loadingText">Updating task</p>
        <br>
        <mat-spinner></mat-spinner>
    </div>
    <div style="width: 100%;" [ngStyle]="{'display': state==enum.inPreview? 'block': 'none'}">
        <div class="flexJustify flexRow">
            <div mat-dialog-actions style="width: 35%; user-select: none;">
                <p class="taskHeader">{{data.Name}}</p>
            </div>
            <div mat-dialog-actions style="justify-content: center;">
                <button mat-flat-button color="accent" (click)="approveTask()">Approve</button>
                <button mat-flat-button color="warn" (click)="rejectTask()">Reject</button>
            </div>
            <div mat-dialog-actions style="width: 35%; display: flex; justify-content: flex-end;">
                <!-- <button mat-flat-button color="primary" (click)="toggleSideNav()">
                    <svg *ngIf="!showSideNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                        viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                        <path
                            d="M11 17h10v-2H11v2zm-8-5 4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                        </path>
                    </svg>
                    <svg *ngIf="showSideNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                        viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                        <path
                            d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                        </path>
                    </svg>
                </button> -->
                <p class="taskHeader">{{data.PoolName}}</p>
            </div>
        </div>

        <div class="rowFlex">
            <mat-drawer-container style="background-color: rgb(230, 230, 230); width: 100%;" autosize>
                <mat-drawer #drawer mode="side" style="background-color: rgb(230, 230, 230); width: 350px;"
                    [opened]="showSideNav" position="end">
                    <!-- <div style="width: 350px; height: 100%; background-color: rgb(230, 230, 230); "> -->
                    <!-- <div style="padding: 5px; height: 100%;"> -->
                    <!-- <cdk-virtual-scroll-viewport itemSize="50" style="height: 100%; "> -->
                    <div style="width: 100%; display: flex; justify-content: center; padding-top: 15px; user-select: none;"
                        *ngIf="steps.length == 0">
                        <p class="cardCommentHeader">No steps in this task.</p>
                    </div>
                    @for (step of steps; track $index) {
                    <div style="padding: 10px;">
                        <mat-card>
                            <mat-card-content>
                                <div class="flexRow flexJustify">
                                    <div>
                                        <p class="cardHeader">{{step.Pool}}</p>
                                    </div>
                                    <div>
                                        <div class="flexRow" style="color: green;" *ngIf="step.Action=='Approved'">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                viewBox="0 0 512 512" height="10px" width="10px"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                                                </path>
                                            </svg>
                                            <p class="cardHeader" style="color: green;">{{step.Action}}</p>
                                        </div>
                                        <div class="flexRow" style="color: red;" *ngIf="step.Action=='Rejected'">
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                viewBox="0 0 352 512" height="10px" width="10px"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                                </path>
                                            </svg>
                                            <p class="cardHeader" style="color: red;">{{step.Action}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="cardCommentHeader">Comments:</p>
                                    <div class="commentBox">
                                        <cdk-virtual-scroll-viewport itemSize="50" class="commentBoxScroll">
                                            <p class="cardCommentBody">{{step.Comment}}</p>
                                        </cdk-virtual-scroll-viewport>

                                    </div>
                                </div>
                                <div class="flexRow flexJustify" style="padding-top: 2px;">
                                    <div>
                                        <p class="cardFooterLeft">{{step.User}}</p>
                                    </div>
                                    <div>
                                        <p class="cardFooterRight">{{step.DateTime}}</p>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    }
                    <!-- </cdk-virtual-scroll-viewport> -->
                    <!-- </div> -->
                    <!-- </div> -->
                </mat-drawer>


                <!-- <div class="flexRow" style="width: 100%;"> -->
                <div style="margin-top: 10px; margin-left: 10px; margin-right: 10px; box-shadow: 0px 0px 2px 2px grey;">
                    <iframe name="draftEditorWindow" id="draftEditorWindow" allow="clipboard-read; clipboard-write"
                         style="height: calc(100vh - 140px); width: 100%;" [frameBorder]="0"></iframe>
                </div>

                <!-- </div> -->
            </mat-drawer-container>
            <div style="width: 30px; padding-top: 5px;">
                <mat-card class="commentNavButton" style="width: 30px; height: 100%;" (click)="toggleSideNav()">
                    <mat-card-content style="height: 100%; padding: 0px;">
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: space-between; width: 30px; height: 100%;">
                            <div style="height: 50px; color: #777777;">
                                <svg *ngIf="!showSideNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                                    viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path
                                        d="M11 17h10v-2H11v2zm-8-5 4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                                    </path>
                                </svg>
                                <svg *ngIf="showSideNav" stroke="currentColor" fill="currentColor" stroke-width="0"
                                    viewBox="0 0 24 24" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path
                                        d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z">
                                    </path>
                                </svg>
                            </div>
                            <div style="width: 30px; rotate: -90deg;">
                                <p
                                    style="font-weight: bold; width: 100px;  font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #777777;">
                                    Comments</p>
                            </div>
                            <div style="height: 50px;"></div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </div>

    <div *ngIf="state==enum.inPreview" mat-dialog-actions
        style="justify-content: center; bottom: 10px; position: absolute; width: 100%;">
        <button mat-flat-button color="primary" mat-dialog-close>Close</button>
    </div>
</div>