import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../../web-methods.service';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
// import mime from 'mime-types';

import { Buffer } from 'buffer';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
// import { ThumbnailService } from '@alfresco/adf-core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { registerLocaleData } from '@angular/common';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { AdvancedDateSelectionComponent } from '../../SharedComponents/advanced-date-selection/advanced-date-selection.component';

@Component({
  selector: 'app-archive',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    AdvancedDateSelectionComponent,
  ],
  templateUrl: './archive.component.html',
  styleUrl: './archive.component.scss'
})
export class ArchiveComponent implements OnInit {
  // constructor(public oidcSecurityService: OidcSecurityService,private api: WebMethodsService, public dialog: MatDialog, public thumbnailService: ThumbnailService) { }
  constructor(public oidcSecurityService: OidcSecurityService, private api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  documentTableData: any = [];
  dataSource = new MatTableDataSource(this.documentTableData);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  filter = new FormControl('Document');
  slectedFilter = 'Document';
  templates: any = new FormControl([]);
  templatesList: any[] = [];
  channels: any = new FormControl([]);
  channelsList: any[] = [];
  templatesLoading = false;
  channelsLoading = false;
  documentTableLoading = false;
  searchText = "";
  documentOptionSideNavOpen = false;
  searchOpened = true;
  documentActionLoading = false;
  userEmail = "";
  displayedColumnsDocument: string[] = ['fileType', 'fileName', 'delDate', 'pages', 'channel', 'template'];

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };

  ngOnInit(): void {
    // registerLocaleData('');
    this.readArchive();
    //this.getDocumentList();

    this.oidcSecurityService.getUserData().subscribe((userData) => {
      this.userEmail = userData.email;
    });

  }

  dateUpdated(data: any) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.readArchive();
  }

  filterChange(data: any) {
    // console.log("Event fired");
    // console.log(data);
    // console.log(this.slectedFilter);
    this.slectedFilter = data.value;
    if (data.value == "Document") {
      this.readArchive();
    }
  }


  dispFileName = "";
  dispBatchID = "";
  dispBatchName = "";
  dispEmailSubject = "";
  dispEmailTo = "";
  dispEmailStatus = "";
  dispS3 = "";
  dispDocs: any[] = [];
  dispURL = "";
  dispBucket = "";
  dispPath = "";

  documentOptionButton(tableRow: any) {
    // console.log(tableRow);
    this.documentOptionSideNavOpen = true;
    this.clickedRows.clear();
    this.clickedRows.add(tableRow);

    this.dispFileName = tableRow.fileName;
    this.dispBatchID = tableRow.batchID;
    this.dispBatchName = tableRow.batchName;
    this.dispEmailSubject = tableRow.subjectEmail;
    this.dispEmailTo = tableRow.toEmail;
    this.dispEmailStatus = tableRow.sentStatusEmail == 250 ? "Email Sent" : "Error in sending email";
    this.dispS3 = tableRow.s3Destination;
    this.dispDocs = tableRow.docsInPackage;
    this.dispURL = tableRow.url;
    this.dispBucket = tableRow.bucket;
    this.dispPath = tableRow.dockPath;
  }

  closeDocumentOption() {
    this.documentOptionSideNavOpen = false;
  }


  readArchive() {
    // console.log("Reading archive!!!");
    if (
      !this.range.controls.start.hasError('matStartDateInvalid') &&
      !this.range.controls.end.hasError('matEndDateInvalid') &&
      this.range.controls.start.value != null &&
      this.range.controls.end.value != null) {
      // console.log("Valid");
      if (this.slectedFilter == "Document") {
        this.templatesLoading = true;
        this.channelsLoading = true;

        this.api.postService("/es/documents-generated-per-template", {
          "tenantID": localStorage.getItem('currentTenant'),
          "dateStart": this.range.controls.start.value?.toISOString(),
          "dateEnd": this.range.controls.end.value?.toISOString()
        })
          .then((response) => {
            if (("Message" in response.data)) {
              // console.log(response.data.Message);
              this.toastTemplates.showErrorToast("API error - Get Template List", response.data.Message);
            }
            else {
              var templateListLocal = [];
              for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
                templateListLocal.push(response.data.aggregations.all.buckets[a].key);
              }
              this.templates = new FormControl(templateListLocal);
              this.templatesList = templateListLocal;
            }
            this.templatesLoading = false;

          })
          .catch((error) => {
            // console.log(error);
            this.templatesLoading = false;
          });

        this.api.postService("/es/documents-generated-by-channel", {
          "tenantID": localStorage.getItem('currentTenant'),
          "dateStart": this.range.controls.start.value?.toISOString(),
          "dateEnd": this.range.controls.end.value?.toISOString()
        })
          .then((response) => {
            if (("Message" in response.data)) {
              // console.log(response.data.Message);
              this.toastTemplates.showErrorToast("API error - Get Channel List", response.data.Message);
            }
            else {
              var channelListLocal = [];
              for (var a = 0; a < response.data.aggregations.all.buckets.length; a++) {
                channelListLocal.push(response.data.aggregations.all.buckets[a].key);
              }
              this.channels = new FormControl(channelListLocal);
              this.channelsList = channelListLocal;
            }
            this.channelsLoading = false;
          })
          .catch((error) => {
            // console.log(error);
            this.channelsLoading = false;
          });

      }
    }
    else {
      // console.log("Invalid");
    }
  }

  getDocumentList() {
    this.documentTableData = [];
    this.documentTableLoading = true;
    this.documentOptionSideNavOpen = false;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      dateStart: this.range.controls.start.value,
      dateEnd: this.range.controls.end.value,
      fromItem: 0,
      searchQuery: this.searchText == "" ? "*" : this.searchText
    }
    this.api.postService("/es/archive-data", request)
      .then((response) => {
        if (response.status == 200) {
          // console.log(response);
          if (("Message" in response.data)) {
            // console.log(response.data.Message);
            this.toastTemplates.showErrorToast("API error - Get Documents", response.data.Message);
          }
          else {
            var documentTableDataLocal: any[] = [];




            response.data.hits.hits.forEach((element: any) => {
              var batchName = element?._source?.batch?.batchName;
              var transactionNo = element?._source?.batch?.transactionNo;
              var batchID = element?._source?.batch?.batchId;
              var s3Destination = element?._source?.delivery?.s3?.destinationBucket;
              var toEmail = element?._source?.delivery?.smtp?.recipient;
              var senderEmail = element?._source?.delivery?.smtp?.sender;
              var subjectEmail = element?._source?.delivery?.smtp?.subject;
              var sentStatusEmail = element?._source?.delivery?.smtp?.responseCode;
              // var timeStamp = new Date(element?._source?.delivery?.timestamp);

              var gmtDate = new Date(element?._source?.delivery?.timestamp).setUTCDate(new Date(element?._source?.delivery?.timestamp).getDate());

              var time = new Date(gmtDate).setUTCHours(new Date(element?._source?.delivery?.timestamp).getHours())
              var timeStamp = time;

              var docsInPackage: any[] = [];

              element?._source?.documents.forEach((elementDocs: any, index: any) => {
                var fileExtension = ".txt"
                switch (elementDocs.channel.id) {
                  case 1: fileExtension = '.html'; break;
                  case 2: fileExtension = '.pdf'; break;
                  case 6: fileExtension = '.txt'; break;
                  case 8: fileExtension = '.docx'; break;
                  default: {
                    if (elementDocs.channel.name == "HTML Linked") {
                      fileExtension = '.html';

                    }
                    else {
                      fileExtension = '.txt';
                    }
                  };
                }
                var outputPath = "";
                var docNo = 0 + index + 1;
                try {
                  outputPath = element._source.delivery.disk.outputDir;
                } catch (err) { }
                var newPath = "";
                try {
                  // console.log(outputPath.substring((outputPath.search('/output/') + 8)));
                  newPath = 'output/' + outputPath.substring((outputPath.search('/output/') + 8)) + "/" + element._source.batch.batchId + '_' + element._source.batch.transactionNo + '_' + docNo + fileExtension;
                } catch (err) { console.log("Path error: " + err) }
                var bucket = element._source.delivery.s3.destinationBucket;

                var docItem = {
                  fileName: element._source.batch.batchId + '_' + element._source.batch.transactionNo + '_' + docNo + fileExtension,
                  bucket: bucket.substring(bucket.search("amazonaws.com/") + 14),
                  url: bucket.substring(0, bucket.search("amazonaws.com/") + 14),
                  path: newPath,
                  channel: elementDocs.channel.name
                }
                docsInPackage.push(docItem);
              });

              element?._source?.documents.forEach((elementDocs: any, index: any) => {
                var fileExtension = ".txt"
                var mimeType = "text/plain";
                var image = "./assets/img/file-type-pdf2.svg";
                switch (elementDocs.channel.id) {
                  case 1: fileExtension = '.html'; mimeType = 'text/html'; image = "./assets/img/text-html.svg"; break;
                  case 2: fileExtension = '.pdf'; mimeType = 'application/pdf'; image = "./assets/img/file-type-pdf2.svg"; break;
                  case 6: fileExtension = '.txt'; mimeType = 'text/plain'; image = "./assets/img/file-text.svg"; break;
                  case 8: fileExtension = '.docx'; mimeType = 'application/msword'; image = "./assets/img/file-type-word.svg"; break;
                  default: {
                    if (elementDocs.channel.name == "HTML Linked") {
                      mimeType = 'text/html';
                      fileExtension = '.html';
                      image = "./assets/img/text-html.svg";
                    }
                    else {
                      mimeType = 'text/plain';
                      fileExtension = '.txt';
                      image = "./assets/img/file-text.svg";
                    }
                  };
                }
                var outputPath = "";
                var docNo = 0 + index + 1;
                try {
                  outputPath = element._source.delivery.disk.outputDir;
                } catch (err) { }
                var newPath = "";
                try {
                  // console.log(outputPath.substring((outputPath.search('/output/') + 8)));
                  // console.log(outputPath);
                  newPath = 'output/' + outputPath.substring((outputPath.search('/output/') + 8)) + "/" + element._source.batch.batchId + '_' + element._source.batch.transactionNo + '_' + docNo + fileExtension;
                } catch (err) { }
                var bucket = element._source.delivery.s3.destinationBucket;
                var docObject = {
                  fileName: element._source.batch.batchId + '_' + element._source.batch.transactionNo + '_' + docNo + fileExtension,
                  fileType: mimeType,
                  image: image,
                  batchName: batchName,
                  transactionNo: transactionNo,
                  batchID: batchID,
                  s3Destination: s3Destination,
                  toEmail: toEmail,
                  senderEmail: senderEmail,
                  subjectEmail: subjectEmail,
                  sentStatusEmail: sentStatusEmail,
                  delDate: new Date(timeStamp).toLocaleDateString('af-za') + " " + new Date(timeStamp).toLocaleTimeString('af-za'),
                  pages: elementDocs?.pageCount,
                  channel: elementDocs?.channel?.name,
                  template: elementDocs?.template?.name,
                  dockPath: newPath,
                  bucket: bucket.substring(bucket.search("amazonaws.com/") + 14),
                  url: bucket.substring(0, bucket.search("amazonaws.com/") + 14),
                  docsInPackage: docsInPackage
                }
                if ((this.templates.value.filter(((data: any) => { return data == elementDocs?.template?.name })).length > 0) && (this.channels.value.filter(((data: any) => { return data == elementDocs?.channel?.name })).length > 0)) {
                  documentTableDataLocal.push(docObject);
                }
                //console.log(docObject);
              });

            });
            this.documentTableData = documentTableDataLocal;
            // console.log(this.documentTableData);
            this.dataSource = new MatTableDataSource(this.documentTableData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error", "A connection error occured");
        }
        this.documentTableLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("Error - Document List", err);
        this.documentTableLoading = false;
      });

  }

  downloadDocument() {
    this.documentActionLoading = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: this.dispURL,
      path: '' + this.dispPath,
      bucket: this.dispBucket
    }
    this.api.postService("/document/get-document", request)
      .then((response) => {
        this.documentActionLoading = false;
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            const url = URL.createObjectURL(new Blob([Buffer.from(JSON.parse(response.data.body).document, 'base64')], { type: JSON.parse(response.data.body).mimeType }));
            const a = document.createElement('a');
            a.href = url;
            a.download = this.dispFileName || 'download';
            const clickHandler = () => {
              setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
              }, 150);
            };
            a.addEventListener('click', clickHandler, false);
            a.click();
          }
          else {
            this.toastTemplates.showErrorToast("API error - Download document", response.data.body);
          }
        }
      })
      .catch((err) => {
        this.documentActionLoading = false;
        // console.log(err);
      })
  }

  previewDocument() {
    this.documentActionLoading = true;
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      s3: this.dispURL,
      path: '' + this.dispPath,
      bucket: this.dispBucket
    }
    this.api.postService("/document/get-document", request)
      .then((response) => {
        this.documentActionLoading = false;
        // console.log(response);
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            const url = URL.createObjectURL(new Blob([Buffer.from(JSON.parse(response.data.body).document, 'base64')], { type: JSON.parse(response.data.body).mimeType }));
            var dialog = this.dialog.open(PreviewDialog, { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh', data: url, disableClose: true });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Preview document", response.data.body);
          }
        }
      })
      .catch((err) => {
        this.documentActionLoading = false;
        // console.log(err);
      })
  }

  resendEnvelope(self: boolean) {
    // console.log(self);
    this.documentActionLoading = true;
    var sendData = {
      tenantID: localStorage.getItem('currentTenant'),
      sendToEmail: self ? this.userEmail : this.dispEmailTo,
      subject: this.dispEmailSubject,
      docs: this.dispDocs,
      s3: this.dispBucket
    }
    this.api.postService("/mail/resend", sendData)
      .then((response) => {
        this.documentActionLoading = false;
        if (response.status == 200) {
          if (response.data.statusCode == 200) {
            this.toastTemplates.showSuccessToast("Send Email", "Email sent successfully");
          }
          else {
            this.toastTemplates.showErrorToast("API error - Send Email", "Email could not be sent");
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Send Email", "Email could not be sent");
        }
      })
      .catch((err) => {
        this.documentActionLoading = false;
        console.log(err);
      })
  }
  clickedRows = new Set<any>();
}

@Component({
  selector: 'preview-dialog',
  templateUrl: './preview-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    FormsModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  styleUrl: './archive.component.scss'
})
export class PreviewDialog implements OnInit {
  constructor(private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<PreviewDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  docData: SafeResourceUrl;
  ngOnInit(): void {
    // console.log(this.data);
    // const url = URL.createObjectURL(new Blob([Buffer.from(this.data.doc, 'base64')], { type: this.data.mime }));
    this.docData = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
  }
}