import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { WebMethodsService } from '../web-methods.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Buffer } from 'buffer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

enum flow {
  loading,
  inPreview,
  submitting
}

@Component({
  selector: 'app-completed-tasks',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatIconModule,
    MatSortModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    AdvancedDateSelectionComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './completed-tasks.component.html',
  styleUrl: './completed-tasks.component.scss'
})
export class CompletedTasksComponent {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal, private route: Router, public dialog: MatDialog, private sanitizer: DomSanitizer) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  hostname = "";
  tenants = [{ id: '', EmbedURL: '' }];
  embedURL: SafeResourceUrl;
  iframeData = ``;

  taskTable = [];
  dataSource = new MatTableDataSource(this.taskTable);
  displayedColumns: string[] = ['Name', 'LastUpdate', 'CreateDate', 'CreatorName', 'Action'];

  check_TaskID = true;
  check_Name = true;
  check_LastUpdated = true;
  check_CreatedDate = true;
  check_CreatedBy = true;
  check_Workflow = false;
  check_Queue = false;
  check_ProjectID = false;
  check_TemplateSelector = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };
  filterWorkflows: any = new FormControl("0");
  workflows: any = [];
  workflowsLoading = false;

  taskssLoading = false;
  ngOnInit(): void {
    if (window.location.port == '') {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
    }
    else {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].EmbedURL);
        this.embedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].EmbedURL);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="` + this.embedURL + `" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
    this.getWorkflowItems();
    this.getTasks();
  }

  dateUpdated(data: any) {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getTasks();
  }

  getWorkflowItems() {
    this.filterWorkflows = null;
    this.workflows = [];
    this.workflowsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
      .then((response) => {
        this.workflowsLoading = false;
        // console.log(response);
        if (response.status == 200) {
          this.workflows = response.data;
          this.workflows.unshift({ id: '-all-', workflowName: 'All' });
          this.filterWorkflows = new FormControl(response.data[0].id);
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Add Workflow", err);
        this.workflowsLoading = false;
      });
  }

  getTasks() {
    this.taskTable = [];
    this.taskssLoading = true;
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      var userID = userData.sub;
      var tenantID = localStorage.getItem('currentTenant');
      var wfID = undefined;
      try {
        if (this.filterWorkflows.value != '-all-') {
          wfID = this.filterWorkflows.value;
        }
      }
      catch (err) { }
      var postData = {
        tenantID: tenantID,
        poolID: "Complete",
        fromDate: this.range.controls.start.value,
        toDate: this.range.controls.end.value,
        workflowID: wfID
      };
      this.api.postService("/workflow/listTasks", postData).then((response: any) => {
        // console.log(response);
        if (response.status == 200) {
          var newTaskTable: any = [];
          response.data.forEach((element: any) => {
            element.LastUpdateDisplay = new Date(element.LastUpdate).toDateString() + " " + new Date(element.LastUpdate).toLocaleTimeString();

            element.CreateDateDisplay = new Date(element.CreateDate).toDateString() + " " + new Date(element.CreateDate).toLocaleTimeString();
            newTaskTable.push(element);
          });
          this.taskTable = newTaskTable;
          this.dataSource = new MatTableDataSource(this.taskTable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tasks", "");
        }
        this.taskssLoading = false;
      })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Get Tasks", err);
          this.taskssLoading = false;
        });
    })
  }
  openTasks(tasksItem: any) {
    const dialogRef = this.dialog.open(PreviewDialog, { disableClose: true, width: '100%', height: 'calc(100vh - 10px)', maxWidth: '100%', data: tasksItem });
    dialogRef.afterClosed().subscribe(result => {
      this.getTasks();
    });
  }

  columnVisibleSelect() {
    var newColumns = [];
    if (this.check_TaskID) {
      newColumns.push('id');
    }
    if (this.check_Name) {
      newColumns.push('Name');
    }
    if (this.check_LastUpdated) {
      newColumns.push('LastUpdate');
    }
    if (this.check_CreatedDate) {
      newColumns.push('CreateDate');
    }
    if (this.check_CreatedBy) {
      newColumns.push('CreatorName');
    }
    if (this.check_Workflow) {
      newColumns.push('WorkflowName');
    }
    if (this.check_Queue) {
      newColumns.push('Queue');
    }
    if (this.check_ProjectID) {
      newColumns.push('ProjectID');
    }
    if (this.check_TemplateSelector) {
      newColumns.push('TemplateSelector');
    }
    newColumns.push('Action');
    this.displayedColumns = newColumns;
  }

  columnVisibleSet() {
    this.check_TaskID = (this.displayedColumns.indexOf('id') != -1);
    this.check_Name = (this.displayedColumns.indexOf('Name') != -1);
    this.check_LastUpdated = (this.displayedColumns.indexOf('LastUpdate') != -1);
    this.check_CreatedDate = (this.displayedColumns.indexOf('CreateDate') != -1);
    this.check_CreatedBy = (this.displayedColumns.indexOf('CreatorName') != -1);
    this.check_Workflow = (this.displayedColumns.indexOf('WorkflowName') != -1);
    this.check_Queue = (this.displayedColumns.indexOf('Queue') != -1);
    this.check_ProjectID = (this.displayedColumns.indexOf('ProjectID') != -1);
    this.check_TemplateSelector = (this.displayedColumns.indexOf('TemplateSelector') != -1);
  }
}

@Component({
  selector: 'completed-task-view-dialog',
  templateUrl: 'completed-task-view-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatSidenavModule,
    MatCardModule,
  ],
  styleUrl: './completed-tasks.component.scss'
})
export class PreviewDialog implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public dialogRef: MatDialogRef<PreviewDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal, private sanitizer: DomSanitizer) { }
  hostname = "";
  tenants = [{ id: '', EmbedURL: '' }];
  embedURL: SafeResourceUrl;
  iframeData = ``;
  state: flow = flow.loading;
  enum: typeof flow = flow;
  draftData = "";
  editor: any;
  steps: any = [];
  commentsNav = false;

  ngOnInit(): void {
    // console.log(this.data);
    if (window.location.port == '') {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}`);
    }
    else {
      this.hostname = (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`);
    }
    this.tenants = JSON.parse(localStorage.getItem('tenants')!);
    // console.log('Tenants: ')
    // console.log(this.tenants)
    const currentTenant = localStorage.getItem('currentTenant');
    // console.log("Current tenant: " + currentTenant);
    for (var a = 0; a < this.tenants!.length; a++) {
      // console.log(this.tenants[a]);
      if (this.tenants[a].id == currentTenant) {
        // console.log(this.tenants[a].EmbedURL);
        this.embedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.tenants[a].EmbedURL);
        // this.embedURL = this.tenants[a].EmbedURL;
        this.iframeData = `<iframe class="templateIframe" src="` + this.embedURL + `" allow="clipboard-read; clipboard-write" width='100%'></iframe>`;
        // console.log(this.iframeData);
      }
    }
    try {
      var localSteps = JSON.parse(Buffer.from(this.data.Steps, "base64").toString("utf-8"));
      this.steps = [];
      localSteps.forEach((element: any) => {
        element.DateTime = new Date(element.DateTime).toDateString() + " " + new Date(element.DateTime).toLocaleTimeString();
        this.steps.push(element);
      });

    }
    catch (err) { }
    setTimeout(() => { this.loadDraft(); }, 10);
  }


  loadDraft() {
    var request = {
      tenantID: localStorage.getItem('currentTenant'),
      taskID: this.data.id
    }
    this.api.postService("/workflow/getTask", request)
      .then((response) => {
        this.data = response.data[0];
        var draftXMLb64 = response.data[0].DraftXML;
        this.draftData = Buffer.from(draftXMLb64, "base64").toString("utf-8");
        this.editor = window;
        this.editor = this.editor.SmartComms;
        this.editor = new this.editor.DraftEditor({
          server: 'https://eu10-sb.smartcommunications.cloud',
          clientServer: this.hostname,
          targetElement: 'draftEditorWindow',
          navigateAwayWarning: 'Are you sure you want to leave Draft-Editor?',
          onReady: () => {
            // console.log("onReady");
            this.state = flow.inPreview;
            this.editor.v2.loadString(this.draftData, { openInPreview: false }, function () {
            });
          },
          loadStartupConfig: () => ({
            version: '2',
            editingMode: 'readOnly',
            features: {
              disabled: ['PasteMode']
            },
            sidePanel: { collapse: true, alignment: 'right' }
          }),
        });


      });
  }

  submitJob() {
    this.state = flow.submitting;
    var requestSubmit = {
      tenantId: localStorage.getItem('currentTenant'),
      queue: this.data.Queue,
      purgeLocked: 'true',
      transactionDataType: "application/xml",
      inputData: this.data.DraftXML,
      project: this.data.ProjectID,
      type: "DRAFT_FILE",
      config: this.data.TemplateSelector,
      name: this.data.Name
    }
    this.api.postService("/sc/submitjobdraft", requestSubmit).then((resp) => {
      // console.log(resp);
      if (resp.status == 200) {
        this.toastTemplates.showSuccessToast("Submitting for distribution", "Draft was successfully submitted for ditribution.");
        // console.log(resp.data.JobID);
        // console.log(resp.data.Status);
        this.updateTask('Documents submitted for distribution with JOB ID ' + resp.data.JobID, this.data);
        this.dialogRef.close();
      }
      else {
        this.toastTemplates.showErrorToast("API error - Submit Job", "Could not complete the job submission.");
        this.dialogRef.close();
      }
    }).catch((err) => { this.toastTemplates.showErrorToast("API error - Submit Job", err); })
    // setTimeout(()=> {
    //   this.state = flow.inPreview;
    //   this.toastTemplates.showSuccessToast("Submitting for distribution","Draft was successfully submitted for ditribution.");
    //   this.dialogRef.close();
    // }, 2000);
  }

  updateTask(comment: any, taskData: any) {
    this.oidcSecurityService.getUserData().subscribe((userData) => {

      this.steps.push({ Pool: taskData.PoolName, Comment: comment, DateTime: new Date(), Action: "Distribution Submitted", User: userData.name });
      // console.log("DialogData");
      // console.log(dialogData);
      // console.log("taskData");
      // console.log(taskData);
      var request = {
        taskID: taskData.id,
        tenantID: localStorage.getItem('currentTenant'),
        steps: Buffer.from(JSON.stringify(this.steps)).toString('base64')
      }

      this.api.postService("/workflow/updateTask", request)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
          }
          this.dialogRef.close(this.data);
        })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Update Workflow Task", err);
          this.dialogRef.close(this.data);
        });
    });
  }


  openComments() {
    // console.log("Open comments");
    this.commentsNav = !this.commentsNav;
  }
}