import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgbdToastGlobal } from '../toast/toast-global.component';
import { WebMethodsService } from '../web-methods.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AdvancedDateSelectionComponent } from '../SharedComponents/advanced-date-selection/advanced-date-selection.component';

@Component({
  selector: 'app-saved-drafts',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatIconModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    AdvancedDateSelectionComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  templateUrl: './saved-drafts.component.html',
  styleUrl: './saved-drafts.component.scss'
})
export class SavedDraftsComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal, private route: Router, public dialog: MatDialog) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  taskTable = [];
  dataSource = new MatTableDataSource(this.taskTable);
  displayedColumns: string[] = ['Name', 'LastUpdate', 'CreateDate', 'Action'];
  draftsLoading = false;
  timeFrom = new Date();
  timeTo = new Date();

  check_TaskID = true;
  check_Name = true;
  check_LastUpdated = true;
  check_CreatedDate = true;
  check_Workflow = false;
  check_Queue = false;
  check_ProjectID = false;
  check_TemplateSelector = false;

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end: new FormControl<Date | null>(new Date(new Date().setDate(new Date().getDate() + 1))),
  });
  initDateTime = {
    dateFrom: this.range.controls.start.value,
    dateTo: this.range.controls.end.value
  };
  filterWorkflows: any = new FormControl("0");
  workflows: any = [];
  workflowsLoading = false;

  ngOnInit(): void {
    this.getWorkflowItems();
    this.getDrafts();
  }

  dateUpdated(data: any) {
    // console.log(data)
    this.range = new FormGroup({
      start: new FormControl<Date | null>(data.dateFrom),
      end: new FormControl<Date | null>(data.dateTo),
    });
    this.getDrafts();
  }

  getWorkflowItems() {
    this.filterWorkflows = null;
    this.workflows = [];
    this.workflowsLoading = true;
    var tenantID = localStorage.getItem('currentTenant');
    this.api.postService("/settings/listworkflows", { tenantID: tenantID })
      .then((response) => {
        this.workflowsLoading = false;
        // console.log(response);
        if (response.status == 200) {
          this.workflows = response.data;
          this.workflows.unshift({ id: '-all-', workflowName: 'All' });
          this.filterWorkflows = new FormControl(response.data[0].id);
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Add Workflow", err);
        this.workflowsLoading = false;
      });
  }


  getDrafts() {
    this.taskTable = [];
    this.draftsLoading = true;

    this.oidcSecurityService.getUserData().subscribe((userData) => {
      var wfID = undefined;
      try {
        if (this.filterWorkflows.value != '-all-') {
          wfID = this.filterWorkflows.value;
        }
      }
      catch (err) { }
      var userID = userData.sub;
      var tenantID = localStorage.getItem('currentTenant');
      var postData = {
        userID: userID,
        tenantID: tenantID,
        fromDate: this.range.controls.start.value,
        toDate: this.range.controls.end.value,
        poolID: '',
        workflowID: wfID
      };

      this.api.postService("/workflow/listTasks", postData).then((response: any) => {
        // console.log(response);
        if (response.status == 200) {
          var newTaskTable: any = [];
          response.data.forEach((element: any) => {
            element.LastUpdateDisplay = new Date(element.LastUpdate).toDateString() + " " + new Date(element.LastUpdate).toLocaleTimeString();
            element.CreateDateDisplay = new Date(element.CreateDate).toDateString() + " " + new Date(element.CreateDate).toLocaleTimeString();
            newTaskTable.push(element);
          });
          this.taskTable = newTaskTable;
          this.dataSource = new MatTableDataSource(this.taskTable);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tasks", "");
        }
        this.draftsLoading = false;
      })
        .catch((err) => {
          // console.log(err);
          this.toastTemplates.showErrorToast("API error - Get Tasks", err);
          this.draftsLoading = false;
        });
    })
  }

  openDraft(data: any) {
    var taskID = data.id;
    this.route.navigateByUrl('/interactive/newDraft?taskid=' + taskID);
  }

  deleteDraft(data: any) {
    // console.log(data);
    const dialogRef = this.dialog.open(DeleteDraftDialog, { disableClose: true, width: 'fit-content', data: data });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.draftsLoading = true;
        this.taskTable = [];
        var tenantID = localStorage.getItem('currentTenant');
        this.api.postService("/workflow/deleteTask", {"tenantID": tenantID, "TaskID": result.id })
          .then((response) => {
            this.draftsLoading = false;
            // console.log(response);
            if (response.status == 200) {
              this.getDrafts();
            }
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Delete Workflow", err);
            this.draftsLoading = false;
          });
      }
    });

  }

  columnVisibleSelect() {
    var newColumns = [];
    if (this.check_TaskID) {
      newColumns.push('id');
    }
    if (this.check_Name) {
      newColumns.push('Name');
    }
    if (this.check_LastUpdated) {
      newColumns.push('LastUpdate');
    }
    if (this.check_CreatedDate) {
      newColumns.push('CreateDate');
    }
    if (this.check_Workflow) {
      newColumns.push('WorkflowName');
    }
    if (this.check_Queue) {
      newColumns.push('Queue');
    }
    if (this.check_ProjectID) {
      newColumns.push('ProjectID');
    }
    if (this.check_TemplateSelector) {
      newColumns.push('TemplateSelector');
    }
    newColumns.push('Action');
    this.displayedColumns = newColumns;
  }

  columnVisibleSet() {
    this.check_TaskID = (this.displayedColumns.indexOf('id') != -1);
    this.check_Name = (this.displayedColumns.indexOf('Name') != -1);
    this.check_LastUpdated = (this.displayedColumns.indexOf('LastUpdate') != -1);
    this.check_CreatedDate = (this.displayedColumns.indexOf('CreateDate') != -1);
    this.check_Workflow = (this.displayedColumns.indexOf('WorkflowName') != -1);
    this.check_Queue = (this.displayedColumns.indexOf('Queue') != -1);
    this.check_ProjectID = (this.displayedColumns.indexOf('ProjectID') != -1);
    this.check_TemplateSelector = (this.displayedColumns.indexOf('TemplateSelector') != -1);
  }
}


@Component({
  selector: 'delete-draft-confirm-dialog',
  templateUrl: 'delete-draft-confirm-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
  ],
  styleUrl: './saved-drafts.component.scss'
})
export class DeleteDraftDialog {
  constructor(public dialogRef: MatDialogRef<DeleteDraftDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
}