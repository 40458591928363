import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantsComponent } from './tenants/tenants.component';
import { UsersComponent } from './users/users.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { GroupsComponent } from './groups/groups.component';
import { RolesComponent } from './roles/roles.component';
import { MatCardModule } from '@angular/material/card';
import { PoolsComponent } from './pools/pools.component';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    CommonModule,
    TenantsComponent,
    UsersComponent,
    PermissionsComponent,
    GroupsComponent,
    RolesComponent,
    PoolsComponent,
    MatCardModule,
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit {
  constructor(private avtiveRoute: ActivatedRoute, private route: Router) {
    avtiveRoute.url.subscribe((data) => {
      this.ngOnInit();
    })
  }

  ngOnInit(): void {
    this.tenantsLink = false;
    this.usersLink = false;
    this.permissionsLink = false;
    this.groupsLink = false;
    this.rolesLink = false;
    this.poolsLink = false;
    
    if (this.avtiveRoute.snapshot.url.length > 1) {
      switch (this.avtiveRoute.snapshot.url[1].path) {
        case 'tenants': this.tenantsLink = true; break;
        case 'users': this.usersLink = true; break;
        case 'permissions': this.permissionsLink = true; break;
        case 'groups': this.groupsLink = true; break;
        case 'roles': this.rolesLink = true; break;
        case 'pools': this.poolsLink = true; break;
      }
    }
    else {
    }
  }

  tenantsLink = false;
  usersLink = false;
  permissionsLink = false;
  groupsLink = false;
  rolesLink = false;
  poolsLink = false;
  

  tenantsClick() {
    this.route.navigateByUrl('/admin/tenants');
  }
  usersClick() {
    this.route.navigateByUrl('/admin/users');
  }
  permissionsClick() {
    this.route.navigateByUrl('/admin/permissions');
  }
  groupsClick() {
    this.route.navigateByUrl('/admin/groups');
  }
  rolesClick() {
    this.route.navigateByUrl('/admin/roles');
  }
  poolsClick() {
    this.route.navigateByUrl('/admin/pools');
  }
}
