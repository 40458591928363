import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WebMethodsService } from '../../web-methods.service';
import { environment } from '../../../environments/environment';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgbdToastGlobal } from '../../toast/toast-global.component';

@Component({
  selector: 'app-api-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    ClipboardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './api-settings.component.html',
  styleUrl: './api-settings.component.scss'
})



export class ApiSettingsComponent implements OnInit {
  externalID: string;
  clientName: string;
  clientID: string;
  clientSecret: string;
  tokenExpire: string;
  scopes: string;
  flows: string;
  tokenURL: string;
  apiKeysLoading = false;
  showClientSecret = "password";

  constructor(public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }

  ngOnInit(): void {
    this.getAPIKeys();
  }

  showHideSecret() {
    if (this.showClientSecret == "password") {
      this.showClientSecret = "text";
    }
    else {
      this.showClientSecret = "password";
    }
  }

  getAPIKeys() {
    this.apiKeysLoading = true;
    this.api.postService("/settings/getappclients", {})
      .then((results) => {

        var clientID = "";
        if (results.status == 200) {
          var resultObject = results.data;
          if (resultObject.statusCode === 200) {
            let tenants = JSON.parse(localStorage.getItem('tenants')!);
            let currentTenant = localStorage.getItem('currentTenant');
            var tenantList = [];
            for (var a = 0; a < tenants.length; a++) {
              tenantList.push(tenants[a].id);

            }
            let ClientApis = JSON.parse(resultObject.body).UserPoolClients;
            var apiItems = [];
            for (a = 0; a < ClientApis.length; a++) {
              var tenantIndex = tenantList.indexOf(ClientApis[a].ClientName);
              if (tenantList.includes(ClientApis[a].ClientName)) {
                var returnObject = {
                  id: a,
                  tenant: tenants[tenantIndex].tenantItem,
                  clientName: ClientApis[a].ClientName,
                  clientID: ClientApis[a].ClientId,
                  ExternalID: tenants[tenantIndex].SC_ExternalID
                }
                if (ClientApis[a].ClientName == currentTenant) {
                  apiItems.push(returnObject);
                }
              }

            }
            // console.log(apiItems);
            this.externalID = apiItems![0].ExternalID;
            this.clientName = apiItems![0].clientName;
            this.clientID = apiItems![0].clientID;

            this.api.postService("/settings/getapikeys", { TenantID: apiItems![0].clientID })
              .then((response) => {
                if (response.status == 200) {
                  if (response.data.statusCode === 200) {
                    var appClientDetails = JSON.parse(response.data.body)[0];
                    var scopes = "";
                    for (var x = 0; x < appClientDetails.optionalClientScopes.length; x++) {
                      scopes = scopes + appClientDetails.optionalClientScopes[x];
                      if ((x + 1) !== appClientDetails.optionalClientScopes.length) {
                        scopes = scopes + " | "
                      }
                    }
                    this.flows = "client_credentials";
                    var oauth2tokenurl = "";
                    if (environment.env_type === 'dev') {
                      oauth2tokenurl = environment.authority + '/protocol/openid-connect/token';
                    }
                    else if (environment.env_type === 'sit') {
                      oauth2tokenurl = "https://auth-ccmsaasportal-sit.communicationgenetics.com/oauth2/token";
                    }
                    else if (environment.env_type === 'prod') {
                      oauth2tokenurl = "https://auth-ccmsaasportal.communicationgenetics.com/oauth2/token";
                    }

                    this.clientSecret = appClientDetails.secret;
                    this.tokenExpire = (appClientDetails.attributes["access.token.lifespan"] / 60) + ' minute(s)';
                    this.scopes = scopes;
                    this.tokenURL = oauth2tokenurl;
                  }
                  else{
                    this.toastTemplates.showErrorToast("API error - Get API Information", "Could not get API Information.");
                  }
                }
                else{
                  this.toastTemplates.showErrorToast("API error - Get API Information", "Could not get API Information.");
                }
                this.apiKeysLoading = false;
              })
              .catch((err) => {
                // console.log(err);
                this.toastTemplates.showErrorToast("API error - Get API Information", err);
                this.apiKeysLoading = false;
              });
          }
          else {
            this.toastTemplates.showErrorToast("API error - Get API Information", "Could not get API Information.");
            this.apiKeysLoading = false;
          }
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get API Information", "Could not get API Information.");
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get API Information", err);
        this.apiKeysLoading = false;
      })

  }
}
