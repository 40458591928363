<h1 mat-dialog-title><b>Log Data - {{data.fileName}}</b></h1>
<div mat-dialog-content>
  <div *ngIf="!data.isCSV">
    <textarea disabled
      style="resize: none; width: 99%; height: 300px; white-space: pre; overflow-wrap: normal; overflow-x: scroll;">{{data.logData}}</textarea>
  </div>
  <div *ngIf="data.isCSV">
    <table mat-table [dataSource]="tableData" class="mat-elevation-z8" style="user-select: none;">
      <div *ngFor="let row of displayedColumns">
        <ng-container [matColumnDef]="row">
          <th mat-header-cell style="font-size: 12px;" *matHeaderCellDef> {{row}} </th>
          <td mat-cell style="font-size: 12px; max-width: fit-content;" *matCellDef="let element"> {{element[row]}} </td>
        </ng-container>
      </div>


      <tr mat-header-row style="height: 12px;" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row style="height: 10px;" *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</div>
<div mat-dialog-actions style="justify-content: center;">
  <button mat-flat-button color="primary" mat-dialog-close cdkFocusInitial>Close</button>
</div>