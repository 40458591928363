<div class="dialogMainDiv">
    <p class="subHeadingInSettings">Submitting Job</p>
    <div *ngIf="uploading">
        <p>Uploading file</p>
        <br>
        <div class="rowFlex">
            <div style="width: 100%; display: flex; align-items: center;">
                <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
            </div>
            <div>
                <p>{{uploadProgress}}%</p>
            </div>
        </div>
    </div>
    <div *ngIf="completed">
        <p>{{errorMessage}}</p>
    </div>
    <div *ngIf="error">
        <p>There was an issue submitting your job.</p>
        <p>{{errorMessage}}</p>
    </div>
    <br>
    <button class="blueButton" (click)="closeDialog()" [disabled]="closeDisable">Close</button>
</div>