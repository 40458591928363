<div class="dialogMainDiv">
    <p class="subHeadingInSettings">Add Queue</p>
    <table>
        <tbody>
            <tr>
                <td>
                    <p class="passwordText">Queue Name</p>
                </td>
                <td>
                    <input class="mainTextbox" placeholder="Queue Name" [(ngModel)]="data.queue">
                </td>
            </tr>
            <tr>
                <td>
                    <p class="passwordText">S3 Input Bucket</p>
                </td>
                <td>
                    <input class="mainTextbox" placeholder="S3 Input Bucket" [(ngModel)]="data.input">
                </td>
            </tr>
            <tr>
                <td>
                    <p class="passwordText">S3 Output Bucket</p>
                </td>
                <td>
                    <input class="mainTextbox" placeholder="S3 Output Bucket" [(ngModel)]="data.output">
                </td>
            </tr>
        </tbody>
    </table>
    <br>
    <button class="blueButton" (click)="closeDialog()" >Cancel</button>
    <button class="blueButton" [mat-dialog-close]="data" >Add Queue</button>
</div>