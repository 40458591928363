<div style="padding: 20px;">
    <div class="divHeaderLine">
        <h3>System Administration</h3>
        <div class="divHeaderLine">

        </div>
    </div>
    <br>
    <div class="ManuPage">
        <!-- <div style="min-width: 150px;">
            <div (click)="tenantsClick()" *ngIf="!tenantsLink" class="PageButton">
                <p class="PageButtonText">Tenants</p>
            </div>
            <div (click)="tenantsClick()" *ngIf="tenantsLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Tenants</p>
            </div>

            <div (click)="usersClick()" *ngIf="!usersLink" class="PageButton">
                <p class="PageButtonText">Users</p>
            </div>
            <div (click)="usersClick()" *ngIf="usersLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Users</p>
            </div>

            <div (click)="permissionsClick()" *ngIf="!permissionsLink" class="PageButton">
                <p class="PageButtonText">Permissions</p>
            </div>
            <div (click)="permissionsClick()" *ngIf="permissionsLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Permissions</p>
            </div>

            <div (click)="groupsClick()" *ngIf="!groupsLink" class="PageButton">
                <p class="PageButtonText">Groups</p>
            </div>
            <div (click)="groupsClick()" *ngIf="groupsLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Groups</p>
            </div>

            <div (click)="rolesClick()" *ngIf="!rolesLink" class="PageButton">
                <p class="PageButtonText">Roles</p>
            </div>
            <div (click)="rolesClick()" *ngIf="rolesLink" class="PageButtonSelected">
                <p class="PageButtonSelectedText">Roles</p>
            </div>

        </div> -->

        <mat-card style="margin-right: 20px; height: max-content;">
            <mat-card-content style="padding-left: 0px;">
                <div (click)="tenantsClick()" *ngIf="!tenantsLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px"
                            xmlns="http://www.w3.org/2000/svg">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h1.6"></path>
                            <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h4.159"></path>
                            <path d="M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                            <path d="M18 14.5v1.5"></path>
                            <path d="M18 20v1.5"></path>
                            <path d="M21.032 16.25l-1.299 .75"></path>
                            <path d="M16.27 19l-1.3 .75"></path>
                            <path d="M14.97 16.25l1.3 .75"></path>
                            <path d="M19.733 19l1.3 .75"></path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Tenants</p>
                </div>
                <div (click)="tenantsClick()" *ngIf="tenantsLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                            stroke-linecap="round" stroke-linejoin="round" height="20px" width="20px"
                            xmlns="http://www.w3.org/2000/svg">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h1.6"></path>
                            <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h4.159"></path>
                            <path d="M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                            <path d="M18 14.5v1.5"></path>
                            <path d="M18 20v1.5"></path>
                            <path d="M21.032 16.25l-1.299 .75"></path>
                            <path d="M16.27 19l-1.3 .75"></path>
                            <path d="M14.97 16.25l1.3 .75"></path>
                            <path d="M19.733 19l1.3 .75"></path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Tenants</p>
                </div>

                <div (click)="usersClick()" *ngIf="!usersLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke-width="2"
                                d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Users</p>
                </div>
                <div (click)="usersClick()" *ngIf="usersLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke-width="2"
                                d="M18.0003,20.9998 C16.3453,20.9998 15.0003,19.6538 15.0003,17.9998 C15.0003,16.3458 16.3453,14.9998 18.0003,14.9998 C19.6543,14.9998 21.0003,16.3458 21.0003,17.9998 C21.0003,19.6538 19.6543,20.9998 18.0003,20.9998 L18.0003,20.9998 Z M24.0003,17.9998 L21.0003,17.9998 L24.0003,17.9998 Z M20.1213,20.1218 L22.2423,22.2428 L20.1213,20.1218 Z M18.0003,23.9998 L18.0003,20.9998 L18.0003,23.9998 Z M13.7573,22.2428 L15.8783,20.1208 L13.7573,22.2428 Z M12.0003,17.9998 L15.0003,17.9998 L12.0003,17.9998 Z M15.8783,15.8788 L13.7573,13.7578 L15.8783,15.8788 Z M18.0003,14.9998 L18.0003,11.9998 L18.0003,14.9998 Z M20.1213,15.8788 L22.2423,13.7578 L20.1213,15.8788 Z M12.5,12.5 C11.2660678,11.4458897 9.77508483,11 8,11 C4.13400675,11 1,13.0294373 1,18 L1,23 L11,23 M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Users</p>
                </div>

                <div (click)="permissionsClick()" *ngIf="!permissionsLink" class="PageButton">
                    <div>
                        <svg _ngcontent-ng-c2421312070="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-ng-c2421312070="" fill="none" stroke-width="2"
                                d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L8,23 M10,19.5 C10,20.88 11.12,22 12.5,22 C13.881,22 15,20.88 15,19.5 C15,18.119 13.881,17 12.5,17 C11.12,17 10,18.119 10,19.5 L10,19.5 Z M23,15 L20,12 L14,18 M17.5,14.5 L20.5,17.5 L17.5,14.5 Z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Permissions</p>
                </div>
                <div (click)="permissionsClick()" *ngIf="permissionsLink" class="PageButtonSelected">
                    <div>
                        <svg _ngcontent-ng-c2421312070="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-ng-c2421312070="" fill="none" stroke-width="2"
                                d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L8,23 M10,19.5 C10,20.88 11.12,22 12.5,22 C13.881,22 15,20.88 15,19.5 C15,18.119 13.881,17 12.5,17 C11.12,17 10,18.119 10,19.5 L10,19.5 Z M23,15 L20,12 L14,18 M17.5,14.5 L20.5,17.5 L17.5,14.5 Z">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Permissions</p>
                </div>

                <div (click)="groupsClick()" *ngIf="!groupsLink" class="PageButton">
                    <div>
                        <svg _ngcontent-ng-c2421312070="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-ng-c2421312070="" fill="none" stroke-width="2"
                                d="M12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 Z M6,22 L6,19 C6,15.6862915 8.6862915,13 12,13 C15.3137085,13 18,15.6862915 18,19 L18,22 M13,5 C13.4037285,3.33566165 15.0151447,2 17,2 C19.172216,2 20.98052,3.790861 21,6 C20.98052,8.209139 19.172216,10 17,10 L16,10 L17,10 C20.287544,10 23,12.6862915 23,16 L23,18 M11,5 C10.5962715,3.33566165 8.98485529,2 7,2 C4.82778404,2 3.01948003,3.790861 3,6 C3.01948003,8.209139 4.82778404,10 7,10 L8,10 L7,10 C3.71245602,10 1,12.6862915 1,16 L1,18">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Templating Groups</p>
                </div>
                <div (click)="groupsClick()" *ngIf="groupsLink" class="PageButtonSelected">
                    <div>
                        <svg _ngcontent-ng-c2421312070="" stroke="currentColor" fill="currentColor" stroke-width="0"
                            viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path _ngcontent-ng-c2421312070="" fill="none" stroke-width="2"
                                d="M12,13 C14.209139,13 16,11.209139 16,9 C16,6.790861 14.209139,5 12,5 C9.790861,5 8,6.790861 8,9 C8,11.209139 9.790861,13 12,13 Z M6,22 L6,19 C6,15.6862915 8.6862915,13 12,13 C15.3137085,13 18,15.6862915 18,19 L18,22 M13,5 C13.4037285,3.33566165 15.0151447,2 17,2 C19.172216,2 20.98052,3.790861 21,6 C20.98052,8.209139 19.172216,10 17,10 L16,10 L17,10 C20.287544,10 23,12.6862915 23,16 L23,18 M11,5 C10.5962715,3.33566165 8.98485529,2 7,2 C4.82778404,2 3.01948003,3.790861 3,6 C3.01948003,8.209139 4.82778404,10 7,10 L8,10 L7,10 C3.71245602,10 1,12.6862915 1,16 L1,18">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Templating Groups</p>
                </div>

                <div (click)="rolesClick()" *ngIf="!rolesLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke-width="2"
                                d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M14.6431888,15.6961461 C14.3091703,14.6675626 13.7524493,13.7598949 13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L11,23 M12,18.8235294 L16.1904762,22 L23,13">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonText">Templating Roles</p>
                </div>
                <div (click)="rolesClick()" *ngIf="rolesLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" stroke-width="2"
                                d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M14.6431888,15.6961461 C14.3091703,14.6675626 13.7524493,13.7598949 13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L11,23 M12,18.8235294 L16.1904762,22 L23,13">
                            </path>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Templating Roles</p>
                </div>

                <div (click)="poolsClick()" *ngIf="!poolsLink" class="PageButton">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                            </path>
                            <circle cx="8" cy="14" r="2"></circle>
                            <circle cx="12" cy="8" r="2"></circle>
                            <circle cx="16" cy="14" r="2"></circle>
                        </svg>
                    </div>
                    <p class="PageButtonText">Workflow Pools</p>
                </div>
                <div (click)="poolsClick()" *ngIf="poolsLink" class="PageButtonSelected">
                    <div>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                            height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                            </path>
                            <circle cx="8" cy="14" r="2"></circle>
                            <circle cx="12" cy="8" r="2"></circle>
                            <circle cx="16" cy="14" r="2"></circle>
                        </svg>
                    </div>
                    <p class="PageButtonSelectedText">Workflow Pools</p>
                </div>
            </mat-card-content>
        </mat-card>

        <div class="PageSection">
            <app-tenants *ngIf="tenantsLink"></app-tenants>
            <app-users *ngIf="usersLink"></app-users>
            <app-permissions *ngIf="permissionsLink"></app-permissions>
            <app-groups *ngIf="groupsLink"></app-groups>
            <app-roles *ngIf="rolesLink"></app-roles>
            <app-pools *ngIf="poolsLink"></app-pools>
        </div>
    </div>
</div>