import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebMethodsService } from '../../web-methods.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgbdToastGlobal } from '../../toast/toast-global.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


export interface DialogData {
  groupName: string;
  groupDescription: string;
  tenantID: any;
}

@Component({
  selector: 'app-groups',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.scss'
})
export class GroupsComponent implements OnInit {
  constructor(public api: WebMethodsService, public dialog: MatDialog, private toastTemplates: NgbdToastGlobal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Name', 'Description','TenantName', 'action'];
  groups: any = [];
  dataSource = new MatTableDataSource(this.groups);
  groupsLoading = false;
  tenants: any = [{ id: 'All', Name: 'All' }];
  tenantsLoading = false;
  filter = new FormControl('All');
  filterValue = '';

  ngOnInit(): void {
    this.getTenants();
    
  }

  getTenants() {
    this.tenantsLoading = true;
    this.api.postService("/um/tenantperuser", { Username: "#" })
      .then((response) => {
        if (response.status == 200) {
          this.tenants = [{ id: 'All', Name: 'All' }];
          this.tenants.push.apply(this.tenants, response.data.data.listTenantss.items);
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tenants", "Could not get tenants.");
        }
        this.tenantsLoading = false;
        this.getGroups();
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Tenants", err);
        this.tenantsLoading = false;
      });
  }
  getGroups() {
    this.groupsLoading = true;
    this.api.postService("/settings/listgroups", { "none": "" })
      .then((response) => {
        this.groupsLoading = false;
        // console.log(response);
        if (response.status == 200) {
          // console.log(response.data);
          // this.groups = response.data;
          this.groups = [];
          response.data.forEach((element: any) => {
            if ((this.filter.value == 'All') || (this.filter.value == element.TenantID)) {
              var tenantName = this.tenants.find((tItem: any) => { return tItem.id == element.TenantID });
              var groupListItem = {
                id: element.id,
                Name: element.Name,
                Description: element.Description,
                TenantID: tenantName,
                TenantName: tenantName.Name
              }
              this.groups.push(groupListItem);
            }
          });
          this.dataSource = new MatTableDataSource(this.groups);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(this.groups);
        }
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Groups", err);
        this.groupsLoading = false;
      });
  }

  filterGroups() {
    this.getGroups();
  }

  addGroup() {
    const dialogRef = this.dialog.open(AddGroupDialog, { width: 'fit-content', data: { groupName: "", groupDescription: "", tenantID: new FormControl() } });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        if ((result.groupDescription != "") && (result.groupName != "")) {
          this.groupsLoading = true;
          this.api.postService("/settings/addgroup", { Name: result.groupName, Description: result.groupDescription, TenantID: result.tenantID.value})
            .then((results) => {
              this.groupsLoading = false;
              if (results.status == 200) {
                if (results.data.statusCode != 200) {
                  this.toastTemplates.showErrorToast("API error - Add Group", "A connection error has occured. Please contact support for assistance.");
                }
              }
              else {
                this.toastTemplates.showErrorToast("API error - Add Group", "A connection error has occured. Please contact support for assistance.");
              }
              this.getGroups();
            })
            .catch((err) => {
              // console.log(err);
              this.toastTemplates.showErrorToast("API error - Add Group", err);
              this.groupsLoading = false;
            });
        }
      }
    });
  }

  deleteGroup(id: string, name: string) {
    // console.log(id);
    // console.log(name);
    this.groupsLoading = true;
    this.api.postService("/settings/deletegroup", { GroupID: id })
      .then((response) => {
        // console.log(response);
        this.api.postService("/um/updateallusergroups", { GroupName: name })
          .then((response) => {
            // console.log(response);
            this.groupsLoading = false;
            this.getGroups();
          })
          .catch((err) => {
            // console.log(err);
            this.toastTemplates.showErrorToast("API error - Delete Group", err);
            this.groupsLoading = false;
          });
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Delete Group", err);
        this.groupsLoading = false;
      });
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }
}


@Component({
  selector: 'add-group-dialog',
  templateUrl: 'add-group-dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    MatProgressBarModule,
  ],
  styleUrl: './groups.component.scss'
})
export class AddGroupDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddGroupDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public api: WebMethodsService, private toastTemplates: NgbdToastGlobal) { }
  tenantsLoading =false;
  tenants: any = [];
  // filter = new FormControl('All');
  ngOnInit(): void {
    this.getTenants();
  }
  getTenants() {
    this.tenantsLoading = true;
    this.api.postService("/um/tenantperuser", { Username: "#" })
      .then((response) => {
        if (response.status == 200) {
          this.tenants = response.data.data.listTenantss.items;
        }
        else {
          this.toastTemplates.showErrorToast("API error - Get Tenants", "Could not get tenants.");
        }
        this.tenantsLoading = false;
      })
      .catch((err) => {
        // console.log(err);
        this.toastTemplates.showErrorToast("API error - Get Tenants", err);
        this.tenantsLoading = false;
      });
  }
}